import * as client from 'src/internal/metrics/metrics.client';

import type {IMetrics} from 'src/internal/metrics/types';
import type {ObservabilityConfig} from 'src/types';

// TODO: fix references to example files

export default class Metrics implements IMetrics {
  #config: ObservabilityConfig;

  constructor(config: ObservabilityConfig) {
    this.#config = config;
  }

  increment(...args: Parameters<IMetrics['increment']>) {
    client.increment(this.#config, ...args);
  }

  gauge(...args: Parameters<IMetrics['gauge']>) {
    client.gauge(this.#config, ...args);
  }

  set(...args: Parameters<IMetrics['set']>) {
    client.set(this.#config, ...args);
  }
}
