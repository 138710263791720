import Counter from 'src/internal/platformMetrics/aggregators/Counter';

export const safeParams = [
  'stripelet_dashboard_page',
  'stripelet_dashboard_module',
  'stripelet_dashboard_workbench',
  'stripelet_unclassified',
] as const;

type SafeParams = (typeof safeParams)[number];

export default new Counter<SafeParams>('frontend.platform-ui.stripelet-count');
