function stableKeysOrderReplacer(_key: unknown, value: unknown): unknown {
  if (value && typeof value === 'object' && !Array.isArray(value)) {
    const keys = Object.keys(value).sort();
    const length = keys.length;
    const result: {[key: string]: unknown} = {};

    for (let i = 0; i < length; i++) {
      result[keys[i]] = (value as {[key: string]: unknown})[keys[i]];
    }

    return result;
  }

  // JSON.stringify removes undefined values as they are invalid JSON props, so we need to replace them with null
  return value === undefined ? null : value;
}

export default function stableStringify(value: unknown): string {
  return JSON.stringify(value, stableKeysOrderReplacer);
}
