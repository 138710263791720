import * as Sentry from '@sentry/browser';

import type {Event, Severity} from '@sentry/browser';

export function sanitizeExtras(data: any): any {
  if (data) {
    try {
      return JSON.parse(JSON.stringify(data));
    } catch (e: any) {
      return {
        // If you see this in a sentry, it means you attempted to pass it data that caused an exception
        // when attempting serialization (like a circular reference).
        // Instead of failing to send the sentry entirely, we'll still let it through but with the
        // non-serializable extra stripped and replaced with this.
        bad_extra: 'Extra data could not be serialized',
      };
    }
  }

  return data;
}

export function extractErrorMessage(event: Event): string | undefined {
  const exceptionValues = event?.exception?.values;

  if (Array.isArray(exceptionValues) && exceptionValues.length >= 1) {
    return String(exceptionValues[0].value);
  }
}

export function normalizeErrorLevel(
  severity: Severity | undefined,
): 'error' | 'warning' | 'info' {
  const levelMap = {
    [Sentry.Severity.Fatal]: 'error',
    [Sentry.Severity.Critical]: 'error',
    [Sentry.Severity.Error]: 'error',
    [Sentry.Severity.Warning]: 'warning',
    [Sentry.Severity.Log]: 'info',
    [Sentry.Severity.Info]: 'info',
    [Sentry.Severity.Debug]: 'info',
  } as const;

  return severity ? levelMap[severity] : 'error';
}
