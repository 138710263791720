import buildParams from 'src/internal/analytics/buildParams';
import createAnalyticsReporter from 'src/internal/analytics/createAnalyticsReporter';

import type {
  IAnalytics,
  ImperativeAnalytics,
} from 'src/internal/analytics/types';

const method = <TMethod extends keyof IAnalytics>(
  methodName: TMethod,
): ImperativeAnalytics[TMethod] => {
  return (config, eventName, parameters): void => {
    if (!config || config.analytics?.disabled) {
      return;
    }

    const {service, userId, pageViewToken, analytics} = config;

    // Passing only the parameters needed by the 'createAnalyticsReporter' function
    // instead of the spreading the config object to avoid invalidating the cache for
    // unrelated values.
    const analyticsReporter = createAnalyticsReporter({
      service,
      userId,
      pageViewToken,
      parametersAllowList: analytics?.parametersAllowList,
      disableCookies: analytics?.disableCookies,
      cookieEnforcementMode: analytics?.cookieEnforcementMode,
      useLegacyEndpoint: analytics?.useLegacyEndpoint,
      maxPageLength: analytics?.maxPageLength,
    });

    analyticsReporter?.[methodName]?.(
      eventName,
      buildParams(config, parameters),
    );
  };
};

export const action: ImperativeAnalytics['action'] = method('action');
export const link: ImperativeAnalytics['link'] = method('link');
export const modal: ImperativeAnalytics['modal'] = method('modal');
export const track: ImperativeAnalytics['track'] = method('track');
export const viewed: ImperativeAnalytics['viewed'] = method('viewed');
