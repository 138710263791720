export function isBasicObject(value: unknown): value is object {
  if (value == null || typeof value !== 'object') {
    return false;
  }

  const prototype = Object.getPrototypeOf(value);
  return prototype == null || prototype === Object.prototype;
}

export const isTraversable = (value: any): value is Object =>
  typeof value === 'object' &&
  value !== null &&
  (Array.isArray(value) || isBasicObject(value));
