import {getSearchParams} from '../../utils/searchParams/getSearchParams';

const commsCenterKeys = ['stripe_email_id', 'stripe_target_component'];
export const calculateCommsCenterQueryParams = (
  // eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals
  windowUrl: string = window.location.href,
): Record<string, string> => {
  const map: Record<string, string> = {};
  const searchParams = getSearchParams(windowUrl);

  commsCenterKeys.forEach((key: string) => {
    const value = searchParams.get(key);
    if (value) {
      map[key] = value;
    }
  });
  return map;
};

const commsCenterParams = calculateCommsCenterQueryParams();
export function getCommsCenterQueryParams(): Record<string, string> {
  return commsCenterParams;
}
