import {AbstractTracker} from 'src/internal/platformMetrics/aggregators/AbstractTracker';

export default class WeightCounter<
  TParams extends string,
> extends AbstractTracker<Record<TParams, number>> {
  track(id: TParams, weight: number): void {
    this.eventData[id] ??= 0;
    this.eventData[id] += weight;
  }
}
