import type {ErrorObject} from 'serialize-error';
import {serializeError} from 'serialize-error';
import type {SentryTeamName} from '@stripe-internal/connect-embedded-lib';
import type * as Sentry from '@sentry/browser';
import {rpc, type RpcClient} from '@sail/observability';
import {fromMessagePort, MessageChannel} from '@sail/rpc';
import {assertUnreachable} from '../utils/assertUnreachable';
import type {IScriptUrlContext} from '../utils/getCurrentScriptUrlContext';
import {
  isConnectFrameMessage,
  isConnectFrameProxyEvent,
  isConnectFrameProxyMessageResponse,
  stripeConnectJsMessage,
} from '../utils/isConnectFrameMessage';
import {getDevLogger} from '../utils/getLogger';
import {
  buildDataLayerFrame,
  buildDataLayerFrameUrl,
} from './buildDataLayerFrame';
import {DataLayerAuthError} from '../data-layer-frame/errors';
import type {IDeferredPromise} from '../utils/DeferredPromise';
import {DeferredPromise} from '../utils/DeferredPromise';
import {getStripeApiHost} from './buildStripeClient';
import type {
  AnalyticsRecord,
  IAnalyticsEvent,
} from '../data-layer-frame/AnalyticsTypes';
import type {IErrorPayload} from '../data-layer-frame/sentry/ErrorReporter';
import type {PrimitiveRecord} from '../utils/errorHandling';
import {RefreshClientSecret} from '../connect/refreshClientSecret';
import type {AuthenticationState} from '../data-layer-frame/authentication/types';
import type {AccountSessionClaim} from '../data-layer-frame/types';
import type {IMetricsEvent} from './Metrics';
import {markConnectTiming} from '../utils/telemetry/performanceMetrics';
import type {MetaOptions} from '../connect/ConnectJSInterface/InitAndUpdateOptionsTypes';
import type {SerializedFetchError} from './utils';
import {deseralizeFetchErrorFromPostMessage} from './utils';
import type {ConnectElementImportKeys} from '../connect/ConnectJSInterface/ConnectElementList';
import {getFirstLoadedFont} from '../utils/dom/getLoadedFontFamilies';
import {MESSAGE_CHANNEL_TIMEOUT} from '../connect/utils/embeddedLoadErrors';
import {isAnalyticsDisabled} from '../data-layer-frame/isAnalyticsDisabled';

const devLogger = getDevLogger();

export interface IFrameMessenger {
  refreshClientSecret: RefreshClientSecret;
  proxyRequest: (params: IHttpRequestParams) => Promise<IProxiedHttpResponse>;
  authenticate: (
    clientSecret: string,
    setNewKey?: boolean,
  ) => Promise<AccountSessionClaim>;
  logIframeLoadedFont: (fontFamily: string) => Promise<void>;
  init: () => Promise<void>;
  initWithExistingWindow: (frameWindow: Window) => Promise<void>;
  sendAnalytics(analyticsEvent: IAnalyticsEvent): void;
  sendErrorReport(errorPayload: IErrorPayload): void;
  sendMetrics(metrics: IMetricsEvent): void;
  logout(clearUserSessionOnly?: boolean): Promise<AuthenticationState>;
  getAuthMetadata(): AnalyticsRecord & PrimitiveRecord;
  getAuthState(): Promise<AuthenticationState>;
  openPopupAndWaitForAuth(signal: AbortSignal): Promise<AuthenticationState>;
  openPopupAndWaitForComplete(signal: AbortSignal): Promise<'complete'>;

  addListener<Event extends keyof IFrameEventMap>(
    eventName: Event,
    callback: IFrameEventHandler<Event>['callback'],
    options?: IFrameEventOptions,
  ): void;

  removeListener<Event extends keyof IFrameEventMap>(
    eventName: Event,
    callback: IFrameEventHandler<Event>['callback'],
  ): void;

  getFrameName: () => Promise<string>;

  deferredFrame: IDeferredPromise<IDataLayerFrameLoadResult>;

  getObservabilityRpc: () => RpcClient;
}

/**
 * This error occurs when performing a request and running into issues with authentication
 * the error is not recognized as an API error - instead we expect authentication to flag an issue. In some situations,
 * this is expected, like when a platform feeds us an expired/wrong account session client secret
 */
export class FrameMessengerAuthError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'FrameMessengerAuthError';
  }
}

/**
 * This error occurs when performing a request and running into issues with initializing the data layer
 * the error is not recognized as an API error - instead we expect initialization to flag an issue. This error is never expected
 */
export class FrameMessengerDataLayerError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'FrameMessengerDataLayerError';
  }
}

// --------------
// Message parameters
export interface IHttpRequestParams {
  /**
   * A brief description of the request being made
   */
  url: string;
  method: string;
  headers?: Record<string, string>;
  body?: string | Uint8Array;
  connectElementKey: ConnectElementImportKeys;
}

export interface IFrameOpenAuthPopupAndWaitForAuthParams {
  popupParams: string;
}

export interface IFrameOpenAuthPopupAndWaitForCompleteParams {
  popupParams: string;
}

export interface IErrorFramePayload {
  serializableError: ErrorObject;
  connectElement: string | undefined;
  tags?: PrimitiveRecord;
  extra?: PrimitiveRecord;
  owner?: SentryTeamName;
  level?: Sentry.SeverityLevel;
}

export interface IProxiedHttpResponse {
  ok: boolean;
  status: number;
  statusText: string;
  headers: Record<string, string>;
  body?: string; // TODO: Support more types (other than string)
}

// --------------
// Messages

export type IFrameAnyMessage = {
  type: 'stripeconnectjs';
  requestType: string;
};

export type IFrameMessageInitRequest = {
  type: 'stripeconnectjs';
  requestType: 'init';
  releaseCandidate?: string;
  isAnalyticsDisabled?: boolean;
};

export type IFrameMessageInitResponse = {
  type: 'stripeconnectjs';
  requestType: 'initresponse';
};

export type IFrameAnalyticsEvent = {
  type: 'stripeconnectjs';
  requestType: 'analyticsEvent';
  analyticsEvent: IAnalyticsEvent;
};

export type IFrameErrorReport = {
  type: 'stripeconnectjs';
  requestType: 'errorReport';
  errorPayload: IErrorFramePayload;
};

export type IFrameMetricsEvent = {
  type: 'stripeconnectjs';
  requestType: 'metricsEvent';
  metricsPayload: IMetricsEvent;
};

export type IFrameProxyAnonRequest = {
  type: 'stripeconnectjs';
  requestType: 'proxy';
  id: number;
  request: IHttpRequestParams;
  metaOptions: Pick<MetaOptions, 'apiKeyOverride' | 'platformIdOverride'>;
};

export type IFrameProxyAuthedRequest = {
  type: 'stripeconnectjs';
  requestType: 'authedProxy';
  id: number;
  request: IHttpRequestParams;
  metaOptions: Pick<MetaOptions, 'apiKeyOverride' | 'platformIdOverride'>;
};

export type IFrameProxyRequest =
  | IFrameProxyAnonRequest
  | IFrameProxyAuthedRequest;

export type IFrameProxyAbort = {
  type: 'stripeconnectjs';
  requestType: 'abort';
  id: number;
};

export type IFrameProxyResponse = {
  type: 'stripeconnectjs';
  requestType: 'response';
  id: number;
  response: IProxiedHttpResponse;
};

export type IFrameMessageFailedResponse = {
  type: 'stripeconnectjs';
  requestType: 'errorResponse';
  id: number;
  error: Error;
};

export type IFrameMessageFetchErrorResponse = {
  type: 'stripeconnectjs';
  requestType: 'fetchErrorResponse';
  id: number;
  error: SerializedFetchError;
};

export type IFrameSentryTags = {
  type: 'stripeconnectjs';
  requestType: 'sentryTags';
  tags: PrimitiveRecord;
};

export type IFrameLogIframeLoadedFontRequest = {
  type: 'stripeconnectjs';
  requestType: 'logIframeLoadedFontRequest';
  id: number;
  request: {
    platformLoadedFont: string;
    fontFamily: string;
  };
};

export type IFrameClaimAccountSessionRequest = {
  type: 'stripeconnectjs';
  requestType: 'claimAccountSessionRequest';
  id: number;
  request: {
    clientSecret: string;
    setNewKey: boolean;
    publishableKey: string;
    apiHost: string;
  } & Pick<
    MetaOptions,
    | 'merchantIdOverride'
    | 'livemodeOverride'
    | 'apiKeyOverride'
    | 'platformIdOverride'
    | 'isV2Session'
  >;
};

export type IFrameLogIframeLoadedFontResponse = {
  type: 'stripeconnectjs';
  requestType: 'logIframeLoadedFontResponse';
  id: number;
  response: {
    platformLoadedFont: string;
    iframeLoadedFont: string;
    availableInIframe: boolean;
    platformHostName: string;
  };
};

export type IFrameClaimAccountSessionResponse = {
  type: 'stripeconnectjs';
  requestType: 'claimAccountSessionResponse';
  id: number;
  response: AccountSessionClaim;
};

export type IFrameLogoutRequest = {
  type: 'stripeconnectjs';
  requestType: 'logout';
  id: number;
  request: {clearUserSessionOnly?: boolean} & Pick<
    MetaOptions,
    'merchantIdOverride' | 'livemodeOverride'
  >;
};

export type IFrameAuthStateRequest = {
  type: 'stripeconnectjs';
  requestType: 'authStateRequest';
  id: number;
  request: undefined;
};

export type IFrameAuthStateResponse = {
  type: 'stripeconnectjs';
  requestType: 'authState';
  id: number;
  response: AuthenticationState;
};

export type IFramePopupCompleteResponse = {
  type: 'stripeconnectjs';
  requestType: 'popupComplete';
  id: number;
  response: 'complete';
};

export type IFramePopupAuthenticationComplete = {
  type: 'stripeconnectjs';
  requestType: 'popupAuthenticationComplete';
  apiKey: string;
  sessionCreatedForEmbedded: boolean;
};

export type IFramePopupComplete = {
  type: 'stripeconnectjs';
  requestType: 'popupComplete';
};

export type IFrameOpenPopupAndWaitForAuthRequest = {
  type: 'stripeconnectjs';
  requestType: 'openPopupAndWaitForAuth';
  id: number;
  request: Record<string, unknown>;
};

export type IFrameOpenPopupAndWaitForCompleteRequest = {
  type: 'stripeconnectjs';
  requestType: 'openPopupAndWaitForComplete';
  id: number;
  request: Record<string, unknown>;
};
// TODO(dhiggins) This isn't actually used yet. POC for how we could support mulitple challenge types
export type AuthenticatedChallenge = {
  type: 'authenticated';
};

export type ExternalAccountChallenge = {
  type: 'external_account';
  object: string;
  challenge?: string;
  requiresVerification: boolean;
  identityAuthEligible?: boolean;
};

export type IFrameMessageAction<
  Request extends IFrameAnyMessage & {id: number; request: unknown},
  Response extends IFrameAnyMessage & {id: number; response: unknown},
> = {req: Request; res: Response};

export type IFrameMessageActions = {
  authedProxy: IFrameMessageAction<
    IFrameProxyAuthedRequest,
    IFrameProxyResponse
  >;
  logIframeLoadedFont: IFrameMessageAction<
    IFrameLogIframeLoadedFontRequest,
    IFrameLogIframeLoadedFontResponse
  >;
  claimAccountSession: IFrameMessageAction<
    IFrameClaimAccountSessionRequest,
    IFrameClaimAccountSessionResponse
  >;
  logout: IFrameMessageAction<IFrameLogoutRequest, IFrameAuthStateResponse>;
  authState: IFrameMessageAction<
    IFrameAuthStateRequest,
    IFrameAuthStateResponse
  >;
  openPopupAndWaitForAuth: IFrameMessageAction<
    IFrameOpenPopupAndWaitForAuthRequest,
    IFrameAuthStateResponse
  >;
  openPopupAndWaitForComplete: IFrameMessageAction<
    IFrameOpenPopupAndWaitForCompleteRequest,
    IFramePopupCompleteResponse
  >;
};

export type IFrameMessageSuccessfulRequest<
  Key extends keyof IFrameMessageActions = keyof IFrameMessageActions,
> = IFrameMessageActions[Key]['req'];

export type IDataLayerRequest<
  Key extends keyof IFrameMessageActions = keyof IFrameMessageActions,
> = IFrameMessageSuccessfulRequest<Key>['request'];

export type IFrameMessageSuccessfulResponse<
  Key extends keyof IFrameMessageActions = keyof IFrameMessageActions,
> = IFrameMessageActions[Key]['res'];

export type IDataLayerResponse<
  Key extends keyof IFrameMessageActions = keyof IFrameMessageActions,
> = IFrameMessageSuccessfulResponse<Key>['response'];

export type IFrameEventMap = {
  authStateChanged: AuthenticationState;
  popupReady: undefined;
  popupLoaded: undefined;
  popupDoRefresh: undefined;
};

export type IFrameEventOptions = {
  once?: boolean;
};

export type IFrameEventHandler<Key extends keyof IFrameEventMap> = {
  callback: (data: IFrameEventMap[Key]) => void;
  options: IFrameEventOptions;
};

export type IFrameEventMessage<Key extends keyof IFrameEventMap> = {
  type: 'stripeconnectjs';
  requestType: Key;
  event: true;
  data: IFrameEventMap[Key];
};

export type IDataLayerMessage =
  | IFrameMessageInitRequest
  | IFrameMessageInitResponse
  | IFrameClaimAccountSessionRequest
  | IFrameClaimAccountSessionResponse
  | IFrameAnalyticsEvent
  | IFrameErrorReport
  | IFrameLogIframeLoadedFontRequest
  | IFrameLogIframeLoadedFontResponse
  | IFrameMetricsEvent
  | IFrameProxyRequest
  | IFrameProxyAbort
  | IFrameProxyResponse
  | IFrameMessageFailedResponse
  | IFrameMessageFetchErrorResponse
  | IFrameLogoutRequest
  | IFrameAuthStateRequest
  | IFrameAuthStateResponse
  | IFrameEventMessage<'authStateChanged'>
  | IFrameEventMessage<'popupReady'>
  | IFrameEventMessage<'popupLoaded'>
  | IFrameEventMessage<'popupDoRefresh'>
  | IFramePopupAuthenticationComplete
  | IFramePopupComplete
  | IFrameOpenPopupAndWaitForAuthRequest
  | IFrameOpenPopupAndWaitForCompleteRequest
  | IFrameSentryTags;

export type IFrameAuthStateChangedEvent =
  IFrameEventMessage<'authStateChanged'>;

export type IFramePopupReadyEvent = IFrameEventMessage<'popupReady'>;

export type IFramePopupLoadedEvent = IFrameEventMessage<'popupLoaded'>;

export type IFrameMessageRequest =
  | IFrameMessageSuccessfulRequest
  | IFrameProxyAbort;

export type IFrameMessageResponse =
  | IFrameMessageSuccessfulResponse
  | IFrameMessageFailedResponse
  | IFrameMessageFetchErrorResponse;

// These are all messages with specific message ids
export type IDataLayerProxyMessage =
  | IFrameMessageRequest
  | IFrameMessageResponse;

export interface IDataLayerFrameLoadResult {
  frameWindow: Window;
  frameName: string;
}

export class FrameMessenger implements IFrameMessenger {
  // Holds metadata sent to analytics and Sentry. Both Analytics and Primitive records
  // should have the same type signature. But this ensures we'll always have the correct
  // metadata.
  private observabilityAuthMetadata: AnalyticsRecord & PrimitiveRecord = {};

  private observabilityRpc: RpcClient;

  public refreshClientSecret: RefreshClientSecret;

  /**
   * Build a FrameMessenger, which instantiates the data layer iframe and is able to send/receive messages to it
   * @param connectInstanceId ID of the connect instance
   * @param scriptUrlContext Used for building and communicating with the data layer iframe
   * @param releaseCandidate Used for communicating with the data layer iframe that a release candidate is being tested
   * @param publishableKey The platform's publishable key
   * @param refreshClientSecretCallback Used to fetch a new client secret to refresh the api key after it expires
   * @param eagerDataLayerPromise promise of a data layer frame that is loaded before Connect instance created
   * @param frameLoader Used to construct the data layer iframe
   * @param channel Used as a message channel between the platform frame and the data layer frame: https://developer.mozilla.org/en-US/docs/Web/API/MessageChannel
   * @param deferredFrame Used to gate all proxyRequest calls from being resolved until data layer frame is loaded
   * @param refreshClientSecretUILayer This is only used in the FrameMessenger instance created in the UI layer. It should post a message to the platform frame and call the refreshClientSecretCallback via the platform frame's FrameMessenger instance
   */
  constructor(
    private connectInstanceId: string,
    private scriptUrlContext: IScriptUrlContext,
    private publishableKey: string,
    private metaOptions?: MetaOptions,
    private refreshClientSecretCallback?: () => Promise<string | undefined>,
    private eagerDataLayerPromise?: Promise<HTMLIFrameElement>,
    private frameLoader: (
      url: string,
      connectInstanceId: string,
    ) => Promise<HTMLIFrameElement> = buildDataLayerFrame,
    private channel: MessageChannel = new MessageChannel(),
    private sdkChannel: MessageChannel = new MessageChannel(),
    public deferredFrame: IDeferredPromise<IDataLayerFrameLoadResult> = new DeferredPromise(),
    private refreshClientSecretUILayer?: () => Promise<void>,
  ) {
    this.refreshClientSecret = new RefreshClientSecret(
      this.authenticate.bind(this),
      this.refreshClientSecretCallback,
    );
    this.sdkChannel.port1.start();
    // TODO(endrit): Fix rpc typings in sdk/observability
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    this.observabilityRpc = rpc.initClient({
      transport: fromMessagePort(this.sdkChannel.port1),
    }) as any;
  }

  getAuthMetadata() {
    return {...this.observabilityAuthMetadata};
  }

  getFrameName: () => Promise<string> = async () => {
    const initFrame = await this.deferredFrame.promise;
    return initFrame.frameName;
  };

  getObservabilityRpc: () => RpcClient = () => {
    return this.observabilityRpc;
  };

  public async init(): Promise<void> {
    let frame: HTMLIFrameElement;

    try {
      if (this.eagerDataLayerPromise) {
        frame = await this.eagerDataLayerPromise;
      } else {
        const url = buildDataLayerFrameUrl(
          this.scriptUrlContext.absoluteFolderPath,
          // eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals
          window.location.href,
        );

        frame = await this.frameLoader(url.href, this.connectInstanceId);
      }

      if (!frame.contentWindow) {
        devLogger.error(
          'Attempt to use the FrameMessenger when the data layer has not been initalized',
        );
        throw new Error('Data layer has not been initialized');
      }

      const loadFailureTimeout = setTimeout(() => {
        this.deferredFrame.reject(
          new Error(
            `Data layer message channel was not initialized within ${MESSAGE_CHANNEL_TIMEOUT}ms`,
          ),
        );
      }, MESSAGE_CHANNEL_TIMEOUT);

      await this.initializeMessageChannel(frame.contentWindow);
      markConnectTiming('frameReady');
      clearTimeout(loadFailureTimeout);
      this.deferredFrame.resolve({
        frameWindow: frame.contentWindow,
        frameName: frame.name, // We use the iframe's name instead of the contentWindow.name since this may be a cross-origin request
      });
    } catch (e) {
      devLogger.error('Error when initializing the FrameMessenger', e);
      this.deferredFrame.reject(e);
      throw e;
    }
  }

  public async initWithExistingWindow(frameWindow: Window): Promise<void> {
    try {
      await this.initializeMessageChannel(frameWindow);
      this.deferredFrame.resolve({frameWindow, frameName: frameWindow.name}); // We use the iframe's contentWindow.name since this is only used in the UILayerWrapper, i.e. same-origin request
    } catch (e) {
      devLogger.error('Error when initializing the FrameMessenger', e);
      this.deferredFrame.reject(e);
      throw e;
    }
  }

  /**
   * Sends the fontFamily stack to the data-layer to find out which font would be loaded in an iframe
   *
   * @param fontFamily The fontFamily either inherited or from the appearance API
   * @returns The font that would be loaded in an iframe
   */
  logIframeLoadedFont = async (fontFamily: string) => {
    await this.deferredFrame.promise;
    const platformLoadedFont = getFirstLoadedFont(fontFamily);
    await this.sendMessageAndWaitForReply(
      'logIframeLoadedFont',
      {
        type: 'stripeconnectjs',
        requestType: 'logIframeLoadedFontRequest',
        request: {
          platformLoadedFont,
          fontFamily,
        },
      },
      undefined,
    );
  };

  /**
   * Use the client secret to claim the account session. This authenticates the platform auth
   * by setting the api key to the account session key.
   *
   * @param clientSecret The platform's secret key
   * @param setNewKey Resets the deferred auth promise to block requests until the new key is set
   * @returns The account session claim object
   */
  authenticate = async (clientSecret: string, setNewKey = false) => {
    await this.deferredFrame.promise;
    const response = await this.sendMessageAndWaitForReply(
      'claimAccountSession',
      {
        type: 'stripeconnectjs',
        requestType: 'claimAccountSessionRequest',
        request: {
          clientSecret,
          setNewKey,
          publishableKey: this.publishableKey,
          apiHost: getStripeApiHost(this.scriptUrlContext.serviceEnvironment),
          merchantIdOverride: this.metaOptions?.merchantIdOverride,
          livemodeOverride: this.metaOptions?.livemodeOverride,
          apiKeyOverride: this.metaOptions?.apiKeyOverride,
          platformIdOverride: this.metaOptions?.platformIdOverride,
          isV2Session: this.metaOptions?.isV2Session,
        },
      },
      undefined,
    );

    // We set the default value to undefined meaning the secret will never expire, because the expiry key will only be null when we are overriding the API key
    this.refreshClientSecret.updateExpiry(response.api_key_expiry || undefined);
    this.refreshClientSecret.apiKeyOverride =
      !!this.metaOptions?.apiKeyOverride;
    return response;
  };

  /**
   * Invalidate user session and clear the stored API keys, if any.
   *
   * @returns The authentication state for the user, after logging out.
   *    This should always have a status of "unauthenticated".
   */
  logout = async (
    clearUserSessionOnly?: boolean,
  ): Promise<AuthenticationState> => {
    await this.deferredFrame.promise;
    return this.sendMessageAndWaitForReply(
      'logout',
      {
        type: 'stripeconnectjs',
        requestType: 'logout',
        request: {
          clearUserSessionOnly,
          merchantIdOverride: this.metaOptions?.merchantIdOverride,
          livemodeOverride: this.metaOptions?.livemodeOverride,
        },
      },
      undefined,
    );
  };

  /**
   * Get the user's current authentication state, including whether or not
   * the user has authenticated with Stripe.
   *
   * @returns The authentication state for the user.
   */
  getAuthState = async (): Promise<AuthenticationState> => {
    await this.deferredFrame.promise;
    return this.sendMessageAndWaitForReply(
      'authState',
      {
        type: stripeConnectJsMessage,
        requestType: 'authStateRequest',
        request: undefined,
      },
      undefined,
    );
  };

  /**
   *
   * This method initializes connect account authentication by opening a popup which should include the
   * login page that returns an API key when successful.
   *
   * @param url The url for the popup, which should route to a login page that sends back a message with the API key
   * @param signal A signal that can be passed in to reject the currently waiting promise.
   *    For example, you can use this with `AbortSignal.timeout()` to cancel waiting for authentication after some
   *    amount of time.
   * @returns A promise that will resolve once the data layer receives an authentication message with an API key for
   *    the user. It rejects if the AbortSignal is triggered for any reason.
   */
  openPopupAndWaitForAuth = async (
    signal: AbortSignal,
  ): Promise<AuthenticationState> => {
    await this.deferredFrame.promise;
    return this.sendMessageAndWaitForReply(
      'openPopupAndWaitForAuth',
      {
        type: 'stripeconnectjs',
        requestType: 'openPopupAndWaitForAuth',
        request: {},
      },
      signal,
    );
  };

  /**
   *
   * This method opens a popup which should include the onboarding page, authenticates, and closes the popup when complete
   *
   * @param url The url for the popup, which should route to an onboarding page
   * @param signal A signal that can be passed in to reject the currently waiting promise.
   *    For example, you can use this with `AbortSignal.timeout()` to cancel waiting for authentication after some
   *    amount of time.
   * @returns A promise that will resolve once the data layer receives close window message
   */
  openPopupAndWaitForComplete = async (
    signal: AbortSignal,
  ): Promise<'complete'> => {
    await this.deferredFrame.promise;
    return this.sendMessageAndWaitForReply(
      'openPopupAndWaitForComplete',
      {
        type: 'stripeconnectjs',
        requestType: 'openPopupAndWaitForComplete',
        request: {},
      },
      signal,
    );
  };

  /**
   * Initializes the data layer message channel
   * @param frame Data layer iframe
   */
  private initializeMessageChannel(frameWindow: Window): Promise<void> {
    return new Promise((resolve, reject) => {
      this.channel.port1.onmessage = (event: MessageEvent) => {
        if (!isConnectFrameMessage(event.data)) {
          devLogger.warn(
            'Data layer received a non-connect frame message',
            event.data,
          );
          return reject(new Error('Unexpected message returned'));
        }

        const initResponse = event.data;
        if (initResponse.requestType !== 'initresponse') {
          devLogger.error(
            'Data layer did not return an init response message',
            event.data,
          );
          return reject(
            new Error('Data layer did not return an init response message'),
          );
        }

        // Once channel is set up, messages will pipe to regular listener
        this.channel.port1.onmessage = this.onChannelMessageReceived;
        this.channel.port1.onmessageerror = this.onChannelMessageError;
        resolve();
      };
      this.channel.port1.onmessageerror = reject;

      const message: IFrameMessageInitRequest = {
        type: stripeConnectJsMessage,
        requestType: 'init',
        releaseCandidate: this.metaOptions?.releaseCandidate,
        isAnalyticsDisabled:
          this.metaOptions?.disableAnalytics || isAnalyticsDisabled(),
      };

      frameWindow.postMessage(message, this.scriptUrlContext.origin, [
        this.channel.port2,
        this.sdkChannel.port2,
      ]);
    });
  }

  private eventListeners = new Map<
    keyof IFrameEventMap,
    Array<IFrameEventHandler<keyof IFrameEventMap>>
  >();

  /**
   * Subscribe to events emitted by the data layer.
   *
   * You can use this method to subscribe to any events emitted by the data layer.
   * For instance, you can listen to 'authStateChanged' events to subscribe for
   * when the user has authenticated or logged out.
   *
   * @param eventName The name of the event that to subscribe to changes on.
   * @param callback A method to call whenever that event has been emitted.
   */
  addListener = <Event extends keyof IFrameEventMap>(
    eventName: Event,
    callback: IFrameEventHandler<Event>['callback'],
    options: IFrameEventOptions = {once: false},
  ): void => {
    const listeners = this.getListeners(eventName);
    if (!listeners.some((listener) => listener.callback === callback)) {
      listeners.push({
        callback,
        options,
      });
    }
  };

  /**
   * Unsubscribe from events emitted by the data layer.
   *
   * @param eventName The name of the event.
   * @param callback The callback method to remove from the list.
   */
  removeListener = <Event extends keyof IFrameEventMap>(
    eventName: Event,
    callback: IFrameEventHandler<Event>['callback'],
  ): void => {
    const listeners = this.getListeners(eventName);
    const index = listeners.findIndex(
      (listener) => listener.callback === callback,
    );
    if (index >= 0) {
      listeners.splice(index, 1);
    }
  };

  /**
   * Typesafe method for getting event listeners by event name.
   *
   * If there are no listeners, this method will return an empty list.
   * This list is modifiable and will update the listeners in the map.
   */
  private getListeners = <Event extends keyof IFrameEventMap>(
    eventName: Event,
  ): Array<IFrameEventHandler<Event>> => {
    let listeners = this.eventListeners.get(eventName);
    if (listeners == null) {
      listeners = [];
      this.eventListeners.set(eventName, listeners);
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return listeners as Array<IFrameEventHandler<Event>>;
  };

  private callListeners = <Event extends keyof IFrameEventMap>(
    eventName: Event,
    data: IFrameEventMap[Event],
  ): void => {
    const listeners = this.getListeners(eventName);
    listeners.forEach((listener) => {
      listener.callback(data);
      if (listener.options.once) {
        this.removeListener(eventName, listener.callback);
      }
    });
  };

  /**
   * Increases with every message being sent to generate a unique identifier per message
   */
  private currentMessageId = 0;

  /**
   * Maps message ids to the callbacks that need to resolve/reject when messages are returned
   */
  private messageIdCallerMap = new Map<
    number,
    DeferredPromise<IDataLayerResponse>
  >();

  private onChannelMessageReceived = (event: MessageEvent) => {
    if (isConnectFrameProxyMessageResponse(event.data)) {
      const messageResponse = event.data;

      // Find corresponding caller in the messageId map
      const promiseCallback = this.messageIdCallerMap.get(messageResponse.id);
      if (promiseCallback) {
        this.messageIdCallerMap.delete(messageResponse.id); // Remove entry from map
        switch (messageResponse.requestType) {
          case 'logIframeLoadedFontResponse':
          case 'claimAccountSessionResponse':
          case 'authState':
          case 'popupComplete':
          case 'response':
            promiseCallback.resolve(messageResponse.response);
            break;
          case 'errorResponse':
            promiseCallback.reject(messageResponse.error);
            break;
          case 'fetchErrorResponse':
            promiseCallback.reject(
              deseralizeFetchErrorFromPostMessage(messageResponse.error),
            );
            break;
          default:
            assertUnreachable(
              messageResponse,
              'Unsupported requestType received',
            );
            break;
        }
      }
    }

    if (isConnectFrameProxyEvent(event.data)) {
      const eventData = event.data;
      this.callListeners(eventData.requestType, eventData.data);
    }
  };

  private onChannelMessageError = (ev: MessageEvent) => {
    // TODO: Handle message channel errors. This can happen due to serialization issues through the channel
    devLogger.error(
      'Unhandled message channel error',
      JSON.parse(JSON.stringify(ev.data)),
    );
    throw new Error('Message channel error unhandled');
  };

  private getNewMessageId(): number {
    const {currentMessageId} = this;
    this.currentMessageId += 1;
    return currentMessageId;
  }

  proxyRequest = async (
    params: IHttpRequestParams,
    signal?: AbortSignal | null | undefined,
  ): Promise<IProxiedHttpResponse> => {
    // Ensure frame is loaded, communication channel is open, and we are authenticated
    try {
      await this.deferredFrame.promise;
    } catch (error) {
      throw new FrameMessengerDataLayerError(
        'Error initializing the data layer frame',
      );
    }

    // Check if we need to refresh the client secret before making the request, i.e. if the API key is near expiry
    if (this.refreshClientSecretUILayer) {
      await this.refreshClientSecretUILayer();
    } else {
      await this.refreshClientSecret.refresh();
    }

    return this.sendMessageAndWaitForReply(
      'authedProxy',
      {
        type: stripeConnectJsMessage,
        requestType: 'authedProxy',
        request: params,
        metaOptions: this.metaOptions ?? {},
      },
      signal,
    ).catch((error) => {
      if (error instanceof DataLayerAuthError) {
        throw new FrameMessengerAuthError(error.message);
      } else {
        throw error;
      }
    });
  };

  sendAnalytics = (analyticsEvent: IAnalyticsEvent): void => {
    (async () => {
      try {
        // Ensure frame is loaded, communication channel is open (skip authentication)
        await this.deferredFrame.promise;

        const analyticsFrameMessage: IFrameAnalyticsEvent = {
          requestType: 'analyticsEvent',
          type: stripeConnectJsMessage,
          analyticsEvent: {
            ...analyticsEvent,
            params: {
              ...this.observabilityAuthMetadata, // Include auth analytics metadata if available
              ...analyticsEvent.params,
            },
          },
        };
        this.channel.port1.postMessage(analyticsFrameMessage);
      } catch (e) {
        // We ignore errors when sending analytics
        devLogger.warn('Error when sending an analytics event', e);
      }
    })();
  };

  sendErrorReport = (errorPayload: IErrorPayload): void => {
    (async () => {
      try {
        // Ensure frame is loaded, communication channel is open (skip authentication)
        await this.deferredFrame.promise;

        const errorReportFrameMessage: IFrameErrorReport = {
          requestType: 'errorReport',
          type: stripeConnectJsMessage,
          errorPayload: {
            connectElement: errorPayload.connectElement,
            tags: {
              ...this.observabilityAuthMetadata,
              ...errorPayload.tags,
            },
            extra: errorPayload.extra,
            serializableError: serializeError(errorPayload.error),
            owner: errorPayload.owner,
            level: errorPayload.level,
          },
        };
        this.channel.port1.postMessage(errorReportFrameMessage);
      } catch (e) {
        // We ignore errors when sending errors
        devLogger.warn('Error when sending an error event', e);
      }
    })();
  };

  sendMetrics = (metrics: IMetricsEvent): void => {
    (async () => {
      try {
        // Ensure frame is loaded, communication channel is open (skip authentication)
        await this.deferredFrame.promise;
        const metricsEventFrameMessage: IFrameMetricsEvent = {
          requestType: 'metricsEvent',
          type: stripeConnectJsMessage,
          metricsPayload: {...this.observabilityAuthMetadata, ...metrics},
        };
        this.channel.port1.postMessage(metricsEventFrameMessage);
      } catch (e) {
        // We ignore errors when sending metrics
        devLogger.warn('Error when sending a metrics payload', e);
      }
    })();
  };

  private sendMessageAndWaitForReply<K extends keyof IFrameMessageActions>(
    _requestType: K,
    message: Omit<IFrameMessageSuccessfulRequest<K>, 'id'>,
    signal: AbortSignal | null | undefined,
  ): Promise<IDataLayerResponse<K>> {
    const messageId = this.getNewMessageId();

    const deferredPromise = new DeferredPromise<IDataLayerResponse<K>>();
    this.messageIdCallerMap.set(messageId, deferredPromise);

    if (signal) {
      if (signal.aborted) {
        // if signal has been prematurely aborted, we throw a DOMException error
        throw new DOMException('The fetch request was aborted.', 'AbortError');
      } else {
        // add event listener if signal has not been aborted
        signal.addEventListener('abort', () => {
          const abortMessage: IFrameProxyAbort = {
            requestType: 'abort',
            id: messageId,
            type: stripeConnectJsMessage,
          };
          this.channel.port1.postMessage(abortMessage);
        });
      }
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const messageWithId = message as IFrameMessageSuccessfulRequest;
    messageWithId.id = messageId;
    this.channel.port1.postMessage(message);
    return deferredPromise.promise;
  }
}
