/* eslint-disable no-console */
import {isDevEnvironment} from './isDevEnvironment';

type Logger = (message?: any, ...optionalParams: any[]) => void;

export type IEmbeddedLogger = {
  readonly log: Logger;
  readonly info: Logger;
  readonly warn: Logger;
  readonly error: Logger;
};

/**
 * Do not log anywhere.
 */
const NullLogger: IEmbeddedLogger = {
  log: (_message?: any, ..._optionalParams: any[]) => {},
  info: (_message?: any, ..._optionalParams: any[]) => {},
  warn: (_message?: any, ..._optionalParams: any[]) => {},
  error: (_message?: any, ..._optionalParams: any[]) => {},
};

/**
 * This logger is a proxy for the console.* log methods.
 *
 * It should only be used for things that can be embedded into
 * the build.
 */
export const StandardLogger: IEmbeddedLogger = {
  log: console.log.bind(console),
  info: console.info.bind(console),
  warn: console.warn.bind(console),
  error: console.error.bind(console),
};

export const buildDevLogger = (isDevEnvironment: boolean) => {
  return isDevEnvironment ? StandardLogger : NullLogger;
};

/**
 * Only log when inside of a development environment.
 */
export const DevelopmentLogger: IEmbeddedLogger =
  buildDevLogger(isDevEnvironment);

/**
 * Provide a logger interface that only logs to the console when in a
 * development environment.
 */
export const getDevLogger = (): IEmbeddedLogger => {
  return DevelopmentLogger;
};

/**
 * Provide a logger interface that only logs to the console when in a
 * testmode environment.
 */
export const getTestmodeLogger = (testmode: boolean): IEmbeddedLogger => {
  return testmode ? StandardLogger : NullLogger;
};
