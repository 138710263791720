import type {ProjectName} from 'src/types';

class BaseError extends Error {
  constructor(message: string) {
    super(`[@sail/observability] ${message}`);
  }
}

export class UninitializedObservabilityError extends BaseError {
  constructor() {
    super('Trying to access module before initialization!');
  }
}

export class MissingSentryProjectObservabilityError extends BaseError {
  constructor(project: ProjectName) {
    super(
      `Missing sentry project! Please provide a valid DSN for '${project}' in 'errors.projects'.`,
    );
  }
}

export class MissingServiceObservabilityError extends BaseError {
  constructor() {
    super(
      `Missing service name! Please initialize '@sail/observability' with a valid service name.`,
    );
  }
}

export class AlreadyInitializedObservabilityError extends BaseError {
  constructor() {
    super('Trying to call init on an already initialized module!');
  }
}

export class UninitializedObservabilityProviderError extends BaseError {
  constructor(hookName: string) {
    super(
      `Please make sure that '${hookName}' is used inside an '<ObservabilityProvider />`,
    );
  }
}
