// Referenced code from https://git.corp.stripe.com/stripe-internal/stripe-js-v3/blob/0d614104/src/shared/constants/constants.ts#L1

import {getStronglyTypedKeys} from '../../utils/getStronglyTypedKeys';

export const JS_TO_CSS_FONT_PROPERTY_MAPPINGS = {
  family: 'font-family',
  src: 'src',
  unicodeRange: 'unicode-range',
  style: 'font-style',
  variant: 'font-variant',
  stretch: 'font-stretch', // These are extra styles that could be loaded from a @font-face definition
  weight: 'font-weight', // These are extra styles that could be loaded from a @font-face definition
  display: 'font-display',
} as const;

export const JS_TO_STYLE_FONT_PROPERTY_MAPPINGS = {
  family: 'fontFamily',
  src: null,
  unicodeRange: null,
  style: 'fontStyle',
  variant: 'fontVariant',
  stretch: 'fontStretch',
  weight: 'fontWeight',
  display: null,
} as const;

export const CSS_TO_JS_FONT_PROPERTY_MAPPINGS = getStronglyTypedKeys(
  JS_TO_CSS_FONT_PROPERTY_MAPPINGS,
).reduce<Record<string, string>>((obj, key) => {
  obj[JS_TO_CSS_FONT_PROPERTY_MAPPINGS[key]] = key;
  return obj;
}, {});
