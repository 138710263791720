import {getEnvironment, memoize} from '@sail/utils';
import {AnalyticsReporter, DualReporter} from '@stripe-internal/analytics-js';
import {DEFAULT_PARAMETERS_ALLOW_LIST} from 'src/internal/analytics/constants';
import {MissingServiceObservabilityError} from 'src/internal/common/errors';
import logErrorInDev from 'src/internal/common/logErrorInDev';

import type {AnalyticsConfig as AnalyticsJsConfig} from '@stripe-internal/analytics-js';
import type {AnalyticsConfig, IAnalytics, ObservabilityConfig} from 'src/types';

type AnalyticsReporterConfig = Pick<
  ObservabilityConfig,
  'service' | 'userId' | 'pageViewToken'
> &
  Pick<
    AnalyticsConfig,
    | 'parametersAllowList'
    | 'cookieEnforcementMode'
    | 'useLegacyEndpoint'
    | 'disableCookies'
    | 'maxPageLength'
  >;

const DEFAULT_MAX_PAGE_LENGTH = 300;
const LEGACY_ENDPOINT_CONFIG = {
  overrideTrackingUrl: {
    prod: 'https://t.stripe.com/',
    qa: 'https://t.stripe.com/',
  },
};

const createAnalyticsReporter = (
  config: AnalyticsReporterConfig,
): IAnalytics | null => {
  const {
    service,
    userId,
    pageViewToken,
    parametersAllowList,
    disableCookies,
    maxPageLength,
    cookieEnforcementMode,
    useLegacyEndpoint,
  } = config;

  if (!service) {
    logErrorInDev(new MissingServiceObservabilityError());

    return null;
  }

  const inProduction = getEnvironment() === 'production';
  const useCookies = disableCookies ? 'disabled' : 'newCookies';
  const safeParams = (parametersAllowList ?? [])
    .concat(DEFAULT_PARAMETERS_ALLOW_LIST)
    .reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {} as Record<string, boolean>);

  const analyticsJsConfig: AnalyticsJsConfig = {
    clientId: service,
    inProduction,
    safeParams,
    useCookies,
    maxPageLength: maxPageLength ?? DEFAULT_MAX_PAGE_LENGTH,
    stripeCookiesEnforcementMode: cookieEnforcementMode,
    cookies: {
      user: userId,
    },
    pageviewtoken: pageViewToken,
  };

  return useLegacyEndpoint
    ? new DualReporter<string>(
        analyticsJsConfig,
        {
          ...analyticsJsConfig,
          ...LEGACY_ENDPOINT_CONFIG,
        },
        [],
        // Always send to legacy endpoint
        () => true,
      )
    : new AnalyticsReporter(analyticsJsConfig);
};

export default memoize(createAnalyticsReporter);
