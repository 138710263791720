import {Integrations} from '@sentry/browser';
import {Dedupe} from '@sentry/integrations';
import * as analytics from 'src/internal/analytics/analytics.server';
import filters from 'src/internal/errors/constants/filters';
import SailObservabilityIntegration from 'src/internal/errors/sentry/SailObservabilityIntegration';
import {beforeSend} from 'src/internal/errors/sentry/beforeSend';
import * as metrics from 'src/internal/metrics/metrics.server';

import type {SentryHubOptions} from 'src/internal/errors/sentry/types';
import type {ErrorEventReport, ObservabilityConfig} from 'src/types';

export function createHubIntegrations(
  config: ObservabilityConfig,
  isMainHub = false,
): SentryHubOptions['integrations'] {
  const {errors} = config;
  const {
    expectedJsErrors = [],
    whitelistUrls = [],
    captureUnhandledErrors = false,
  } = errors ?? {};

  return [
    new Integrations.InboundFilters({
      ignoreErrors: [...filters.ignoreErrors, ...expectedJsErrors],
      whitelistUrls: [...filters.whitelistUrls, ...whitelistUrls],
      blacklistUrls: filters.blacklistUrls,
    }),
    new Dedupe(),
    new Integrations.FunctionToString(),
    new Integrations.TryCatch(),
    new Integrations.Breadcrumbs(),
    captureUnhandledErrors && isMainHub
      ? new Integrations.GlobalHandlers({
          onunhandledrejection: true,
          onerror: true,
        })
      : new Integrations.GlobalHandlers({
          onunhandledrejection: false,
          onerror: false,
        }),
    new Integrations.LinkedErrors(),
    new Integrations.UserAgent(),
    new SailObservabilityIntegration(config, metrics, analytics),
  ];
}

export function createHubConfig(config: ObservabilityConfig): SentryHubOptions {
  const {service, project, release, page, userId, errors} = config;
  const {disabled} = errors ?? {};

  const defaultTags: ErrorEventReport = {
    service: service ?? '<unknown>',
    project,
    page: page ?? '<unknown>',
    release: release ?? '<unknown>',
    error_message: '<unknown>',
    error_handled: 'false',
    error_issue_id: '<unknown>',
    error_issue_url: '<unknown>',
  };

  return {
    enabled: !disabled,
    release,
    initialScope: {
      user: {
        id: userId,
      },
      tags: {
        ...errors?.tags,
        ...defaultTags,
      },
      extras: errors?.extras,
    },
    integrations: createHubIntegrations(config),
    beforeSend,
  };
}
