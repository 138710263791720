import {getStripeApiHost} from '../data-layer-client/buildStripeClient';
import {getCurrentScriptUrlContext} from './getCurrentScriptUrlContext';

// We create a `preconnect` link as a performance optimization to tell the browser to connect to api.stripe.com as early as possible and make future API calls to that domain faster
export const createApiPreconnectLink = (currentDocument: Document) => {
  const link = currentDocument.createElement('link');
  link.rel = 'preconnect';
  link.href = `https://${getStripeApiHost(
    getCurrentScriptUrlContext().serviceEnvironment,
  )}`;
  link.crossOrigin = '';
  return link;
};
