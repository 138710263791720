import type {
  IFrameEventMessage,
  IDataLayerMessage,
  IDataLayerProxyMessage,
  IFrameMessageResponse,
  IFrameEventMap,
} from '../data-layer-client/FrameMessenger';

export const stripeConnectJsMessage = 'stripeconnectjs';

export const isConnectFrameMessage = (
  messageEventData: any,
): messageEventData is IDataLayerMessage => {
  return !!(
    messageEventData &&
    typeof messageEventData === 'object' &&
    messageEventData.type === stripeConnectJsMessage &&
    typeof messageEventData.requestType === 'string'
  );
};

export const isConnectFrameProxyMessage = (
  messageEventData: any,
): messageEventData is IDataLayerProxyMessage => {
  return !!(
    messageEventData &&
    typeof messageEventData === 'object' &&
    messageEventData.type === stripeConnectJsMessage &&
    typeof messageEventData.requestType === 'string' &&
    messageEventData.id !== undefined
  );
};

export const isConnectFrameProxyMessageResponse = (
  messageEventData: any,
): messageEventData is IFrameMessageResponse => {
  if (!isConnectFrameProxyMessage(messageEventData)) {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const responseMessage = messageEventData as IFrameMessageResponse;
  return (
    responseMessage.requestType === 'errorResponse' ||
    responseMessage.requestType === 'fetchErrorResponse' ||
    responseMessage.response != null
  );
};

export const isConnectFrameProxyEvent = (
  messageEventData: unknown,
): messageEventData is IFrameEventMessage<keyof IFrameEventMap> => {
  if (!isConnectFrameMessage(messageEventData)) return false;

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const eventMessage = messageEventData as IFrameEventMessage<
    keyof IFrameEventMap
  >;
  return Boolean(eventMessage.event);
};
