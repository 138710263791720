import {getDevLogger} from './getLogger';

const devLogger = getDevLogger();

export function assertUnreachable(x: never | undefined, message?: string) {
  devLogger.error(
    message ?? 'Thing that should never be set is set',
    JSON.parse(JSON.stringify(x)),
  );
}
