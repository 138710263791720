import {getEnvironment, once} from '@sail/utils';
import generateId from 'src/helpers/generateId';
import {MissingServiceObservabilityError} from 'src/internal/common/errors';
import logErrorInDev from 'src/internal/common/logErrorInDev';
import * as ENDPOINTS from 'src/internal/metrics/endpoints';

import type {MetricParams} from 'src/internal/metrics/types';
import type {ObservabilityConfig} from 'src/types';

const HEADERS = {
  'Content-Type': 'application/x-www-form-urlencoded',
};

const logMissingServiceOnce = once(() => {
  logErrorInDev(new MissingServiceObservabilityError());
});

export default function record(
  config: ObservabilityConfig,
  metric: string,
  params?: MetricParams,
): void {
  const service = config.service;

  if (!service) {
    logMissingServiceOnce();

    return;
  }

  // Parameters required by the analytics event logger service
  const requiredParams = {
    client_id: service,
    event_id: generateId(),
    event_name: metric,
    created: new Date().getTime() / 1000,
  };

  const pageViewToken = config.pageViewToken;

  fetch(
    getEnvironment() === 'production' ? ENDPOINTS.R_URL : ENDPOINTS.QA_R_URL,
    {
      method: 'POST',
      headers: HEADERS,
      mode: 'no-cors',
      // https://github.com/microsoft/TypeScript/issues/32951 - URLSearchParams API only accepts strings
      body: new URLSearchParams({
        ...params,
        ...(pageViewToken ? {page_view_id: pageViewToken} : {}),
        ...requiredParams,
      } as any).toString(),
    },
  );
}
