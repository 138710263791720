/* eslint-disable @stripe-internal/embedded/no-restricted-globals */
import type {CustomFontOptions} from '@stripe-internal/connect-embedded-lib';
import type {Analytics} from '@sail/observability';
import {getTestmodeLogger} from '../../utils/getLogger';
import {livemodeFromPublishableKey} from '../../utils/livemodeFromPublishableKey';
import {loadFont} from './loadFont';
import {isAbsoluteUrl, urlJoin} from './urls';

export class FontLoader {
  public loadedFontsPromise: Promise<Array<Record<string, string>>>;

  constructor(
    analyticsSender: Analytics,
    publishableKey: string,
    public fonts?: CustomFontOptions,
  ) {
    const allFonts = fonts || [];

    this.loadedFontsPromise = (async () => {
      const fontResults = await Promise.all(
        allFonts.map(async (font) => {
          if ('src' in font && typeof font.src === 'string') {
            // Fonts that don't have to be loaded via a CSS file, i.e. CustomFontSources that use a `url()`
            analyticsSender.track('submerchant_surfaces_load_font', {
              fontSrc: font.src,
              api_result: 'success',
            });
            return {
              ...font,
              __resolveFontRelativeTo: window.location.href,
            };
          } else if ('cssSrc' in font && typeof font.cssSrc === 'string') {
            // Fonts that have to be loaded directly, i.e. CSSFontSources that provide a URL
            const initialFetchTime = window.performance.now();
            const urlToLoad = isAbsoluteUrl(font.cssSrc)
              ? font.cssSrc
              : urlJoin(window.location.href, font.cssSrc);
            try {
              const cssSrcFonts = await loadFont(urlToLoad);

              analyticsSender.track('submerchant_surfaces_load_font', {
                fontLoadTime: Math.ceil(
                  window.performance.now() - initialFetchTime,
                ),
                fontCount: cssSrcFonts.length,
                fontCssSrc: urlToLoad,
                api_result: 'success',
              });

              return cssSrcFonts.map((cssSrcFont) => ({
                ...cssSrcFont,
                __resolveFontRelativeTo: urlToLoad,
              }));
            } catch (err: any) {
              analyticsSender.track('submerchant_surfaces_load_font', {
                fontLoadTime: Math.ceil(
                  window.performance.now() - initialFetchTime,
                ),
                errorMessage: err?.message,
                fontCssSrc: urlToLoad,
                api_result: 'error',
              });
              getTestmodeLogger(
                !livemodeFromPublishableKey(publishableKey, true),
              ).warn(`Failed to load CSS file at ${urlToLoad}.`);

              return undefined;
            }
          } else {
            getTestmodeLogger(
              !livemodeFromPublishableKey(publishableKey, true),
            ).warn(`Unexpected font object: ${JSON.stringify(font)}.`);

            return undefined;
          }
        }),
      );

      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return fontResults.flat().filter(Boolean) as Array<
        Record<string, string>
      >;
    })();
  }
}
