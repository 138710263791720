/* eslint-disable @stripe-internal/embedded/no-restricted-globals */
import type {SentryTeamName} from '@stripe-internal/connect-embedded-lib';
import {FetchApiError} from '@stripe-internal/connect-embedded-lib';
import type {IAnalytics, IReports, Tags} from '@sail/observability';
import type {PrimitiveRecord, ReportErrorTags} from '../utils/errorHandling';
import type {MetaOptions} from '../connect/ConnectJSInterface/InitAndUpdateOptionsTypes';
import {getHostApp} from '../utils/getHostApp';
import {
  reportRequestError,
  reportRequestSuccess,
} from '../utils/telemetry/connectElementAnalyticsSender';
import type {AuthenticationState} from '../data-layer-frame/authentication/types';
import type {AccountSessionClaim} from '../data-layer-frame/types';

export async function withErrorReportingAndAnalytics<
  Params,
  Returns extends AccountSessionClaim | AuthenticationState,
>(
  reports: IReports,
  metaOptions: MetaOptions,
  analytics: IAnalytics,
  analyticsId: string,
  func: (args: Params) => Promise<Returns>,
  args: Params,
  extraErrorTags?: Record<string, string>,
  meta?: string,
  owner?: SentryTeamName,
  expectErrors: string[] = [],
  mutedErrors: string[] = [],
): Promise<Returns> {
  const hostApp = getHostApp(metaOptions);
  const startTime = window.performance.now();

  let response: Returns;
  try {
    response = await func(args);
    reportRequestSuccess(
      analytics,
      analyticsId,
      Math.ceil(window.performance.now() - startTime),
      owner,
    );
  } catch (error) {
    if (
      error instanceof FetchApiError &&
      error.error.httpStatus === 401 &&
      !error.error.code
    ) {
      error.error.code = 'reauth_required';
    }

    const reportError = (
      error: Error,
      tags?: ReportErrorTags,
      extra?: PrimitiveRecord,
      owner?: SentryTeamName,
    ) => {
      reports.error(error, {
        project: owner,
        tags: {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          ...(tags as Tags | undefined),
          ...extraErrorTags,
        },
        extras: extra,
      });
    };

    reportRequestError(
      analytics,
      reportError,
      analyticsId,
      error,
      hostApp,
      expectErrors,
      mutedErrors,
      meta,
      Math.ceil(window.performance.now() - startTime),
      undefined,
      owner,
    );
    throw error;
  }
  return response;
}
