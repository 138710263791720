import {once} from '@sail/utils';
import {
  AlreadyInitializedObservabilityError,
  UninitializedObservabilityError,
} from 'src/internal/common/errors';
import logErrorInDev from 'src/internal/common/logErrorInDev';

import type {ObservabilityConfig} from 'src/types';

const logUninitializedObservabilityErrorOnce = once(() => {
  logErrorInDev(new UninitializedObservabilityError());
});

const logAlreadyInitializedObservabilityErrorOnce = once(() => {
  logErrorInDev(new AlreadyInitializedObservabilityError());
});

// TODO(endrit): Report error to sentry & signalfx instead of logging.
// This will be possible when we add RPC support, to avoid reporting
// internal @sail/observability errors in main window.
class Config {
  private _isInitialized = false;
  private store: ObservabilityConfig;

  constructor(initialValue: ObservabilityConfig) {
    this.store = initialValue;
  }

  getAll(): ObservabilityConfig {
    if (!this._isInitialized) {
      logUninitializedObservabilityErrorOnce();
    }

    return this.store;
  }

  get<K extends keyof ObservabilityConfig>(key: K): ObservabilityConfig[K] {
    if (!this._isInitialized) {
      logUninitializedObservabilityErrorOnce();
    }

    return this.store[key];
  }

  set(store: ObservabilityConfig): boolean {
    if (this._isInitialized) {
      logAlreadyInitializedObservabilityErrorOnce();
      return false;
    }

    this.store = Object.freeze(store);
    this._isInitialized = true;

    return true;
  }

  isInitialized(): boolean {
    return this._isInitialized;
  }
}

export default Config;
