import {
  getPlatformURL,
  isStripeDomain,
} from '@stripe-internal/connect-embedded-lib';
import {disableAnalyticsParamName} from '../data-layer-client/buildDataLayerFrame';
import {getFlagOverrides} from '../utils/flags/flagOverrides';
import {isDevEnvironment} from '../utils/isDevEnvironment';
import {getSearchParams} from '../utils/searchParams/getSearchParams';

let isAnalyticsDisabledCalculatedValue: boolean | undefined;

export const initializeIsAnalyticsDisabled = (
  isDevEnvironmentParam: boolean = isDevEnvironment,
  isAnalyticsDisabledFromFrameInit: boolean | undefined = undefined,
) => {
  const currentUrl = new URL(getPlatformURL());
  const stripeDomain = isStripeDomain(currentUrl);

  if (isAnalyticsDisabledFromFrameInit && stripeDomain) {
    return true;
  }

  // This may be specified as a URL query param (in the platform frame), or as a hash param (in the ui layer frame)
  // We handle both in this method. This is only allowed in internal domains
  const searchParams = getSearchParams();
  const isAnalyticsDisabledInUrl =
    searchParams.get(disableAnalyticsParamName) === 'true';

  if (isAnalyticsDisabledInUrl && stripeDomain) {
    return true;
  }

  // Telemetry (analytics, sentry) is disabled in dev environment, or it is explicitly turned on via an ENV variable
  const isAnalyticsEnabledInEnvironment =
    !isDevEnvironmentParam || !!process.env.ENABLE_ANALYTICS_IN_DEV;

  if (!isAnalyticsEnabledInEnvironment) {
    return true;
  }

  const flagOverrides = getFlagOverrides();

  // If there are flag overrides, we disable analytics as we don't want to alert when adhoc features are on/off
  const hasFlagOverrides = Object.keys(flagOverrides).length > 0;
  if (hasFlagOverrides) {
    return true;
  }

  return false;
};

export function isAnalyticsDisabled(
  isDevEnvironmentParam: boolean = isDevEnvironment,
  isAnalyticsDisabledFromFrameInit: boolean | undefined = undefined,
): boolean {
  if (isAnalyticsDisabledCalculatedValue !== undefined) {
    return isAnalyticsDisabledCalculatedValue;
  }

  isAnalyticsDisabledCalculatedValue = initializeIsAnalyticsDisabled(
    isDevEnvironmentParam,
    isAnalyticsDisabledFromFrameInit,
  );
  return isAnalyticsDisabledCalculatedValue;
}
