import type {MetricName, MetricTags} from '@sail/observability';
import {isAnalyticsDisabled} from '../data-layer-frame/isAnalyticsDisabled';
import {uuid} from '../utils/uuid';

export interface IMetricsContext {
  increment<T extends MetricName>(metric: T, tags?: MetricTags<T>): void;
  timing<T extends MetricName>(
    metric: T,
    duration: number,
    tags?: MetricTags<T>,
  ): void;
}
export type IMetricsEvent = Record<string, string | number | boolean>;
export type IMetricsSender = (metrics: IMetricsEvent) => void;

// This id is a module scoped variable and is reused throughout our events, so we can link events from a given session back to a common page view id
let currentPageViewId = uuid();
/**
 * Setting page view id to stay the same as platform frame page view id
 */
export const setCurrentPageViewId = (pageViewID: string) => {
  currentPageViewId = pageViewID;
};
export const getCurrentPageViewId = (): string => currentPageViewId;

/**
 * Metrics class which writes events to https://r.stripe.com/0 as a POST request
 *
 * This follows the SignalFX API for metrics - https://dev.splunk.com/observability/reference/api/ingest_data/latest
 */
export class Metrics implements IMetricsContext {
  private sendMetrics: IMetricsSender;

  private getDefaultParams<T extends MetricName>(
    metric: T,
    tags?: MetricTags<T>,
  ): IMetricsEvent {
    const created = new Date().getTime();
    const params: IMetricsEvent = {
      // event_name, event_id, client_id and created are required params by the service.
      // Referenced code from https://git.corp.stripe.com/stripe-internal/stripe-js-v3/blob/master/src/shared/logger/Logger.ts#L153
      // Referenced code from https://git.corp.stripe.com/stripe-internal/stripe-js-v3/blob/master/src/shared/logger/transports/RTransport.ts#L43
      event_id: uuid(),
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      event_name: metric as string,
      client_id: 'connect-js', // This has to match our AEL client_id: https://git.corp.stripe.com/stripe-internal/zoolander/blob/2396aa220534b507677479ed6abf8da6a3e68ee4/uppsala/src/main/resources/com/stripe/dscore/analyticseventlogger/server/rpcserver/client_config.yaml#L598
      created: created / 1000,
      page_view_id: currentPageViewId, // This helps correlate events for a single page view together
    };

    if (tags) {
      Object.keys(tags).forEach((key) => {
        // 'timestamp' was renamed to 'created' above.
        // 'client_id' is added in the code above, with a fallback in case it is not present.
        if (key === 'timestamp' || key in params) {
          return;
        }

        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        params[key] = tags[key as keyof MetricName];
      });
    }

    return params;
  }

  constructor(sendMetrics: IMetricsSender) {
    this.sendMetrics = sendMetrics;
  }

  /**
   * Timing metrics come under the SFX metric - analytics_event_logger.event_meter.client_metrics.gauge
   *
   * In zoolander/uppsala/src/main/resources/com/stripe/dscore/analyticseventlogger/server/rpcserver/client_config.yaml, specify:
   * sfx_events:
   *  - name: METRIC_NAME
   *    skip_count_event: true
   *      gauges:
   *        - name: duration
   */
  timing<T extends MetricName>(
    metric: T,
    duration: number,
    tags?: MetricTags<T>,
  ): void {
    if (isAnalyticsDisabled()) return;

    const params = this.getDefaultParams(metric, tags);

    // Timing metrics
    params.duration = duration;

    this.sendMetrics(params);
  }

  /**
   * Group timing metrics come under the SFX metric - analytics_event_logger.event_meter.client_metrics.gauge
   *
   * In zoolander/uppsala/src/main/resources/com/stripe/dscore/analyticseventlogger/server/rpcserver/client_config.yaml, specify:
   * sfx_events:
   *  - name: METRIC_NAME
   *    skip_count_event: true
   *      gauges:
   *        - name: durationType1
   *        - name: durationType2
   *        - name: durationType3
   *
   * This is used for metrics that have multiple gauges (like performance metrics)
   */
  _groupTiming<T extends MetricName>(
    metric: T,
    timings: Record<string, number>,
    tags?: MetricTags<T>,
  ): void {
    if (isAnalyticsDisabled()) return;

    const params = this.getDefaultParams(metric, tags);

    // Set batch timing metrics
    Object.keys(timings).forEach((key) => {
      if (key in params) {
        return;
      }
      params[key] = timings[key];
    });

    this.sendMetrics(params);
  }

  /**
   * Count metrics come under the SFX metric - analytics_event_logger.event_meter.client_metrics.count
   *
   * In zoolander/uppsala/src/main/resources/com/stripe/dscore/analyticseventlogger/server/rpcserver/client_config.yaml, specify:
   * sfx_events:
   *  - name: METRIC_NAME
   */
  increment<T extends MetricName>(metric: T, tags?: MetricTags<T>): void {
    if (isAnalyticsDisabled()) return;

    const params = this.getDefaultParams(metric, tags);
    this.sendMetrics(params);
  }
}
