import {message} from '@sail/intl';
import type {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';
import {ComponentOverrideFlag} from '../../../utils/flags/flags';
import {RECENT_PAYMENTS_HTML_ATTRIBUTE} from '../HtmlAttributes';

const messages = {
  errorText: message({
    id: 'connectEmbed.payments.errorBoundary.text',
    defaultMessage:
      'Your money is secure, but your payments information cannot be loaded here.',
    description: 'Message shown when an unhandled payments error is caught',
  }),
};

const paymentsComponentParams = [RECENT_PAYMENTS_HTML_ATTRIBUTE] as const;
type PaymentsComponentParams = (typeof paymentsComponentParams)[number];
export type PaymentsComponentHtmlParams = Partial<
  Record<PaymentsComponentParams, string>
>;

export const PaymentsListConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "paymentsExperience" */ '../../../components/Payments/Payments'
    ),
  customHtmlElementMethods: {
    setDefaultFilters: {
      type: 'SupplementalObject',
      objectToSet: 'paymentsListDefaultFilters',
      isInternal: false,
      description:
        'Sets the default filters to apply to the payments list. Includes amount, date, status, and payment method.',
    },
  },
  htmlAttributes: {
    [RECENT_PAYMENTS_HTML_ATTRIBUTE]: {
      isInternal: true,
    },
  },
  messageOverrides: messages,
  releaseState: 'ga',
  enabledKey: 'payments',
  enabledOverrideFlag: ComponentOverrideFlag.payments,
  teamOwner: 'accounts_ui',
  description:
    'Displays the list of payments, allows for refund, dispute management, and capturing charges.',
  docSitePath: '/connect/supported-embedded-components/payments',
  syntheticTestInfo: {
    testUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/sus-payments-iframe',
    rcTestUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/sus-paymentsiframe-rc?filterState=ALL',
  },
};
