// Create a random base prefix to distinguish IDs between multiple loads in case
// the browser or merchant has some kind of DOM serialization / caching
const base = `00${Math.floor(Math.random() * 1000)}`.slice(-3);
let counter = 0;
// Return the next process-global id with a given prefix.
//
// This is NOT a UUID/GUID. The uniqueness of the id is only per-process.
//
// Examples:
//
//   nextId('foo'); // foo3
//   nextId('foo'); // foo7 <-- not guaranteed to be sequential.
//   nextId('bar'); // bar10
export const nextId = (prefix: string): string => {
  // eslint-disable-next-line no-plusplus
  return `${prefix}${base}${counter++}`;
};
