export interface IDeferredPromise<T> {
  resolve: (value: T | PromiseLike<T>) => void;

  reject: (reason: any) => void;

  promise: Promise<T>;
}

export class DeferredPromise<T> implements IDeferredPromise<T> {
  public promise: Promise<T>;

  public resolve!: (value: T | PromiseLike<T>) => void;

  public reject!: (reason: any) => void;

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}
