import type {
  GenericTags,
  MetricName,
  MetricTagName,
  MetricTags,
} from 'src/internal/metrics/types';
import type {ObservabilityConfig} from 'src/types';

// Type-wise, this function is a disaster. I had to use many `as` castings
// because of how MetricTags<T> is built.

export default function buildParams<T extends MetricName>(
  config: ObservabilityConfig,
  parameters?: GenericTags,
): MetricTags<T> | undefined {
  const disable = config.metrics?._tmp_disableAllowList ?? true;
  const configAllowList = new Set(config.metrics?.parametersAllowList);
  const configParameters: GenericTags = config.metrics?.parameters || {};
  const result: GenericTags = {};

  for (const key in configParameters) {
    if (configParameters.hasOwnProperty(key)) {
      result[key as MetricTagName] = (
        configAllowList.has(String(key) as MetricTagName) || disable
          ? configParameters[key as MetricTagName]
          : '[REDACTED]'
      ) as GenericTags[keyof GenericTags];
    }
  }

  for (const key in parameters) {
    if (parameters.hasOwnProperty(key)) {
      result[key as MetricTagName] = (
        configAllowList.has(String(key) as MetricTagName) || disable
          ? parameters[key as MetricTagName]
          : '[REDACTED]'
      ) as GenericTags[keyof GenericTags];
    }
  }

  if (Object.keys(result).length === 0) {
    return undefined;
  }

  return result as MetricTags<T>;
}
