import type {SentryTeamName} from '@stripe-internal/connect-embedded-lib';
import {
  getStripeOrGraphQLErrorMetadata,
  FileReadError,
} from '@stripe-internal/connect-embedded-lib';
import type {IAnalytics} from '@sail/observability';
import {
  FrameMessengerAuthError,
  FrameMessengerDataLayerError,
} from '../../data-layer-client/FrameMessenger';
import type {ReportError} from '../errorHandling';
import {EmbeddedError, makeSafe} from '../errorHandling';
import type {HostApp} from '../../connect/ConnectJSInterface/InitAndUpdateOptionsTypes';
import {isInvalidOrExpiredSessionError} from '../../connect/EmbeddedInvalidOrExpiredSessionContext';

// Referenced code from manage/frontend/packages/kernel/errors/expectErrors.ts
type ErrorType =
  | 'unexpected'
  | 'expected'
  | 'file_read_expected'
  | 'muted'
  | 'network'
  | 'auth_expected'
  | 'data_layer'
  | 'unauthorized';

// Keep consistent with https://livegrep.corp.stripe.com/view/stripe-internal/zoolander/uppsala/src/main/resources/com/stripe/dscore/analyticseventlogger/server/rpcserver/client_config.yaml#L1186
export const errorMetricNames: Record<ErrorType, string> = {
  expected: 'expect_errors.expected_error',
  file_read_expected: 'expect_errors.file_read',
  network: 'expect_errors.network_error',
  muted: 'expect_errors.muted_error',
  auth_expected: 'expect_errors.auth',
  data_layer: 'expect_errors.data_layer',
  unauthorized: 'expect_errors.unauthorized',
  unexpected: 'error',
};

export const DEFAULT_GLOBALLY_EXPECTED_ERRORS = [
  'lock_timeout',
  'platform_api_key_expired',
  'invalid_obvious_xss',
];

export const DEFAULT_READONLY_EXPECTED_ERRORS = [
  'more_permissions_required',
  'more_permissions_required_for_session',
  'unauthorized_application_edit',
];

export const DEFAULT_HOSTED_ONBOARDING_EXPECTED_ERRORS = [
  'live_key_required', // This error is expected in hosted onboarding, because hosted onboarding supports cross-mode. Embedded onboarding does not
];

export const DEFAULT_GLOBALLY_MUTED_ERRORS = [
  'rate_limit',
  'rate_limit_exceeded_simple',
];

export const EXPECTED_AUTH_ERRORS = [
  'cannot_create_account_session',
  'reauth_required',
  'invalid_session',
];

export const READONLY_HOST_APPS = ['loginAs', 'docs'];

export const getErrorType = (
  error: unknown,
  expectedErrors: string[],
  mutedErrors: string[],
): ErrorType => {
  const metadata = getStripeOrGraphQLErrorMetadata(error);

  // If this API call failed because of a data layer initialization issue, map it to an data layer error
  if (error instanceof FrameMessengerDataLayerError) {
    return 'data_layer';
  }

  // If this is a file read error, it is always expected
  if (error instanceof FileReadError) {
    return 'file_read_expected';
  }

  if (
    metadata?.stripeErrorCode &&
    expectedErrors.includes(metadata.stripeErrorCode)
  ) {
    return 'expected';
  }

  if (
    metadata?.stripeErrorCode &&
    mutedErrors.includes(metadata.stripeErrorCode)
  ) {
    return 'muted';
  }

  // If this API call failed because of a claim auth issue, map it to an expected auth error
  if (
    error instanceof FrameMessengerAuthError ||
    (metadata?.stripeErrorCode &&
      EXPECTED_AUTH_ERRORS.includes(metadata.stripeErrorCode))
  ) {
    return 'auth_expected';
  }

  // We classify network errors differently, as we expect them to happen, but not too often
  if (
    metadata.stripeStatusCode === -1 ||
    metadata.stripeStatusCode === 0 ||
    metadata.stripeErrorCode === 'network_error'
  ) {
    return 'network';
  }

  // Special handling for expired sessions specifically - we don't want to report these to Sentry or alert on these, so we mark them as expected
  if (isInvalidOrExpiredSessionError(metadata.stripeStatusCode)) {
    return 'unauthorized';
  }

  return 'unexpected';
};

export const reportQueryOrMutationError = (
  analytics: IAnalytics,
  reportError: ReportError,
  error: any,
  queryOrMutationKey: string,
  hostApp: HostApp,
  // eslint-disable-next-line default-param-last
  expectedErrors: string[] = [],
  // eslint-disable-next-line default-param-last
  mutedErrors: string[] = [],
  meta?: string,
  duration?: number,
  owner?: SentryTeamName,
  component?: string,
) => {
  reportRequestError(
    analytics,
    reportError,
    'submerchant_surfaces_component_query_result',
    error,
    hostApp,
    expectedErrors,
    mutedErrors,
    meta,
    duration,
    queryOrMutationKey,
    owner,
    component,
  );
};

export const reportRequestError = (
  analytics: IAnalytics,
  reportError: ReportError,
  analyticsId: string,
  error: any,
  hostApp: HostApp,
  // eslint-disable-next-line default-param-last
  expectedErrors: string[] = [],
  // eslint-disable-next-line default-param-last
  mutedErrors: string[] = [],
  meta?: string,
  duration?: number,
  queryOrMutationKey?: string,
  owner?: SentryTeamName,
  component?: string,
) => {
  // Get stripe sdk error metadata if present
  const stripeErrorMetadata = getStripeOrGraphQLErrorMetadata(error);
  const isReadOnly = READONLY_HOST_APPS.includes(hostApp);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tags: any = {
    type: EmbeddedError.ApiError,
    ...stripeErrorMetadata,
    ...(owner
      ? {
          teamName: owner,
        }
      : {}),
    ...(component
      ? {
          component,
        }
      : {}),
  };

  if (queryOrMutationKey) {
    tags.queryKey = queryOrMutationKey;
  }

  let errorType = getErrorType(
    error,
    [
      ...expectedErrors,
      ...DEFAULT_GLOBALLY_EXPECTED_ERRORS,
      ...(isReadOnly ? DEFAULT_READONLY_EXPECTED_ERRORS : []),
    ],
    mutedErrors,
  );
  // The balance transaction total count query is expected to fail with a 500 error if the merchant has a large number of transactions
  // This is a known issue and we don't want to alert on it
  if (
    queryOrMutationKey === 'RetrieveAllBalanceTransactionsTotalCountQuery' &&
    errorType === 'unexpected'
  ) {
    errorType = 'expected';
  }

  const errorMetricName = errorMetricNames[errorType];

  // Send to analytics
  analytics.track(analyticsId, {
    ...tags,
    api_result: errorMetricName,
    duration,
  });

  // We do not report to Sentry if it's an expected, muted or network error
  if (
    errorType === 'muted' ||
    errorType === 'expected' ||
    errorType === 'auth_expected' ||
    errorType === 'network' ||
    errorType === 'file_read_expected' ||
    errorType === 'unauthorized'
  ) {
    return;
  }

  const extra = {
    meta: makeSafe(meta),
  };
  reportError(error, tags, extra, owner, 'error');
};

export const reportQueryOrMutationSuccess = (
  analytics: IAnalytics,
  queryOrMutationKey: string,
  duration?: number,
  owner?: SentryTeamName,
  component?: string,
) => {
  reportRequestSuccess(
    analytics,
    'submerchant_surfaces_component_query_result',
    duration,
    owner,
    queryOrMutationKey,
    component,
  );
};

export const reportRequestSuccess = (
  analytics: IAnalytics,
  analyticsId: string,
  duration?: number,
  owner?: SentryTeamName,
  queryOrMutationKey?: string,
  component?: string,
) => {
  analytics.track(analyticsId, {
    queryKey: queryOrMutationKey,
    api_result: 'success',
    duration,
    ...(owner
      ? {
          teamName: owner,
        }
      : {}),
    ...(component
      ? {
          component,
        }
      : {}),
  });
};
