import type {ErrorScope} from 'src/internal/errors/types';

export default function withSdkSignature(
  scope: ErrorScope | undefined,
): ErrorScope | undefined {
  return {
    ...scope,
    extras: {
      ...scope?.extras,
      // The `fromSdk` field is used in @dashboard/kernel to create a custom
      // fingerprint rule for errors coming from the sdk. This is a temporary
      // solution until we migrate dashboard to use the new observability sdk.
      fromSdk: true,
    },
  };
}
