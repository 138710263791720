import * as Sentry from '@sentry/browser';
import {
  extractErrorMessage,
  normalizeErrorLevel,
} from 'src/internal/errors/sentry/utils';

import type {Integration} from '@sentry/types';
import type {ImperativeAnalytics} from 'src/internal/analytics/types';
import type {
  ErrorEventMetricReport,
  ErrorEventReport,
  ImperativeMetrics,
  ObservabilityConfig,
  ProjectName,
} from 'src/types';

export default class SailObservabilityIntegration implements Integration {
  name: string;
  config: ObservabilityConfig;
  metrics: ImperativeMetrics;
  analytics: ImperativeAnalytics;

  constructor(
    config: ObservabilityConfig,
    metrics: ImperativeMetrics,
    analytics: ImperativeAnalytics,
  ) {
    this.name = 'SailObservabilityIntegration';
    this.config = config;
    this.metrics = metrics;
    this.analytics = analytics;
  }

  setupOnce(): void {
    Sentry.addGlobalEventProcessor((event) => {
      if (!event.sdk?.integrations?.includes(this.name)) {
        return event;
      }

      const eventId = event.event_id;
      const level = normalizeErrorLevel(event.level);
      const {
        service,
        page,
        release,
        project,
        error_handled: errorHandled,
      } = event.tags ?? {};

      const analyticsReport: ErrorEventReport = {
        ...event.tags,
        service: service ?? '<unknown>',
        page: page ?? '<unknown>',
        release: release ?? event.release ?? '<unknown>',
        project: (project as ProjectName) ?? '<unknown>',
        error_handled: errorHandled ?? 'false',
        error_message: extractErrorMessage(event) ?? '<unknown>',
        error_issue_id: eventId ?? '<unknown>',
        error_issue_url: eventId
          ? `https://sentry.corp.stripe.com/organizations/stripe/issues/?query=${eventId}`
          : '<unknown>',
      };

      const metricsReport: ErrorEventMetricReport = {
        page: analyticsReport.page,
        project: analyticsReport.project,
      };

      this.analytics.track(
        this.config,
        `frontend.observability.${level}`,
        analyticsReport,
      );

      // Not sending metrics for 'info' events as they're not used for alerting.
      if (level !== 'info') {
        this.metrics.increment(
          this.config,
          `frontend.observability.${level}`,
          metricsReport,
        );
      }

      return event;
    });
  }
}
