export type OnboardingStateValues = {
  isOnboardingComplete: boolean;
  detailsSubmitted: boolean;
};

export interface IOnboardingState {
  values: OnboardingStateValues;
  updateValues: (newValues: Partial<OnboardingStateValues>) => void;
  registerObserver: (observer: () => void) => void;
  unregisterObserver: (observer: () => void) => void;
}

export class OnboardingState implements IOnboardingState {
  private observers: ((values: OnboardingStateValues) => void)[];

  public values: OnboardingStateValues;

  constructor(_values?: OnboardingStateValues) {
    this.values = _values || {
      isOnboardingComplete: false, // Defaults to false
      detailsSubmitted: false, // Defaults to false
    };
    this.observers = [];
  }

  public updateValues(newValues: Partial<OnboardingStateValues>) {
    this.values = {...this.values, ...newValues};
    // eslint-disable-next-line no-restricted-syntax
    for (const observer of this.observers) {
      observer(this.values);
    }
  }

  public registerObserver(observer: () => void) {
    this.observers.push(observer);
  }

  public unregisterObserver(observer: () => void) {
    this.observers = this.observers.filter((o) => o !== observer);
  }
}
