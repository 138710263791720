import {useFlag} from '@sail/access';
import {usePlatformViewport} from '../PlatformViewportContext/PlatformViewportContext';
import {CONNECT_ELEMENT_IMPORTS} from '../../connect/ConnectJSInterface/ConnectElementList';
import type {ConnectElementImportKeys} from '../../connect/ConnectJSInterface/ConnectElementList';
import type {SafeAreaDimensions} from './calculatePosition';

const SAFE_AREA_TOP_CSS_VAR = '--safe-top';
const SAFE_AREA_LEFT_CSS_VAR = '--safe-left';

export const useShouldRenderMobileUI = (
  component: ConnectElementImportKeys,
) => {
  const {isMobileScreen} = usePlatformViewport();
  const enableMobileUIFlag = useFlag('enable_improved_mobile_ui');
  const connectElementConfig = CONNECT_ELEMENT_IMPORTS[component];

  const showMobilePopoverTooltip =
    isMobileScreen &&
    enableMobileUIFlag &&
    connectElementConfig?.applyMobileUIOverrides;

  return showMobilePopoverTooltip;
};

// Safe areas are the areas of a mobile device that are not covered by the system UI, such as the status bar, navigation bar, or notches.
// If an embedded component is rendered in one of our webviews, these CSS values are set on the platform frame and can be accessed via this function
export const getSafeAreaValue = (property: string): number => {
  try {
    return parseInt(
      // eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals
      getComputedStyle(document.documentElement).getPropertyValue(property) ||
        '0',
      10,
    );
  } catch (e) {
    return 0;
  }
};

export const getSafeAreaDimensions = () => {
  return {
    top: getSafeAreaValue(SAFE_AREA_TOP_CSS_VAR),
    left: getSafeAreaValue(SAFE_AREA_LEFT_CSS_VAR),
  };
};

export const safeAreaExists = (
  safeAreaDimensions: SafeAreaDimensions,
): boolean => {
  return safeAreaDimensions.top > 0 || safeAreaDimensions.left > 0;
};
