import bannerCount, {
  safeParams as bannerCountSafeParams,
} from 'src/internal/platformMetrics/trackers/bannerCount';
import dashboardPageTemplates, {
  safeParams as dashboardPageTemplatesSafeParams,
} from 'src/internal/platformMetrics/trackers/dashboardPageTemplates';
import orgGlobalsAreaCount, {
  safeParams as orgGlobalsAreaCountSafeParams,
} from 'src/internal/platformMetrics/trackers/orgGlobalsAreaCount';
import stripeletCount, {
  safeParams as stripeletCountSafeParams,
} from 'src/internal/platformMetrics/trackers/stripeletCount';
import {
  translationCount,
  safeParams as translationSafeParams,
} from 'src/internal/platformMetrics/trackers/translationCount';
import {
  translationCountForMessageLength,
  safeParams as translationwithWeightSafeParams,
} from 'src/internal/platformMetrics/trackers/translationCountForMessageLength';
import usageByMount, {
  safeParams as usageByMountSafeParams,
} from 'src/internal/platformMetrics/trackers/usageByMount';

import type {AbstractTracker} from 'src/internal/platformMetrics/aggregators/AbstractTracker';

export const trackers = {
  bannerCount,
  dashboardPageTemplates,
  stripeletCount,
  usageByMount,
  orgGlobalsAreaCount,
  translationCount,
  translationCountForMessageLength,
};

export const enabledTrackers: ReadonlyArray<
  AbstractTracker<Record<string, unknown>>
> = [
  bannerCount,
  dashboardPageTemplates,
  stripeletCount,
  usageByMount,
  orgGlobalsAreaCount,
  translationCount,
  translationCountForMessageLength,
];

export const trackersSafeParams = [
  ...bannerCountSafeParams,
  ...dashboardPageTemplatesSafeParams,
  ...stripeletCountSafeParams,
  ...usageByMountSafeParams,
  ...orgGlobalsAreaCountSafeParams,
  ...translationSafeParams,
  ...translationwithWeightSafeParams,
] as const;

export function throwsIfNotMocked() {
  throw new Error(
    "To properly assert tracking logic, make sure you call `jest.mock('src/observability/internal/platformMetrics/trackers')` within your test",
  );
}
