import type {AnalyticsParams} from 'src/internal/analytics/types';
import type {ObservabilityConfig} from 'src/types';

export default function buildParams<T extends string>(
  config: ObservabilityConfig,
  parameters?: AnalyticsParams<T>,
): AnalyticsParams<T> | undefined {
  const params = {
    ...(config.page ? {page: config.page} : {}),
    ...(config.project ? {project: config.project} : {}),
    ...(config.userId ? {user_id: config.userId} : {}),
    ...(config.release ? {release: config.release} : {}),
    ...(config.analytics?.parameters as any),
    ...parameters,
  };

  if (Object.keys(params).length === 0) {
    return undefined;
  }

  return params;
}
