export const getStatusRange = (status: number | undefined) => {
  if (!status || status < 100) {
    return 'unknown';
  }

  if (status < 200) {
    return '1xx';
  } else if (status < 300) {
    return '2xx';
  } else if (status < 400) {
    return '3xx';
  } else if (status < 500) {
    return '4xx';
  } else if (status < 600) {
    return '5xx';
  }

  return 'unknown';
};
