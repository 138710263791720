// TODO(afontcu): We should find a way to exclude expected errors without having to rely
// on the `EXCLUDE_FROM_SENTRY` symbol + beforeSend hack.

import {extractErrorMessage} from 'src/internal/errors/sentry/utils';

import type {Event, EventHint} from '@sentry/types';

export function beforeSend(
  event: Event,
  hint?: EventHint | undefined,
): Event | null {
  const transformedEvent = removeExcludedEvents(event, hint);

  return transformedEvent ? setFingerprint(transformedEvent) : null;
}

/**
 * Adding this symbol to an error will cause it to not be logged to Sentry. Use at your own risk.
 */
export const EXCLUDE_FROM_SENTRY = Symbol.for(
  'ExpectedErrorDoesNotLogToSentry',
);

const removeExcludedEvents = (
  event: Event,
  hint: EventHint | undefined,
): Event | null => {
  if (!event.exception || !event.exception.values?.length) {
    return event;
  }

  // @ts-expect-error TS2339: Property 'graphQLErrors' does not exist on type 'Error'
  const graphQLErrors = hint?.originalException?.graphQLErrors ?? [];

  const expectedErrorMessages = graphQLErrors.reduce((acc: any, error: any) => {
    if (error[EXCLUDE_FROM_SENTRY]) {
      acc.push(error.message);
    }
    return acc;
  }, []);

  // If there are any expected errors, remove it/them from values
  if (expectedErrorMessages?.length) {
    event.exception.values = event.exception.values.filter(
      (eventValue) => !expectedErrorMessages.includes(eventValue.value),
    );
  }

  // If after removing we still have errors, return the event. Otherwise, drop it
  return event.exception.values.length ? event : null;
};

const setFingerprint = (event: Event): Event => {
  event.fingerprint = ['{{ default }}', extractErrorMessage(event)].filter(
    (value) => value != null,
  ) as Array<string>;

  return event;
};
