export const platformUrlParamName = 'platformUrl';

const getPlatformURLHash = (): string | null => {
  // eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals
  const url = new URL(window.location.href);
  const hash = url.hash.replace('#', '');
  const hashParams = new URLSearchParams(hash);
  const platformURL = hashParams.get(platformUrlParamName);
  return platformURL;
};

export const getPlatformURL = (): string => {
  const platformUrlHash = getPlatformURLHash();
  if (platformUrlHash) {
    return platformUrlHash;
  }
  // eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals
  return window.location.href;
};

// We only extract the `platformUrl` parameter if the following is true:
// - The platformUrl parameter is provided
// - The current window is a trusted domain, i.e. connect-js.stripe.com
// - We are running in an iframe
export const getPlatformOrigin = (): string => {
  const platformUrlHash = getPlatformURLHash();
  if (platformUrlHash) {
    return new URL(platformUrlHash).origin;
  }
  // eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals
  return window.location.origin;
};
