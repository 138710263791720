import type {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';

export const financialAccountParameter = 'financial-account' as const;
const financialAccountTransactionsParams = [financialAccountParameter] as const;

type FinancialAccountTransactionsParams =
  (typeof financialAccountTransactionsParams)[number];
export type FinancialAccountTransactionsHtmlParams = Partial<
  Record<FinancialAccountTransactionsParams, string>
>;

export const FinancialAccountTransactionsConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "treasuryFinancialAccount" */
      '../../../components/Treasury/TransactionsList'
    ),
  enabledKey: 'financial_account_transactions',
  htmlAttributes: {
    [financialAccountParameter]: {
      isInternal: false,
    },
  },
  customHtmlElementMethods: {
    setFinancialAccount: {
      type: 'AttributeSettingMethod',
      attribute: financialAccountParameter,
      isInternal: false,
      description: 'Sets the financial account id to display transactions for.',
    },
  },
  releaseState: 'preview',
  teamOwner: 'ef_surfaces',
  description:
    'Displays the list of transactions for the given financial account.',
  docSitePath:
    '/connect/supported-embedded-components/financial-account-transactions',
};
