/**
 * This error occurs when calling a supplemental function, but it isn't implemented. This only happens from iframes
 */
export class PlatformLayerSupplementalFunctionNotImplementedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'PlatformLayerSupplementalFunctionNotImplementedError';
  }
}

export function isPlatformLayerSupplementalFunctionNotImplementedError(
  obj: any,
): obj is PlatformLayerSupplementalFunctionNotImplementedError {
  return (
    obj instanceof PlatformLayerSupplementalFunctionNotImplementedError ||
    (typeof obj === 'object' &&
      obj.name === 'PlatformLayerSupplementalFunctionNotImplementedError')
  );
}
