import type {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';
import {ComponentOverrideFlag} from '../../../utils/flags/flags';

export const appViewportDynamicImport = async () => {
  const dynamicImport = await import(
    /* webpackChunkName: "appViewport" */ '../../../components/Apps/AppViewport'
  );
  return dynamicImport;
};
export const appAppViewportParameter = 'app';
const appDataAppViewportParameter = 'appData';

export const AppViewportConfig: ConnectElementConfig = {
  Component: appViewportDynamicImport,
  htmlAttributes: {
    [appAppViewportParameter]: {
      isInternal: false,
    },
  },
  customHtmlElementMethods: {
    setApp: {
      type: 'AttributeSettingMethod',
      attribute: appAppViewportParameter,
      isInternal: false,
      description: 'Sets the app id for the app to render',
    },
    setAppData: {
      type: 'SupplementalObject',
      objectToSet: appDataAppViewportParameter,
      isInternal: false,
      description:
        'Sets the app data for the app to render. This is an arbitrary property bag that is passed in to the app.',
    },
  },
  syntheticTestInfo: {
    testUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/embedded-app-viewport',
    rcTestUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/afp-form-interaction',
  },
  enabledKey: 'app_viewport',
  enabledOverrideFlag: ComponentOverrideFlag.app_viewport,
  releaseState: 'preview',
  teamOwner: 'app_platform',
  description:
    'Renders a Stripe app - more specifically, renders a viewport of the configured stripe app id.',
  docSitePath: '/connect/supported-embedded-components/app-viewport',
};
