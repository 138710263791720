import {message} from '@sail/intl';
import type {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';
import {ComponentOverrideFlag} from '../../../utils/flags/flags';

const messages = {
  errorText: message({
    id: 'connectEmbed.notificationsBanner.errorBoundary.text',
    defaultMessage:
      'Your money is secure, but your payments information cannot be loaded here.',
    description:
      'Message shown when an unhandled error is caught in the notifications banner',
  }),
};

export const NotificationsBannerConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "notificationsBanner" */ '../../../components/Notifications/NotificationsBannerContainer'
    ),
  messageOverrides: messages,
  releaseState: 'ga',
  enabledKey: 'notification_banner',
  enabledOverrideFlag: ComponentOverrideFlag.notification_banner,
  componentControlledBackground: true,
  requiresAuthentication: true,
  teamOwner: 'accounts_ui',
  description:
    'When the account has risk interventions or compliance requirements currently due, the banner displays an entry prompting the user to resolve these. Additionally, it will display the "in review" status of those if they are pending. Renders blank if nothing is in review of currently due.',
  docSitePath: '/connect/supported-embedded-components/notifications-banner',
  htmlEvents: ['onnotificationschange'],
  customHtmlElementMethods: {
    // This is used to disable editing of specific ARKv2 sections.
    // Currently, only the company, person, support, and external account sections can be disabled.
    setDisabledSections: {
      type: 'SupplementalObject',
      objectToSet: 'arkV2DisabledSections',
      isInternal: true,
      description:
        'Sets the list of sections in account management that are disabled. Currently, only the company, person, support, and external account sections can be disabled.',
    },
    setMobileBannerOptions: {
      type: 'SupplementalObject',
      objectToSet: 'mobileBannerOptions',
      isInternal: true,
      description:
        'Sets the mobile view type of the banner in hosted dashboard',
    },
    setDisableBankConnections: {
      type: 'SupplementalObject',
      objectToSet: 'disableBankConnections',
      isInternal: true,
      description:
        'Disables the ability to add linked accounts in the external account form.',
    },
    setCollectionOptions: {
      type: 'SupplementalObject',
      objectToSet: 'collectionOptions',
      isInternal: false,
      description:
        'Sets the collection options for the account management form. Includes future_requirements and eventually_due.',
    },
    setOnNotificationsChange: {
      type: 'EventListenerMethod',
      event: 'onnotificationschange',
      isInternal: false,
      description:
        'Sets the event listener that allows a user to pass in a callback function that provides a total notifications count.',
    },
  },
  syntheticTestInfo: {
    testUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/sus-notibanner-iframe',
    rcTestUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/notibanner-iframe-rc',
  },
};
