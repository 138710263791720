/**
 * Referenced code from https://git.corp.stripe.com/stripe-internal/stripe-js-v3/blob/c5ac1ad7/src/shared/urls/urls.ts#L1
 * This file parses the URLs used to load fonts to decide whether they are relative and absolute URLs. This is needed because
 * we should support font definitions with relative urls when initializing ConnectJS. If the font source is a relative URL, we
 * convert it to an absolute URL so that it can be loaded correctly by the browser. If the font source is an absolute URL, we
 * leave it as is. These URLs are used in the `__resolveFontRelativeTo` property when loading the fonts in src/connect/Connect.ts.
 *
 * StripeJS supports both relative and absolute URLs:
 * - https://git.corp.stripe.com/stripe-internal/stripe-js-v3/pull/1475
 * - https://docs.google.com/document/d/10Ozxhv9KGbVtKDEmicj-e-eIoK1KegYq5cJjD-HUCw8/edit
 * */

type Url = {
  host: string;
  origin: string;
  protocol: string;
  pathname: string;
  hash: string;
};

const protocolRegExp = /^(http(s)?):\/\//;

export const isAbsoluteUrl = (url: string): boolean => {
  // Allow https and http protocol
  return protocolRegExp.test(url);
};

export const isAbsoluteUrlWithoutProtocol = (url: string): boolean => {
  // Allow just `//`.
  return /^\/\//.test(url);
};

export const isDataUrl = (url: string): boolean => /^data:/.test(url);

/**
 * Parses an absolute URL with either https: or http: protocol and
 * returns its components (protocol, host, origin).
 * For invalid or non-absolute URLs or URLs with other protocols,
 * it returns null.
 */
export const parseAbsoluteUrl = (url: string): Url | null | undefined => {
  // Return null for not absolute URLs.
  if (!isAbsoluteUrl(url)) {
    return null;
  }

  return new URL(url);
};

export const urlJoin = (absoluteUrl: string, relativeUrl: string): string => {
  if (relativeUrl[0] === '/') {
    const parsedUrl = parseAbsoluteUrl(absoluteUrl);
    if (parsedUrl) {
      return `${parsedUrl.origin}${relativeUrl}`;
    } else {
      return relativeUrl;
    }
  } else {
    // Replace everything after the last '/'
    const baseUrl = absoluteUrl.replace(/\/[^/]*$/, '/');
    return `${baseUrl}${relativeUrl}`;
  }
};
