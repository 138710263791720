export type FetchErrorResponse = {
  code?: string;
  httpStatus: number;
  message?: string;
  messageCode?: string;
  requestId?: string;
  type?: string;
};

export class FetchApiError extends Error {
  error: FetchErrorResponse;

  constructor(message: string, error: FetchErrorResponse) {
    super(message);
    this.error = error;
    this.name = 'FetchError';
  }
}

// The same as fetch, however it returns the body of the response
export type ApiFetchFn = (
  input: RequestInfo,
  init?: RequestInit,
) => Promise<any>; // We use any to represent the body returned. Ideally we could use <T> and ask for the type when the hook is defined

export const getFormURLEncodedBody = (
  body: Record<string, any>,
  parentKey: string | undefined = undefined,
  list: string[] = [],
) => {
  Object.entries(body).forEach(([key, val]) => {
    if (val !== null && val !== undefined) {
      if (typeof val === 'object') {
        getFormURLEncodedBody(
          val,
          parentKey ? `${parentKey}[${key}]` : key,
          list,
        );
      } else {
        list.push(
          `${parentKey ? `${parentKey}[${key}]` : key}=${encodeURIComponent(
            val,
          )}`,
        );
      }
    }
  });
  return list.join('&');
};
