import {AbstractTracker} from 'src/internal/platformMetrics/aggregators/AbstractTracker';

import type {ProjectName} from 'src/types';

export default class CounterWithAreaCount<
  TParams extends string,
> extends AbstractTracker<
  Record<
    TParams,
    {
      count: number;
      areaData: {
        [areaId: string]: Partial<{
          [K in ProjectName]: number;
        }>;
      };
    }
  >
> {
  track(id: TParams, areaId: string, poject: ProjectName): void {
    this.data[id] ??= Object.create(null);
    this.data[id].count ??= 0;
    this.data[id].count++;

    // extract the data for this id, defaulting to an empty object
    const areaData = this.data[id].areaData ?? Object.create(null);
    // extract the areadData for this area, defaulting to an empty object
    const dataForId = areaData[areaId] ?? Object.create(null);
    // extract the count for this poject, defaulting to 0
    const count = dataForId[poject] ?? 0;

    // reassign to the count, incremented by 1
    dataForId[poject] = count + 1;
    // reassign to the areaData, with the new data
    areaData[areaId] = dataForId;
    // reassign to the data, with the new areaData
    this.data[id].areaData = areaData;
  }
}
