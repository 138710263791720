export function transformNodeMapIntoRecord(
  map: NamedNodeMap,
): Record<string, string> {
  const record: Record<string, string> = {};
  for (let i = 0; i < map.length; i += 1) {
    const attr = map.item(i);
    if (attr) {
      const {name, value} = attr;
      if (name != null && value != null) {
        record[name] = value;
      }
    }
  }
  return record;
}
