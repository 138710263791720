/**
 * This function is a consistent way of dynamically importing the internals of account onboarding. This is used
 * in scenarios that do onboarding outside of the account onboarding connect embedded component (like notifications banner)
 */
export const accountOnboardingDynamicImport = async () => {
  const dynamicImport = await import(
    /* webpackChunkName: "accountOnboardingInternal" */ './AccountOnboarding'
  );
  return dynamicImport;
};
