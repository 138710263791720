import type {AppearanceVariablesKeys} from '@stripe-internal/embedded-theming';
// Used to define types where all AppearanceOptions are required to have a specific value type
type RequiredAppearanceRecord<T> = Record<AppearanceVariablesKeys, T>;
/**
 * The order in which properties appear in this object will be the order in which they are rendered in the doc site demo automatic
 * code sample
 * http://go/sus/integrationguide
 * When a new key is added, we must fit it in a particular order
 */
const appearanceVariablesOrder: RequiredAppearanceRecord<boolean> = {
  colorPrimary: false,
  colorText: false,
  colorBackground: false,
  buttonPrimaryColorBackground: false,
  buttonPrimaryColorBorder: false,
  buttonPrimaryColorText: false,
  buttonSecondaryColorBackground: false,
  buttonSecondaryColorBorder: false,
  buttonSecondaryColorText: false,
  colorSecondaryText: false,
  actionPrimaryColorText: false,
  actionPrimaryTextDecorationLine: false,
  actionPrimaryTextDecorationColor: false,
  actionPrimaryTextDecorationStyle: false,
  actionPrimaryTextDecorationThickness: false,
  actionSecondaryColorText: false,
  actionSecondaryTextDecorationLine: false,
  actionSecondaryTextDecorationColor: false,
  actionSecondaryTextDecorationStyle: false,
  actionSecondaryTextDecorationThickness: false,
  colorBorder: false,
  formHighlightColorBorder: false,
  formAccentColor: false,
  colorDanger: false,
  badgeNeutralColorBackground: false,
  badgeNeutralColorBorder: false,
  badgeNeutralColorText: false,
  badgeSuccessColorBackground: false,
  badgeSuccessColorBorder: false,
  badgeSuccessColorText: false,
  badgeWarningColorBackground: false,
  badgeWarningColorBorder: false,
  badgeWarningColorText: false,
  badgeDangerColorBackground: false,
  badgeDangerColorBorder: false,
  badgeDangerColorText: false,
  offsetBackgroundColor: false,
  formBackgroundColor: false,
  borderRadius: false,
  buttonBorderRadius: false,
  formBorderRadius: false,
  badgeBorderRadius: false,
  overlayBorderRadius: false,
  overlayZIndex: false,
  overlayBackdropColor: false,
  spacingUnit: false,
  fontFamily: false,
  fontSizeBase: false,
  headingXlFontSize: false,
  headingXlFontWeight: false,
  headingXlTextTransform: false,
  headingLgFontSize: false,
  headingLgFontWeight: false,
  headingLgTextTransform: false,
  headingMdFontSize: false,
  headingMdFontWeight: false,
  headingMdTextTransform: false,
  headingSmFontSize: false,
  headingSmFontWeight: false,
  headingSmTextTransform: false,
  headingXsFontSize: false,
  headingXsFontWeight: false,
  headingXsTextTransform: false,
  bodyMdFontSize: false,
  bodyMdFontWeight: false,
  bodySmFontSize: false,
  bodySmFontWeight: false,
  labelMdFontSize: false,
  labelMdFontWeight: false,
  labelMdTextTransform: false,
  labelSmFontSize: false,
  labelSmFontWeight: false,
  labelSmTextTransform: false,
};
export const appearanceVariablesOrderedArray = Object.keys(
  appearanceVariablesOrder,
);
