import type {IAnalytics} from '@sail/observability';

const bestEffortJsonStringify = (value: unknown): string => {
  try {
    return JSON.stringify(value);
  } catch (error) {
    return 'Unable to stringify value';
  }
};

export const validateAttributeValue = (
  setter: string | number,
  newValue: unknown,
  analytics: IAnalytics,
): string | undefined => {
  try {
    // We allow empty values to clear attributes, or strings, booleans and numbers
    if (
      newValue == null ||
      typeof newValue === 'string' ||
      typeof newValue === 'boolean' ||
      typeof newValue === 'number'
    ) {
      return undefined;
    }

    const errorMessage = `The value passed in to ${setter} must be a string, boolean, number or null/undefined`;
    analytics.track('submerchant_surfaces_invalid_attribute_value', {
      setter,
      typeOfValue: typeof newValue,
      jsonStringifiedValue: bestEffortJsonStringify(newValue),
      errorMessage,
    });
    return errorMessage;
  } catch (error) {
    const errorMessage = `An error occurred while validating the value passed in to ${setter}`;
    analytics.track('submerchant_surfaces_error_validating_attribute', {
      setter,
      typeOfValue: 'unknown',
      jsonStringifiedValue: bestEffortJsonStringify(newValue),
      errorMessage,
    });
    // If validation fails, we consider it invalid
    return errorMessage;
  }
};

export const validateEventListenerValue = (
  setter: string | number,
  newValue: unknown,
  analytics: IAnalytics,
): string | undefined => {
  try {
    // We allow empty values to clear event listeners, or a function
    if (newValue == null || typeof newValue === 'function') {
      return undefined;
    }

    const errorMessage = `The value passed in to ${setter} must be a function, or null/undefined`;
    analytics.track('submerchant_surfaces_invalid_event_listener_value', {
      setter,
      typeOfValue: typeof newValue,
      jsonStringifiedValue: bestEffortJsonStringify(newValue),
      errorMessage,
    });
    return errorMessage;
  } catch (error) {
    const errorMessage = `An error occurred while validating the value passed in to ${setter}`;
    analytics.track('submerchant_surfaces_error_validating_event_listener', {
      setter,
      typeOfValue: 'unknown',
      jsonStringifiedValue: bestEffortJsonStringify(newValue),
      errorMessage,
    });
    // If validation fails, we consider it invalid
    return errorMessage;
  }
};

export const validateSupplementalFunctionValue = (
  setter: string | number,
  newValue: unknown,
  analytics: IAnalytics,
): string | undefined => {
  try {
    // We allow empty values to clear supplemental functions, or a function
    if (newValue == null || typeof newValue === 'function') {
      return undefined;
    }

    const errorMessage = `The value passed in to ${setter} must be a function, or null/undefined`;
    analytics.track(
      'submerchant_surfaces_invalid_supplemental_function_value',
      {
        setter,
        typeOfValue: typeof newValue,
        jsonStringifiedValue: bestEffortJsonStringify(newValue),
        errorMessage,
      },
    );
    return errorMessage;
  } catch (error) {
    const errorMessage = `An error occurred while validating the value passed in to ${setter}`;
    analytics.track(
      'submerchant_surfaces_error_validating_supplemental_function',
      {
        setter,
        typeOfValue: 'unknown',
        jsonStringifiedValue: bestEffortJsonStringify(newValue),
        errorMessage,
      },
    );
    // If validation fails, we consider it invalid
    return errorMessage;
  }
};

const validateObjectIsSerializable = (
  setter: string | number,
  obj: unknown,
  analytics: IAnalytics,
): string | undefined => {
  try {
    JSON.stringify(obj);
    return undefined;
  } catch (error) {
    const errorMessage = `The object passed in to ${setter} is not serializable. Please ensure that all values are serializable.`;
    analytics.track('submerchant_surfaces_invalid_supplemental_object_value', {
      setter,
      typeOfValue: typeof obj,
      errorMessage,
    });
    return errorMessage;
  }
};

export const validateSupplementalObjectValue = (
  setter: string | number,
  newValue: unknown,
  analytics: IAnalytics,
): string | undefined => {
  try {
    // We allow empty values to clear supplemental objects, or an array, object, string or number
    if (
      newValue == null ||
      typeof newValue === 'number' ||
      typeof newValue === 'string' ||
      typeof newValue === 'boolean' ||
      Array.isArray(newValue) ||
      typeof newValue === 'object'
    ) {
      // We only tolerate serializable objects
      return validateObjectIsSerializable(setter, newValue, analytics);
    }

    const errorMessage = `The value passed in to ${setter} must be an array, object, string, number or null/undefined`;
    analytics.track('submerchant_surfaces_invalid_supplemental_object_value', {
      setter,
      typeOfValue: typeof newValue,
      jsonStringifiedValue: bestEffortJsonStringify(newValue),
      errorMessage,
    });
    return errorMessage;
  } catch (error) {
    const errorMessage = `An error occurred while validating the value passed in to ${setter}`;
    analytics.track(
      'submerchant_surfaces_error_validating_supplemental_object',
      {
        setter,
        typeOfValue: 'unknown',
        jsonStringifiedValue: bestEffortJsonStringify(newValue),
        errorMessage,
      },
    );
    // If validation fails, we consider it invalid
    return errorMessage;
  }
};
