import type {IApiErrorMetadata} from '@stripe-internal/connect-embedded-lib';
import * as React from 'react';

export const isInvalidOrExpiredSessionError = (
  statusCode: number | undefined,
) => {
  // This is what the API returns when the session is expired or invalidated
  // Currently we consider all 401's regardless of error code (invalid_request_error, api_key_expired)
  // as clear signals that the session is invalid
  return statusCode === 401;
};

export interface IEmbeddedInvalidOrExpiredSessionContext {
  isSessionInvalidOrExpired: boolean;
  setIsSessionInvalidOrExpired: (errorMetadata: IApiErrorMetadata) => void;
  latestError: IApiErrorMetadata | undefined;
}

const EmbeddedInvalidOrExpiredSessionContext =
  React.createContext<IEmbeddedInvalidOrExpiredSessionContext>({
    isSessionInvalidOrExpired: false,
    latestError: undefined,
    setIsSessionInvalidOrExpired: () => {},
  });

export const useEmbeddedInvalidOrExpiredSession = () => {
  return React.useContext(EmbeddedInvalidOrExpiredSessionContext);
};

export const EmbeddedSessionExpiredContextProvider: React.FC = ({children}) => {
  const [isSessionInvalidOrExpired, setIsSessionExpired] =
    React.useState(false);
  const [latestError, setLatestError] = React.useState<
    IApiErrorMetadata | undefined
  >(undefined);
  const setIsSessionInvalidOrExpired = React.useCallback(
    (errorMetadata: IApiErrorMetadata) => {
      setLatestError(errorMetadata);
      setIsSessionExpired(true);
    },
    [setLatestError, setIsSessionExpired],
  );

  return (
    <EmbeddedInvalidOrExpiredSessionContext.Provider
      value={{
        isSessionInvalidOrExpired,
        latestError,
        setIsSessionInvalidOrExpired,
      }}
    >
      {children}
    </EmbeddedInvalidOrExpiredSessionContext.Provider>
  );
};
