import {
  getPlatformURL,
  isStripeDomain,
} from '@stripe-internal/connect-embedded-lib';
import {isDevEnvironment} from '../isDevEnvironment';
import {FLAGS} from './flags';
import {getSearchParams} from '../searchParams/getSearchParams';

export const initializeFlagOverrides = (
  devEnvironment: boolean = isDevEnvironment,
  // eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals
  windowUrl: string = window.location.href,
) => {
  const result: Record<string, boolean> = {};
  const allowedValues = new Set(
    Object.values(FLAGS).map((v) => v.toLowerCase()),
  );
  const searchParams = getSearchParams(windowUrl);
  searchParams.forEach((value, key) => {
    if (!allowedValues.has(key.toLowerCase())) {
      return;
    }

    const boolValue = value.toLowerCase() === 'true';
    result[key] = boolValue;
  });

  const runningInStripeDomain = isStripeDomain(new URL(getPlatformURL()));

  const hasExternalDomainsFlag =
    result[FLAGS.STRIPE_INTERNAL_OVERRIDE_FLAGS_IN_EXTERNAL_DOMAINS];

  // We only allow tweaking these flags in dev environment or in specific domains, except when specifying OVERRIDE_FLAGS_IN_EXTERNAL_DOMAINS
  if (!runningInStripeDomain && !devEnvironment && !hasExternalDomainsFlag) {
    return {};
  }

  return result;
};

const flagOverrides: Record<string, boolean> = initializeFlagOverrides();
export const getFlagOverrides = () => {
  return flagOverrides;
};
