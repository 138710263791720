import rpc from 'src/internal/rpc/rpc';

const initClient = rpc.initClient.bind(rpc);
const initServer = rpc.initServer.bind(rpc);

export default {
  /**
   * Creates an RPC client that can be used by `@sail/observability`
   * to call the methods on a remote context.
   *
   * RPC clients are not meant to be used directly but
   * rather passed to an `ObservabilityProvider`
   * or to `ObservabilityConfig` in the case of
   * imperative methods.
   *
   * Note: In order to ensure correct behavior, it is
   * necessary to initialize an RPC server on the remote
   * context where the RPC client will be making calls.
   */
  initClient,
  /**
   * Creates an RPC server that can be used to receive
   * observability API calls from a remote context.
   */
  initServer,
};
