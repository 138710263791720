/* eslint-disable @stripe-internal/embedded/no-restricted-globals */

type SecureContextMetadataResult =
  | {result: 'pass'}
  | {result: 'error'; blockError: string}
  | {result: 'warn'; warnError: string};

export type ISecureContextMetadata = {
  evaluation: SecureContextMetadataResult;
  isSecureContext: boolean;
  isLocalhost: boolean;
  isAllowed: boolean;
};

export const livemodeMustUseHttpsMessage =
  'Live integrations must use HTTPS. For more information: https://stripe.com/docs/security/guide#tls';

// Warn or throw on non-HTTPS usage when appropriate.
// NB: This is not a security measure!! We run this check in outer where it is
// easily circumvented. This is just makes it harder for non-bad-actors to
// accidentally use Embedded components improperly.
export const evaluateBrowserSecureContext = (
  livemode: boolean,
): ISecureContextMetadata => {
  const {protocol} = window.location;

  const isLocalhost =
    ['localhost', '127.0.0.1', '0.0.0.0'].indexOf(window.location.hostname) !==
    -1;

  // Localhost is treated as a secure context by the potentially trustworthy origin
  // algorithm [0], but we historically have treated localhost as a non-trustworthy origin
  // for livemode payments.
  // [0] https://w3c.github.io/webappsec-secure-contexts/#is-origin-trustworthy
  const isSecureContext = window.isSecureContext === true && !isLocalhost;

  // Allow HTTPS, file:// (for Cordova / PhoneGap), ionic:// and httpsionic:// for Ionic,
  // capacitor:// for Capacitor, chrome-extension:// and moz-extension:// for Browser extensions.
  // All these are safe as they securely deliver the user application
  const isAllowed =
    isSecureContext ||
    [
      'https:',
      'file:',
      'ionic:',
      'httpsionic:',
      'capacitor:',
      'chrome-extension:',
      'moz-extension:',
    ].indexOf(protocol) !== -1;

  const youMayTestWithHttp =
    'You may test your integration over HTTP. However, live integrations must use HTTPS.';

  let evaluation: SecureContextMetadataResult = {result: 'pass'};
  if (!isAllowed) {
    if (livemode && !isLocalhost) {
      evaluation = {result: 'error', blockError: livemodeMustUseHttpsMessage};
    } else if (!livemode || isLocalhost) {
      evaluation = {result: 'warn', warnError: youMayTestWithHttp};
    } else {
      evaluation = {result: 'warn', warnError: livemodeMustUseHttpsMessage};
    }
  }

  return {
    evaluation,
    // The rest of the values are returned here for analytics purposes
    isSecureContext,
    isLocalhost,
    isAllowed,
  };
};
