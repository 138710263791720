import {createRpc} from '@sail/rpc';
import * as analytics from 'src/internal/analytics/analytics.server';
import * as reports from 'src/internal/errors/reports.server';
import * as metrics from 'src/internal/metrics/metrics.server';
import {NAMESPACE} from 'src/internal/rpc/constants';
import encoder from 'src/internal/rpc/encoder';

import type {RpcClient, RpcServer, Transport} from '@sail/rpc';

type RpcMethod<T extends (...args: any[]) => any> = {
  args: Parameters<T>;
  result: ReturnType<T>;
};

export type ObservabilityRpcMethods = {
  analyticsAction: RpcMethod<typeof analytics.action>;
  analyticsLink: RpcMethod<typeof analytics.link>;
  analyticsModal: RpcMethod<typeof analytics.modal>;
  analyticsTrack: RpcMethod<typeof analytics.track>;
  analyticsViewed: RpcMethod<typeof analytics.viewed>;
  metricsIncrement: RpcMethod<typeof metrics.increment>;
  metricsGauge: RpcMethod<typeof metrics.gauge>;
  metricsSet: RpcMethod<typeof metrics.set>;
  initReports: RpcMethod<typeof reports.initReports>;
  report: RpcMethod<typeof reports.report>;
};

const rpc: {
  initServer: (options: {transport: Transport}) => RpcServer;
  initClient: (options: {
    transport: Transport;
  }) => RpcClient<ObservabilityRpcMethods>;
} = createRpc<ObservabilityRpcMethods>({
  namespace: NAMESPACE,
  methods: {
    analyticsAction: analytics.action,
    analyticsLink: analytics.link,
    analyticsModal: analytics.modal,
    analyticsTrack: analytics.track,
    analyticsViewed: analytics.viewed,
    metricsIncrement: metrics.increment,
    metricsGauge: metrics.gauge,
    metricsSet: metrics.set,
    initReports: reports.initReports,
    report: reports.report,
  },
  encoder,
});

export default rpc;
