/* eslint-disable @stripe-internal/embedded/no-restricted-globals */
import {platformUrlParamName} from '@stripe-internal/connect-embedded-lib';
import {runningInIframeParamName} from '../ui-layer-frame/utils/isRunningInIframe';
import {getFlagOverrides} from '../utils/flags/flagOverrides';
import {getStronglyTypedEntries} from '../utils/getStronglyTypedEntries';
import {getCurrentPageViewId} from './Metrics';
import {getCurrentScriptUrlContext} from '../utils/getCurrentScriptUrlContext';
import {nextId} from '../connect/utils/ids';

export const pageViewIdParamName = 'pageViewId';
export const disableAnalyticsParamName = 'disableAnalytics';

export const buildDataLayerFrameUrl = (
  absoluteFolderPath: string,
  platformUrl: string,
) => {
  // Note: we want to make sure to pull the data layer that corresponds to this build of connect.js - which is why
  // we include the webpack hash here
  const url = new URL(
    // eslint-disable-next-line camelcase
    `${absoluteFolderPath}/data_layer_${__webpack_hash__}.html`,
  );

  const searchParams = new URLSearchParams();

  // Pipe all of the flags through
  const flagOverrides = getFlagOverrides();
  getStronglyTypedEntries(flagOverrides).forEach((entry) => {
    searchParams.set(entry[0], entry[1].toString());
  });

  searchParams.append(pageViewIdParamName, getCurrentPageViewId());

  // Set platform URL so that getFlagOverrides knows whether the platform page is an internal domain
  searchParams.set(platformUrlParamName, platformUrl);

  // Set runningInIframe so that we can check whether we are running in an iframe
  searchParams.set(runningInIframeParamName, 'true');

  url.hash = searchParams.toString();
  return url;
};

export const buildDataLayerFrame = (
  url: string,
  connectInstanceId: string,
): Promise<HTMLIFrameElement> => {
  return new Promise<HTMLIFrameElement>((resolve, reject) => {
    const element = document.createElement('iframe');
    element.style.display = 'none';
    element.src = url;
    element.name = `connectjs_controller_${connectInstanceId}`;
    element.id = 'stripeDataLayerFrame';
    element.onload = () => {
      resolve(element);
    };
    element.onerror = reject;
    document.body.appendChild(element);
  });
};

const eagerConnectInstanceId: string = nextId('connect-instance');
let eagerDataLayer: Promise<HTMLIFrameElement> | undefined;

export const preloadDataLayer = () => {
  eagerDataLayer = buildDataLayerFrame(
    buildDataLayerFrameUrl(
      getCurrentScriptUrlContext().absoluteFolderPath,
      window.location.href,
    ).href,
    eagerConnectInstanceId,
  );
};

export const getEagerDataLayerAndConnectInstanceId = () => {
  if (eagerDataLayer == null) {
    return undefined;
  }
  const temp = eagerDataLayer;
  eagerDataLayer = undefined;
  return {
    eagerDataLayer: temp,
    eagerConnectInstanceId,
  };
};
