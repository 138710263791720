import _ from 'lodash';
import {isRecordStringUnknown} from '@stripe-internal/embedded-theming';
import {getStronglyTypedEntries} from '../../utils/getStronglyTypedEntries';
import {getDevLogger} from '../../utils/getLogger';

const devLogger = getDevLogger();

const transformers = [
  // Color Primary
  {
    old: 'appearance.colors.primary',
    new: 'appearance.variables.colorPrimary',
  },
  {
    old: 'appearance.colorPrimary',
    new: 'appearance.variables.colorPrimary',
  },

  // Typography
  {
    old: 'appearance.bodyMediumFontSize',
    new: 'appearance.variables.bodyMdFontSize',
  },
  {
    old: 'appearance.bodyMediumFontWeight',
    new: 'appearance.variables.bodyMdFontWeight',
  },
  {
    old: 'appearance.bodySmallFontSize',
    new: 'appearance.variables.bodySmFontSize',
  },
  {
    old: 'appearance.bodySmallFontWeight',
    new: 'appearance.variables.bodySmFontWeight',
  },

  {
    old: 'appearance.labelLargeFontSize',
    new: 'appearance.variables.labelLgFontSize',
  },
  {
    old: 'appearance.labelLargeFontWeight',
    new: 'appearance.variables.labelLgFontWeight',
  },
  {
    old: 'appearance.labelLargeTextTransform',
    new: 'appearance.variables.labelLgTextTransform',
  },
  {
    old: 'appearance.labelMediumFontSize',
    new: 'appearance.variables.labelMdFontSize',
  },
  {
    old: 'appearance.labelMediumFontWeight',
    new: 'appearance.variables.labelMdFontWeight',
  },
  {
    old: 'appearance.labelMediumTextTransform',
    new: 'appearance.variables.labelMdTextTransform',
  },
  {
    old: 'appearance.labelSmallFontSize',
    new: 'appearance.variables.labelSmFontSize',
  },
  {
    old: 'appearance.labelSmallFontWeight',
    new: 'appearance.variables.labelSmFontWeight',
  },
  {
    old: 'appearance.labelSmallTextTransform',
    new: 'appearance.variables.labelSmTextTransform',
  },

  {
    old: 'appearance.headingXLargeFontSize',
    new: 'appearance.variables.headingXlFontSize',
  },
  {
    old: 'appearance.headingXLargeFontWeight',
    new: 'appearance.variables.headingXlFontWeight',
  },
  {
    old: 'appearance.headingXLargeTextTransform',
    new: 'appearance.variables.headingXlTextTransform',
  },
  {
    old: 'appearance.headingLargeFontSize',
    new: 'appearance.variables.headingLgFontSize',
  },
  {
    old: 'appearance.headingLargeFontWeight',
    new: 'appearance.variables.headingLgFontWeight',
  },
  {
    old: 'appearance.headingLargeTextTransform',
    new: 'appearance.variables.headingLgTextTransform',
  },
  {
    old: 'appearance.headingMediumFontSize',
    new: 'appearance.variables.headingMdFontSize',
  },
  {
    old: 'appearance.headingMediumFontWeight',
    new: 'appearance.variables.headingMdFontWeight',
  },
  {
    old: 'appearance.headingMediumTextTransform',
    new: 'appearance.variables.headingMdTextTransform',
  },
  {
    old: 'appearance.headingSmallFontSize',
    new: 'appearance.variables.headingSmFontSize',
  },
  {
    old: 'appearance.headingSmallFontWeight',
    new: 'appearance.variables.headingSmFontWeight',
  },
  {
    old: 'appearance.headingSmallTextTransform',
    new: 'appearance.variables.headingSmTextTransform',
  },
  {
    old: 'appearance.headingXSmallFontSize',
    new: 'appearance.variables.headingXsFontSize',
  },
  {
    old: 'appearance.headingXSmallFontWeight',
    new: 'appearance.variables.headingXsFontWeight',
  },
  {
    old: 'appearance.headingXSmallTextTransform',
    new: 'appearance.variables.headingXsTextTransform',
  },

  {old: 'appearance.spacingScale', new: 'appearance.variables.spacingUnit'},

  // {Component}{Property} changes
  {
    old: 'appearance.colorPrimaryButtonBackground',
    new: 'appearance.variables.buttonPrimaryColorBackground',
  },
  {
    old: 'appearance.colorPrimaryButtonBorder',
    new: 'appearance.variables.buttonPrimaryColorBorder',
  },
  {
    old: 'appearance.colorPrimaryButtonText',
    new: 'appearance.variables.buttonPrimaryColorText',
  },

  {
    old: 'appearance.colorSecondaryButtonBackground',
    new: 'appearance.variables.buttonSecondaryColorBackground',
  },
  {
    old: 'appearance.colorSecondaryButtonBorder',
    new: 'appearance.variables.buttonSecondaryColorBorder',
  },
  {
    old: 'appearance.colorSecondaryButtonText',
    new: 'appearance.variables.buttonSecondaryColorText',
  },

  {
    old: 'appearance.colorPrimaryLinkText',
    new: 'appearance.variables.actionPrimaryColorText',
  },
  {
    old: 'appearance.colorSecondaryLinkText',
    new: 'appearance.variables.actionSecondaryColorText',
  },

  {
    old: 'appearance.colorFormHighlightBorder',
    new: 'appearance.variables.formHighlightColorBorder',
  },
  {
    old: 'appearance.colorFormAccent',
    new: 'appearance.variables.formAccentColor',
  },

  {
    old: 'appearance.colorOffsetBackground',
    new: 'appearance.variables.offsetBackgroundColor',
  },
  {
    old: 'appearance.colorFormBackground',
    new: 'appearance.variables.formBackgroundColor',
  },

  {
    old: 'appearance.borderRadiusButton',
    new: 'appearance.variables.buttonBorderRadius',
  },
  {
    old: 'appearance.borderRadiusForm',
    new: 'appearance.variables.formBorderRadius',
  },
  {
    old: 'appearance.borderRadiusBadge',
    new: 'appearance.variables.badgeBorderRadius',
  },
  {
    old: 'appearance.borderRadiusOverlay',
    new: 'appearance.variables.overlayBorderRadius',
  },

  // Add transforms to move to variables nesting. Note: appearance.new variable name needs transformation
  // Typography
  {
    old: 'appearance.bodyMdFontWeight',
    new: 'appearance.variables.bodyMdFontWeight',
  },
  {
    old: 'appearance.bodySmFontSize',
    new: 'appearance.variables.bodySmFontSize',
  },
  {
    old: 'appearance.bodySmFontWeight',
    new: 'appearance.variables.bodySmFontWeight',
  },
  {
    old: 'appearance.labelMdFontSize',
    new: 'appearance.variables.labelMdFontSize',
  },
  {
    old: 'appearance.labelMdFontWeight',
    new: 'appearance.variables.labelMdFontWeight',
  },
  {
    old: 'appearance.labelMdTextTransform',
    new: 'appearance.variables.labelMdTextTransform',
  },
  {
    old: 'appearance.labelSmFontSize',
    new: 'appearance.variables.labelSmFontSize',
  },
  {
    old: 'appearance.labelSmFontWeight',
    new: 'appearance.variables.labelSmFontWeight',
  },
  {
    old: 'appearance.labelSmTextTransform',
    new: 'appearance.variables.labelSmTextTransform',
  },

  {
    old: 'appearance.headingXlFontSize',
    new: 'appearance.variables.headingXlFontSize',
  },
  {
    old: 'appearance.headingXlFontWeight',
    new: 'appearance.variables.headingXlFontWeight',
  },
  {
    old: 'appearance.headingXlTextTransform',
    new: 'appearance.variables.headingXlTextTransform',
  },
  {
    old: 'appearance.headingLgFontSize',
    new: 'appearance.variables.headingLgFontSize',
  },
  {
    old: 'appearance.headingLgFontWeight',
    new: 'appearance.variables.headingLgFontWeight',
  },
  {
    old: 'appearance.headingLgTextTransform',
    new: 'appearance.variables.headingLgTextTransform',
  },
  {
    old: 'appearance.headingMdFontSize',
    new: 'appearance.variables.headingMdFontSize',
  },
  {
    old: 'appearance.headingMdFontWeight',
    new: 'appearance.variables.headingMdFontWeight',
  },
  {
    old: 'appearance.headingMdTextTransform',
    new: 'appearance.variables.headingMdTextTransform',
  },
  {
    old: 'appearance.headingSmFontSize',
    new: 'appearance.variables.headingSmFontSize',
  },
  {
    old: 'appearance.headingSmFontWeight',
    new: 'appearance.variables.headingSmFontWeight',
  },
  {
    old: 'appearance.headingSmTextTransform',
    new: 'appearance.variables.headingSmTextTransform',
  },
  {
    old: 'appearance.headingXsFontSize',
    new: 'appearance.variables.headingXsFontSize',
  },
  {
    old: 'appearance.headingXsFontWeight',
    new: 'appearance.variables.headingXsFontWeight',
  },
  {
    old: 'appearance.headingXsTextTransform',
    new: 'appearance.variables.headingXsTextTransform',
  },

  {old: 'appearance.fontFamily', new: 'appearance.variables.fontFamily'},
  {old: 'appearance.spacingUnit', new: 'appearance.variables.spacingUnit'},
  {old: 'appearance.borderRadius', new: 'appearance.variables.borderRadius'},
  {old: 'appearance.fontSizeBase', new: 'appearance.variables.fontSizeBase'},

  {
    old: 'appearance.badgeNeutralBorderColor',
    new: 'appearance.variables.badgeNeutralColorBorder',
  },
  {
    old: 'appearance.badgeNeutralBackgroundColor',
    new: 'appearance.variables.badgeNeutralColorBackground',
  },
  {
    old: 'appearance.badgeNeutralTextColor',
    new: 'appearance.variables.badgeNeutralColorText',
  },

  {
    old: 'appearance.badgeSuccessBorderColor',
    new: 'appearance.variables.badgeSuccessColorBorder',
  },
  {
    old: 'appearance.badgeSuccessBackgroundColor',
    new: 'appearance.variables.badgeSuccessColorBackground',
  },
  {
    old: 'appearance.badgeSuccessTextColor',
    new: 'appearance.variables.badgeSuccessColorText',
  },

  {
    old: 'appearance.badgeWarningBorderColor',
    new: 'appearance.variables.badgeWarningColorBorder',
  },
  {
    old: 'appearance.badgeWarningBackgroundColor',
    new: 'appearance.variables.badgeWarningColorBackground',
  },
  {
    old: 'appearance.badgeWarningTextColor',
    new: 'appearance.variables.badgeWarningColorText',
  },

  {
    old: 'appearance.badgeDangerBorderColor',
    new: 'appearance.variables.badgeDangerColorBorder',
  },
  {
    old: 'appearance.badgeDangerBackgroundColor',
    new: 'appearance.variables.badgeDangerColorBackground',
  },
  {
    old: 'appearance.badgeDangerTextColor',
    new: 'appearance.variables.badgeDangerColorText',
  },

  {
    old: 'appearance.colorBackground',
    new: 'appearance.variables.colorBackground',
  },
  {
    old: 'appearance.colorText',
    new: 'appearance.variables.colorText',
  },
  {
    old: 'appearance.colorSecondaryText',
    new: 'appearance.variables.colorSecondaryText',
  },
  {
    old: 'appearance.colorBorder',
    new: 'appearance.variables.colorBorder',
  },
  {
    old: 'appearance.colorDanger',
    new: 'appearance.variables.colorDanger',
  },

  {
    old: 'appearance.buttonPrimaryColorBackground',
    new: 'appearance.variables.buttonPrimaryColorBackground',
  },
  {
    old: 'appearance.buttonPrimaryColorBorder',
    new: 'appearance.variables.buttonPrimaryColorBorder',
  },
  {
    old: 'appearance.buttonPrimaryColorText',
    new: 'appearance.variables.buttonPrimaryColorText',
  },

  {
    old: 'appearance.buttonSecondaryColorBackground',
    new: 'appearance.variables.buttonSecondaryColorBackground',
  },
  {
    old: 'appearance.buttonSecondaryColorBorder',
    new: 'appearance.variables.buttonSecondaryColorBorder',
  },
  {
    old: 'appearance.buttonSecondaryColorText',
    new: 'appearance.variables.buttonSecondaryColorText',
  },

  {
    old: 'appearance.actionPrimaryColorText',
    new: 'appearance.variables.actionPrimaryColorText',
  },
  {
    old: 'appearance.actionSecondaryColorText',
    new: 'appearance.variables.actionSecondaryColorText',
  },

  {
    old: 'appearance.formHighlightColorBorder',
    new: 'appearance.variables.formHighlightColorBorder',
  },
  {
    old: 'appearance.formAccentColor',
    new: 'appearance.variables.formAccentColor',
  },

  {
    old: 'appearance.offsetBackgroundColor',
    new: 'appearance.variables.offsetBackgroundColor',
  },
  {
    old: 'appearance.formBackgroundColor',
    new: 'appearance.variables.formBackgroundColor',
  },

  {
    old: 'appearance.buttonBorderRadius',
    new: 'appearance.variables.buttonBorderRadius',
  },
  {
    old: 'appearance.formBorderRadius',
    new: 'appearance.variables.formBorderRadius',
  },
  {
    old: 'appearance.badgeBorderRadius',
    new: 'appearance.variables.badgeBorderRadius',
  },
  {
    old: 'appearance.overlayBorderRadius',
    new: 'appearance.variables.overlayBorderRadius',
  },

  // UIConfig deletion
  {old: 'uiConfig.overlay', new: 'appearance.overlays'},
  {old: 'uiConfig.overlayZIndex', new: 'appearance.variables.overlayZIndex'},
];

const transformersMap: Record<string, string> = transformers.reduce(
  (acc: Record<string, string>, {old, new: newKey}) => {
    acc[old] = newKey;
    return acc;
  },
  {},
);

export const flattenObject = (
  obj: Record<string, unknown>,
  parentKey?: string,
) => {
  let result: Record<string, unknown> = {};

  Object.entries(obj).forEach(([key, value]) => {
    const newKey = parentKey ? `${parentKey}.${key}` : key;
    if (isRecordStringUnknown(value)) {
      result = {...result, ...flattenObject(value, newKey)};
    } else {
      result[newKey] = value;
    }
  });

  return result;
};

const unflattenObject = (
  flattenedObject: Record<string, unknown>,
): Record<string, unknown> => {
  const result: Record<string, unknown> = {};
  _.keys(flattenedObject).forEach(function (key, _value) {
    _.set(result, key, flattenedObject[key]);
  });
  return result;
};

const transformVariables = (options: Record<string, unknown>) => {
  const flattenedOptions = flattenObject(options);

  const newOptions: Record<string, unknown> = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of getStronglyTypedEntries(flattenedOptions)) {
    const transformNewKey = transformersMap[key];
    if (!transformNewKey) {
      // If there is no transformer assigned for this entry, simply copy it over and skip
      newOptions[key] = value;
      // eslint-disable-next-line no-continue
      continue;
    }

    if (transformNewKey in flattenedOptions) {
      devLogger.warn(
        `When performing the replacement ${key} -> ${transformNewKey}, encountered a pre-existing value in ${transformNewKey}. Skipping rewrite.`,
      );
      newOptions[key] = value;
      // eslint-disable-next-line no-continue
      continue;
    }

    // Use the transform
    newOptions[transformNewKey] = value;
  }

  const unflattenedOptions = unflattenObject(newOptions);

  return unflattenedOptions;
};

// This function is in place to faciliate backwards compatibility with
// old theming API variable names.
export const transformInitOptions = (options: unknown): unknown => {
  if (!isRecordStringUnknown(options)) {
    return options;
  }

  return transformVariables(options);
};
