interface IConnectionType {
  effectiveType: string;
}

interface INavigatorWithConnectionType {
  connection: IConnectionType;
}

const isNavigatorWithEffectiveConnectionType = (
  navigator: any,
): navigator is INavigatorWithConnectionType => {
  if (
    !navigator ||
    typeof navigator !== 'object' ||
    !('connection' in navigator)
  ) {
    return false;
  }

  const navigatorConnection = navigator.connection;
  if (
    !navigatorConnection ||
    typeof navigatorConnection !== 'object' ||
    !('effectiveType' in navigatorConnection)
  ) {
    return false;
  }

  if (
    !navigatorConnection ||
    typeof navigatorConnection.effectiveType !== 'string'
  ) {
    return false;
  }

  return true;
};

export const getEffectiveConnectionTypeFromNavigator = (
  navigator: unknown,
): string => {
  if (!isNavigatorWithEffectiveConnectionType(navigator)) {
    return 'unknown';
  }

  return navigator.connection.effectiveType;
};
