/**
 * Attempt to parse out livemode from a given publishable key.
 */
export function livemodeFromPublishableKey(
  publishableKey: unknown,
  defaultValue: boolean,
): boolean {
  // Try to parse out from the key
  if (
    typeof publishableKey === 'string' &&
    publishableKey.startsWith('pk_live_')
  ) {
    return true;
  }
  if (
    typeof publishableKey === 'string' &&
    publishableKey.startsWith('pk_test_')
  ) {
    return false;
  }

  // We couldn't fine it, so use whatever the default should be
  return defaultValue;
}
