import {Deferred} from '@sail/utils';
import {MessageType} from 'src/internal/rpc-channel/types';
import {v4 as uuid} from 'uuid';

import type {Encoder} from 'src/internal/encoder/types';
import type {
  AddSubscriptionMessage,
  CallErrorMessage,
  CallMessage,
  CallResponseMessage,
  ChannelConfig,
  MethodHandler,
  MethodName,
  RPCMessage,
  RPCMethods,
  RPCSubscriptions,
  RemoveSubscriptionMessage,
  RequestId,
  Subscription,
  SubscriptionCallback,
  SubscriptionId,
  SubscriptionName,
  SubscriptionProducer,
  SubscriptionResultMessage,
} from 'src/internal/rpc-channel/types';
import type {Transport} from 'src/internal/transport/types';

const RPC_CHANNEL_SRC = 'sdk-rpc-channel';

const transportMessageTypes = new Set(Object.keys(MessageType));

/**
 * An instance of `RpcChannel` represents one end of an asynchronous communications channel
 * that can be used for communicating between different browsing contexts or threads.
 * `RpcChannel` is independent of the underlying communication medium/transport so that
 * it's possible to use iframe, MessagePort or WebWorker or any other "messenger" that
 * implements an abstract `Transport` interface. For instance, in Node one can use a transport
 * built on top of MessagePort, while in browsers it's possible to use a Worker-based transport.
 *
 * When using `RpcChannel`, one instance of `RpcChannel` should be created on each side.
 * For instance, one side creates an `RpcChannel` that is assigned a role of client/caller,
 * while another one creates an `RpcChannel` that serves as an executor/server. The "server"
 * instance is expected to provide method handlers and subscription producers that satisfy
 * a given RPC protocol describing the signatures of remote methods and shape of data returned
 * by them.
 */
export default class RpcChannel<
  Methods extends RPCMethods,
  Subscriptions extends RPCSubscriptions = RPCSubscriptions,
> {
  private name: string;
  private transport: Transport;
  private enableLogging: boolean;
  private responses = new Map<RequestId, Deferred<any>>();
  private callbacks = new Map<SubscriptionId, SubscriptionCallback<any>>();
  private handlers = new Map<MethodName, MethodHandler<any, any>>();
  private subscriptions = new Map<SubscriptionId, Subscription>();
  private messageListener: (event: MessageEvent) => void;
  private encoder?: Encoder;
  private producers = new Map<
    SubscriptionName,
    SubscriptionProducer<any, any>
  >();

  private closed = false;

  constructor({
    name,
    transport,
    methods,
    subscriptions,
    enableLogging,
    encoder,
  }: ChannelConfig<Methods, Subscriptions>) {
    this.name = name;
    this.transport = transport;
    this.enableLogging = enableLogging ?? false;
    this.encoder = encoder;

    for (const name in methods) {
      if (methods.hasOwnProperty(name)) {
        this.handlers.set(name, methods[name]);
      }
    }
    for (const name in subscriptions) {
      if (subscriptions.hasOwnProperty(name)) {
        this.producers.set(name, subscriptions[name]);
      }
    }

    this.messageListener = (evt: MessageEvent) => {
      if (this.isRelevantMessageEvent(evt)) {
        this.handleMessage(evt.data.message as RPCMessage);
      }
    };
    this.transport.addEventListener('message', this.messageListener);
  }

  private isRelevantMessageEvent({data}: MessageEvent): boolean {
    return (
      !!data.message &&
      transportMessageTypes.has(data.message.type) &&
      this.name === data.from &&
      data.source === RPC_CHANNEL_SRC
    );
  }

  // Channel Interface

  /**
   * Calls a given `method` with a given set of `args` remotely on the other side
   * `RpcChannel` that is assigned a role of "server".
   *
   * Returns a promise that is either resolved with a result of remote call or with
   * an error that could be thrown when invoking a given a handler or when no handler
   * was defined at all for a specified method on the other side of `RpcChannel`.
   */
  call<M extends keyof Methods>(
    method: M,
    ...args: Methods[M]['args']
  ): Promise<Methods[M]['result']> {
    if (this.closed) {
      return Promise.reject(
        new Error(
          `Cannot call method '${method as string}'. RPC channel '${
            this.name
          }' is closed.`,
        ),
      );
    }

    const id = uuid();
    const response = new Deferred<Methods[M]['result']>();

    this.responses.set(id, response);

    this.sendMessage({
      type: MessageType.CALL,
      id,
      method: method as string,
      args,
    });

    return response.promise.finally(() => this.responses.delete(id));
  }

  /**
   * Creates a client subscription to a stream of messages generated
   * by the server. Each incoming message whose shape is defined by
   * `Payload` is passed to a `callback` function.
   */
  watch<S extends keyof Subscriptions>(
    name: S,
    payload: Subscriptions[S]['payload'],
    callback: SubscriptionCallback<Subscriptions[S]['result']>,
  ): () => void {
    if (this.closed) {
      throw new Error(
        `Cannot subscribe to '${name as string}'. RPC channel '${
          this.name
        }' is closed.`,
      );
    }

    const id = uuid();

    this.callbacks.set(id, callback);

    this.sendMessage({
      id,
      type: MessageType.ADD_SUBSCRIPTION,
      name: name as string,
      payload,
    });

    return () => {
      this.callbacks.delete(id);
      this.sendMessage({id, type: MessageType.REMOVE_SUBSCRIPTION});
    };
  }

  close() {
    this.closed = true;
    this.transport.terminate?.();
    this.transport.removeEventListener('message', this.messageListener);
  }

  // Private Implementation

  private sendMessage(message: RPCMessage) {
    const encodedMessage = this.encoder
      ? this.encoder.encode(message)
      : message;

    this.log(`> SND ${message.type}, ${message.id}`);

    this.transport.postMessage({
      message: encodedMessage,
      from: this.name,
      source: RPC_CHANNEL_SRC,
    });
  }

  private handleMessage(message: RPCMessage): void {
    const decodedMessage = this.encoder
      ? this.encoder.decode(message)
      : message;

    switch (decodedMessage.type) {
      case MessageType.CALL:
        this.handleCall(decodedMessage);
        break;
      case MessageType.CALL_RESPONSE:
        this.handleСallResponse(decodedMessage);
        break;
      case MessageType.CALL_ERROR:
        this.handleCallError(decodedMessage);
        break;
      case MessageType.ADD_SUBSCRIPTION:
        this.handleAddSubscription(decodedMessage);
        break;
      case MessageType.REMOVE_SUBSCRIPTION:
        this.handleRemoveSubscription(decodedMessage);
        break;
      case MessageType.SUBSCRIPTION_RESULT:
        this.handleSubscriptionResult(decodedMessage);
        break;
    }
  }

  protected async handleCall({method, id, args}: CallMessage) {
    const handler = this.handlers.get(method);

    if (handler) {
      try {
        this.log(`< RCV ${MessageType.CALL}, ${id}, ${method}, ${args}`);

        this.sendMessage({
          id,
          type: MessageType.CALL_RESPONSE,
          result: await handler(...args),
        });
      } catch (err) {
        this.sendMessage({
          id,
          type: MessageType.CALL_ERROR,
          error: err as Error,
        });
      }
    } else {
      this.sendMessage({
        id,
        type: MessageType.CALL_ERROR,
        error: new Error(`Unsupported method: ${method}`),
      });
    }
  }

  private handleСallResponse({id, result}: CallResponseMessage) {
    const resp = this.responses.get(id);
    if (resp) {
      this.log(`< RCV ${MessageType.CALL_RESPONSE}, ${id}, ${result}`);

      resp.resolve(result);
    }
  }

  private handleCallError({id, error}: CallErrorMessage) {
    const resp = this.responses.get(id);
    if (resp) {
      this.log(`< RCV ${MessageType.CALL}, ${id}, ${error}`);

      resp.reject(error);
    }
  }

  private handleSubscriptionResult({id, result}: SubscriptionResultMessage) {
    const callback = this.callbacks.get(id);
    if (callback) {
      this.log(`< RCV ${MessageType.SUBSCRIPTION_RESULT}, ${id}, ${result}`);
      callback(result);
    }
  }

  private handleAddSubscription({id, name, payload}: AddSubscriptionMessage) {
    const producer = this.producers.get(name);
    if (producer && !this.subscriptions.has(id)) {
      this.log(
        `< RCV ${MessageType.ADD_SUBSCRIPTION}, ${id}, ${name}, ${payload}`,
      );

      this.subscriptions.set(
        id,
        producer(payload, (result) => {
          this.sendMessage({
            id,
            result,
            type: MessageType.SUBSCRIPTION_RESULT,
          });
        }),
      );
    }
  }

  private handleRemoveSubscription({id}: RemoveSubscriptionMessage) {
    const subscription = this.subscriptions.get(id);
    if (subscription) {
      this.log(`< RCV ${MessageType.REMOVE_SUBSCRIPTION}, ${id}`);

      subscription.unsubscribe();
      this.subscriptions.delete(id);
    }
  }

  private log(...args: [string | Error, ...any]) {
    if (this.enableLogging) {
      // eslint-disable-next-line no-console
      console.log(...args);
    }
  }
}
