/**
 * This method generates unique ids. Copied from analytics.js package.
 *
 * @method generateId
 * @private
 *
 * @return {String}
 * It returns a string in the form `"xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"`
 */
export default function generateId(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    /* eslint-disable */
    let r = (Math.random() * 16) | 0;
    let v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
    /* eslint-enable */
  });
}
