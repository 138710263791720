import type {ReactElement, ReactNode} from 'react';
import type {PrimitiveType} from 'src/public/formatMessage/types';
import type {
  FormatXMLElementFn,
  MessageDescriptor,
  MessageDescriptorBase,
} from 'src/public/message/types';

/**
 * The message function is used for defining messages in JavaScript source files.
 *
 * These messages, and their translations, can be later formatted with [`formatMessage`](/apis/intl/useIntl/#format-message).
 *
 * Unless you explicitly set `translate: false` in the message definition,
 * messages defined with the `message` function will be extracted and sent
 * to our translation provider.
 *
 * See [Localized messages](/apis/intl/localized-messages) for more details.
 *
 * @example Basic {{include "./examples/message.basic.tsx"}}
 * @example Message syntax {{include "./examples/message.syntax.tsx"}}
 * @example Rich text formatting {{include "./examples/message.rich-text.tsx"}}
 * @example Untranslated message {{include "./examples/message.untranslated.tsx"}}
 *
 * @see https://sail.stripe.me/apis/intl/message
 */
export default function message<
  /** Generic type for the values to be passed-in to the message */
  TValues extends Record<
    string,
    PrimitiveType | ReactElement | ReactNode | FormatXMLElementFn
  > = never,
>(
  /** The [message descriptor](/apis/intl/localized-messages#message-descriptor). */
  descriptor: MessageDescriptorBase,
): MessageDescriptor<TValues> {
  return descriptor as MessageDescriptor<TValues>;
}
