/* eslint-disable @stripe-internal/embedded/no-restricted-globals */
import type {FetchApiError} from '@stripe-internal/connect-embedded-lib';
import {setup, getDefaultConnector} from './connect/connector';
import {markConnectTiming} from './utils/telemetry/performanceMetrics';
import {livemodeFromPublishableKey} from './utils/livemodeFromPublishableKey';
import type {
  InitOptions,
  UpdateOptions,
} from './connect/ConnectJSInterface/InitAndUpdateOptionsTypes';
import {validateInitOptions} from './connect/ConnectJSInterface/validateOptions';
import {withErrorReportingAndAnalytics} from './data-layer-client/ErrorReportingAndAnalytics';
import {transformInitOptions} from './connect/ConnectJSInterface/transformOptions';
import {preloadAccessoryLayerFrame} from './ui-layer-frame/accessory-layer-frame/preloadAccessoryLayerFrame';
import {requestIdleCallbackPonyfill} from './vendor/requestIdleCallback';
import {createApiPreconnectLink} from './utils/createApiPreconnectLink';
import {preloadDataLayer} from './data-layer-client/buildDataLayerFrame';

declare global {
  interface Window {
    StripeConnect:
      | undefined
      | {
          onLoad?: () => void;
          init?: (options?: InitOptions) => void;
          update?: (options?: UpdateOptions) => void;
          optimizedLoading?: boolean;
          COMMIT_HASH?: string;
        };
  }
}

function setupStripeConnect() {
  const link = createApiPreconnectLink(document);
  document.head.appendChild(link);

  // Setup the StripeConnect global
  if (window.StripeConnect == null) {
    window.StripeConnect = {};
    window.StripeConnect.COMMIT_HASH = process.env.COMMIT_HASH;
  }

  // Don't define Stripe connect if it was already defined
  if (window.StripeConnect.init) {
    return;
  }

  // Note: we don't do this with requestIdleCallbackPonyfill, since we want the data layer to be loaded ASAP
  if (document.body) {
    preloadDataLayer();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      preloadDataLayer();
    });
  }

  // Preload the accessory layer frame
  requestIdleCallbackPonyfill(preloadAccessoryLayerFrame);

  Object.defineProperty(window.StripeConnect, 'init', {
    value: (options: unknown) => {
      markConnectTiming('initStart');

      const transformedOptions = transformInitOptions(options);
      validateInitOptions(
        transformedOptions,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        livemodeFromPublishableKey((options as any)?.publishableKey, true),
        false,
      );

      const {
        clientSecret,
        publishableKey,
        appearance,
        metaOptions,
        locale,
        refreshClientSecret,
        fetchClientSecret,
        fonts,
      } = transformedOptions;

      return setup(
        publishableKey,
        clientSecret,
        fetchClientSecret,
        metaOptions,
        appearance,
        locale,
        refreshClientSecret,
        fonts,
      );
    },
  });

  Object.defineProperty(window.StripeConnect, 'update', {
    value: (options: unknown) => {
      const connector = getDefaultConnector();
      connector.update(transformInitOptions(options));
    },
  });

  Object.defineProperty(window.StripeConnect, 'logout', {
    value: async () => {
      const connector = getDefaultConnector();
      try {
        await withErrorReportingAndAnalytics(
          connector.reports,
          connector.connectJsOptions.getValues().metaOptions,
          connector.analytics,
          'submerchant_surfaces_logout',
          connector.frameMessenger.logout,
          false,
          undefined,
          undefined,
          undefined,
          ['invalid_session', 'reauth_required'],
        );
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const status = (error as FetchApiError).error.httpStatus;
        if (status !== 401 && status !== 404) {
          throw new Error('login unsuccessful');
        }
      }
    },
  });

  markConnectTiming('connectGlobalReady');
  // If `onLoad` is defined, call it. If it's not defined yet, call it if it gets set later.
  if (
    window.StripeConnect.onLoad &&
    typeof window.StripeConnect.onLoad === 'function'
  ) {
    window.StripeConnect.onLoad();
  } else {
    Object.defineProperty(window.StripeConnect, 'onLoad', {
      set(onLoad: () => void) {
        if (typeof onLoad === 'function') {
          onLoad();
        }
      },
    });
  }
}

setupStripeConnect();
