import type {
  HostApp,
  MetaOptions,
} from '../connect/ConnectJSInterface/InitAndUpdateOptionsTypes';
import {defaultHostApp} from '../connect/ConnectJSInterface/InitAndUpdateOptionsTypes';

export const getHostApp = (metaOptions: MetaOptions | undefined): HostApp => {
  return metaOptions?.hostApp || defaultHostApp;
};

export const hostedOnboardingHostApps: HostApp[] = [
  'hostedOnboarding', // remove this once this has rolled out
  'hostedOnboarding_custom',
  'hostedOnboarding_standard',
  'hostedOnboarding_standardOAuth',
  'hostedOnboarding_express',
  'hostedOnboarding_expressOAuth',
  'hostedOnboarding_durable',
];

export const durableFlowHostApps: HostApp[] = [
  'durableFlow_embeddedTaxDocuments',
];

export const isHostedOnboardingHostApp = (hostApp: string | undefined) =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  hostApp && hostedOnboardingHostApps.includes(hostApp as HostApp);

export const isDurableFlowHostApp = (hostApp: string | undefined) =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  hostApp && durableFlowHostApps.includes(hostApp as HostApp);
