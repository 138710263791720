import type {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';

export const financialAccountParameter = 'financial-account' as const;
const financialAccountParams = [financialAccountParameter] as const;

type FinancialAccountParams = (typeof financialAccountParams)[number];
export type FinancialAccountHtmlParams = Partial<
  Record<FinancialAccountParams, string>
>;

export const FinancialAccountConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "treasuryFinancialAccount" */
      '../../../components/Treasury/FinancialAccount'
    ),
  enabledKey: 'financial_account',
  htmlAttributes: {
    [financialAccountParameter]: {
      isInternal: false,
    },
  },
  customHtmlElementMethods: {
    setFinancialAccount: {
      type: 'AttributeSettingMethod',
      attribute: financialAccountParameter,
      isInternal: false,
      description:
        'Sets the financial account id to display balance and money movement options for.',
    },
  },
  releaseState: 'preview',
  teamOwner: 'ef_surfaces',
  description:
    'Displays balance and money movement options for the given financial account.',
  docSitePath: '/connect/supported-embedded-components/financial-account',
};
