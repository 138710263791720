import Counter from 'src/internal/platformMetrics/aggregators/Counter';

export const safeParams = [
  'dashboard_page_templates_list',
  'dashboard_page_templates_detail',
] as const;

type SafeParams = (typeof safeParams)[number];

export default new Counter<SafeParams>(
  'frontend.platform-ui.dashboard-page-templates',
);
