export abstract class AbstractTracker<
  TData extends Record<string, unknown> = Record<string, unknown>,
> {
  protected data: TData = Object.create(null);

  constructor(private name: string) {
    this.name = name;
    this.track = this.track.bind(this);
  }

  get eventName() {
    return this.name;
  }

  get eventData() {
    return this.data;
  }

  abstract track(...args: any[]): void;

  drop(): void {
    this.data = Object.create(null);
  }
}
