export class ConnectElementRenderedState {
  private observers: ((value: boolean) => void)[];

  public isRendered: boolean;

  constructor() {
    this.observers = [];
    this.isRendered = true;
  }

  public update(newValue: boolean) {
    this.isRendered = newValue;
    // eslint-disable-next-line no-restricted-syntax
    for (const observer of this.observers) {
      observer(this.isRendered);
    }
  }

  public registerObserver(observer: (isRendered: boolean) => void) {
    this.observers.push(observer);
  }

  public unregisterObserver(observer: () => void) {
    this.observers = this.observers.filter((o) => o !== observer);
  }
}
