import {ConnectElementObservableValues} from './ConnectElementObservableValues';

export type FetchEphemeralKeyResponse = {
  issuingCard: string;
  nonce: string;
  ephemeralKeySecret: string;
};

export interface IConnectElementSupplementalFunction {
  // All items here should be functions that return promises and take in a single parameter

  // Internal
  fetchAdditionalPaymentPropsInternalDoNotUse?: (params: {
    paymentId: string;
  }) => Promise<string>;
  fetchAdditionalPaymentPropsInternalDoNotUse2?: (
    paymentId: string,
  ) => Promise<string>;

  // API reviewed
  // Preview
  fetchEphemeralKey?: (fetchParams: {
    issuingCard: string;
    nonce: string;
  }) => Promise<FetchEphemeralKeyResponse>;

  // GA
}

export type SupplementalFunctionKey = keyof IConnectElementSupplementalFunction;

export class ConnectElementSupplementalFunctions extends ConnectElementObservableValues<IConnectElementSupplementalFunction> {}
