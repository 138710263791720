import {AbstractTracker} from 'src/internal/platformMetrics/aggregators/AbstractTracker';

export default class Counter<TParams extends string> extends AbstractTracker<
  Record<TParams, number>
> {
  track(id: TParams): void {
    this.data[id] ??= 0;
    this.data[id]++;
  }
}
