/* eslint-disable @stripe-internal/embedded/no-restricted-globals */
import {isStripeDomain} from '@stripe-internal/connect-embedded-lib';
import {
  composeValidations,
  ignoreNullish,
  isRecordStringUnknown,
  validateAppearanceOptions,
  validateArray,
  validateBoolean,
  validateFontFamily,
  validateFontWeight,
  validateFunction,
  validateNotNullish,
  validateObject,
  validateString,
  validateStringBooleanRecord,
  validateStringContents,
  validateStringStringRecord,
} from '@stripe-internal/embedded-theming';
import {getTestmodeLogger} from '../../utils/getLogger';
import {isDevEnvironment} from '../../utils/isDevEnvironment';
import type {
  UpdateOptions,
  InitOptions,
  IFeatureOverrides,
} from './InitAndUpdateOptionsTypes';
import {
  validateFontDisplay,
  validateFontStyle,
  validateFontUnicodeRange,
} from '../fonts/validateFonts';
import {FLAGS} from '../../utils/flags/flags';
import {
  isDurableFlowHostApp,
  isHostedOnboardingHostApp,
} from '../../utils/getHostApp';

export type Validator = (value: unknown, path: string) => void;

export const getValidationLogger = (livemode: boolean) =>
  getTestmodeLogger(!livemode);

const validateFeatureOverrides = (
  value: unknown,
  path: string,
): asserts value is IFeatureOverrides => {
  if (!value || typeof value !== 'object') {
    throw new Error(`${path} must be an object`);
  }

  Object.entries(value).forEach((entry) => {
    validateStringBooleanRecord(entry[1], `${path}.${entry[0]}`);
  });
};

const validatePromise = (
  value: unknown,
  path: string,
): asserts value is Promise<unknown> => {
  if (!(value instanceof Promise)) {
    throw new Error(`${path} is expected to be a promise`);
  }
};

export const validateIsInInternalDomain =
  (validator: Validator) => (value: unknown, path: string) => {
    const url = new URL(window.location.href);
    if (isDevEnvironment || isStripeDomain(url)) {
      return validator(value, path);
    } else {
      throw new Error(`${path} not permitted for host: '${url.host}'`);
    }
  };

export const validateIsInInternalDomainOrContainsExternalDomainOverrideFlag =
  (validator: Validator) => (value: unknown, path: string) => {
    const url = new URL(window.location.href);
    const containsExternalDomainFlag =
      isRecordStringUnknown(value) &&
      value[FLAGS.STRIPE_INTERNAL_OVERRIDE_FLAGS_IN_EXTERNAL_DOMAINS] === true;

    if (isDevEnvironment || isStripeDomain(url) || containsExternalDomainFlag) {
      return validator(value, path);
    } else {
      throw new Error(`${path} not permitted for host: '${url.host}'`);
    }
  };

export const validateLocale = ignoreNullish(validateString);

type UpdateOptionsAssert = (
  value: unknown,
  livemode: boolean,
) => asserts value is UpdateOptions;
export const validateUpdateOptions: UpdateOptionsAssert = (
  value: unknown,
  livemode: boolean,
): asserts value is UpdateOptions => {
  if (typeof value !== 'object') {
    throw new Error('The options passed to `update()` must be an object');
  }

  const logWarning = getTestmodeLogger(!livemode).warn;

  validateObject(logWarning, {
    appearance: validateAppearanceOptions(logWarning),
    locale: validateLocale,
  })(value, 'UpdateOptions');
};

export const validateInitOptions: (
  value: unknown,
  livemode: boolean,
  allowPartial: boolean,
) => asserts value is InitOptions = (
  value,
  livemode,
  allowPartial,
): asserts value is InitOptions => {
  if (!isRecordStringUnknown(value)) {
    throw new Error('The options passed to `init()` must be an object');
  }

  const logWarning = getTestmodeLogger(!livemode).warn;

  // This describes the shape of InitOptions
  validateObject(logWarning, {
    publishableKey: composeValidations(
      validateNotNullish(
        'No publishable key was provided. You can get this from your Stripe Dashboard at https://dashboard.stripe.com/apikeys.',
      ),
      validateStringContents((value) => {
        if (!value.startsWith('pk_')) {
          throw new Error(
            `The publishable key that was provided (${value}) doesn't look like a publishable key. It should start with pk_test_ or pk_live_.`,
          );
        }
      }),
    ),
    // We will either have a client secret or a fetchClientSecret function
    clientSecret: ignoreNullish(
      validateStringContents((_value) => {
        if (!value) {
          throw new Error(
            'No client secret was provided. This is returned from the Account Sessions API.',
          );
        }

        // In the future, we'll have a prefix for client secrets and maybe a checksum
        // NOTE: when doing this, keep in mind express/login as don't specify a valid client secret. We don't want to break those integrations
        //   if (!value.startsWith('pk_')) {
        //     throw new Error(
        //       `The publishable key that was provided (${value}) doesn't look like a publishable key. It should start with pk_test_ or pk_live_.`,
        //     );
        //   }
      }),
    ),
    fetchClientSecret: ignoreNullish(validateFunction),
    appearance: validateAppearanceOptions(logWarning),
    fonts: ignoreNullish(
      validateArray(logWarning, (value: unknown, path: string) => {
        if (
          typeof value !== 'object' ||
          !value ||
          Object.keys(value).length === 0
        ) {
          throw new Error(
            `${path} must be either a CSSFontSource or CustomFontSource`,
          );
        }

        if (value.hasOwnProperty('cssSrc')) {
          validateObject(
            logWarning,
            {
              cssSrc: composeValidations(
                validateNotNullish(
                  'A cssSrc must be provided for a CSS font source',
                ),
                validateString,
              ),
            },
            undefined,
            true,
          )(value, path);
        } else {
          validateObject(
            logWarning,
            {
              src: composeValidations(
                validateNotNullish(
                  'A src must be provided for a Custom font source',
                ),
                validateString,
              ),
              family: composeValidations(
                validateNotNullish(
                  'A font family must be provided for a Custom font source',
                ),
                validateFontFamily,
              ),
              display: ignoreNullish(validateFontDisplay),
              style: ignoreNullish(validateFontStyle),
              unicodeRange: ignoreNullish(validateFontUnicodeRange),
              weight: ignoreNullish(validateFontWeight),
            },
            undefined,
            true,
          )(value, path);
        }
      }),
    ),
    metaOptions: ignoreNullish(
      validateObject(
        logWarning,
        {
          // These metaOptions are internal only
          releaseCandidate: ignoreNullish(
            validateIsInInternalDomain(
              composeValidations(
                validateStringContents((releaseCandidate) => {
                  if (/^\w+$/.test(releaseCandidate)) {
                    return true;
                  } else {
                    throw new Error(
                      `releaseCandidate must be alphanumeric. '${releaseCandidate}' provided`,
                    );
                  }
                }),
              ),
            ),
          ),
          disableAnalytics: ignoreNullish(
            validateIsInInternalDomain(validateBoolean),
          ),
          apiKeyOverride: ignoreNullish(
            validateIsInInternalDomain(validateString),
          ),
          merchantIdOverride: ignoreNullish(
            validateIsInInternalDomain(validateString),
          ),
          platformIdOverride: ignoreNullish(
            validateIsInInternalDomain(validateString),
          ),
          livemodeOverride: ignoreNullish(
            validateIsInInternalDomain(validateBoolean),
          ),
          flagOverrides: ignoreNullish(
            validateIsInInternalDomainOrContainsExternalDomainOverrideFlag(
              validateStringBooleanRecord,
            ),
          ),
          featureOverrides: ignoreNullish(
            validateIsInInternalDomain(validateFeatureOverrides),
          ),
          supportSiteBaseOverride: ignoreNullish(
            validateIsInInternalDomain(
              validateStringContents((value) => {
                const isValidOverride = value === 'express';

                if (!isValidOverride) {
                  throw new Error(
                    `The supportSiteBaseOverride value provided (${value}) must be 'express'.`,
                  );
                }
              }),
            ),
          ),
          hostApp: ignoreNullish(
            validateIsInInternalDomain(
              validateStringContents((value) => {
                const isValidHostApp =
                  isHostedOnboardingHostApp(value) ||
                  isDurableFlowHostApp(value) ||
                  value === 'express' ||
                  value === 'standard' ||
                  value === 'direct_settings' ||
                  value === 'platform' ||
                  value === 'platform_android' ||
                  value === 'platform_ios' ||
                  value === 'loginAs' ||
                  value === 'docs';

                if (!isValidHostApp) {
                  throw new Error(
                    `The hostApp value provided (${value}) must either be 'express', 'standard', 'platform', 'docs', 'loginAs', or a hosted onboarding variant.`,
                  );
                }
              }),
            ),
          ),
          isV2Session: ignoreNullish(
            validateIsInInternalDomain(validateBoolean),
          ),
          v2WorkspaceIdOverride: ignoreNullish(
            validateIsInInternalDomain(validateString),
          ),
          // used supplementally with fetchClientSecret, passed in frontend SDK
          eagerClientSecretPromise: ignoreNullish(validatePromise),

          // These options are not internal only
          sdk: ignoreNullish(validateBoolean),
          sdkOptions: ignoreNullish(validateStringStringRecord),

          // This restricts access to custom accounts only for the iOS SDK preview
          restrictToPlatformOwnsOnboarding: ignoreNullish(validateBoolean),
        },
        true,
      ),
    ),
    locale: validateLocale,
    refreshClientSecret: ignoreNullish(validateFunction),
  })(value, 'InitOptions');

  // If configured to allow partial init values (this happens only for init options override), validation stops here
  if (allowPartial) {
    return;
  }

  // Validate values that we'll be able to perform auth. This can happen either via publishable key + client secret,
  // or an API key override

  const hasClientSecret =
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    !!(value as InitOptions).clientSecret ||
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    !!(value as InitOptions).fetchClientSecret;

  const hasPublishableKeyAndClientSecret =
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    !!(value as InitOptions).publishableKey && hasClientSecret;

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const hasApiKeyOverride = !!(value as InitOptions)?.metaOptions
    ?.apiKeyOverride;

  if (!hasClientSecret && !hasApiKeyOverride) {
    throw new Error(
      'To initialize Connect embedded components, you must provide either a client secret or a function to fetch the client secret.  Please refer to our documentation for more information: https://stripe.com/docs/connect/get-started-connect-embedded-components',
    );
  }

  if (!hasPublishableKeyAndClientSecret && !hasApiKeyOverride) {
    throw new Error(
      'To initialize Connect embedded components, you must provide a valid publishable key. Please refer to our documentation for more information: https://stripe.com/docs/connect/get-started-connect-embedded-components',
    );
  }
};
