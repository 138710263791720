/**
 * Referenced code from https://git.corp.stripe.com/stripe-internal/stripe-js-v3/blob/c5ac1ad7/src/lib/outer/loadFont.ts#L1
 * This file contains the functions that are used to load fonts given a URL.
 *
 * This is done via the following:
 * - Fetch the fonts via `window.fetch` on the platform's frame.
 * - `findFontFaceRules` extracts all the relevant @font-face rules
 * - `_extractFontFaceDefinition` extracts the individual rules in each @font-face definition
 * - `_splitDeclarations` parses each @font-face definition into an array of strings in the format `font-property: value`
 * - `_parseDeclarations` will convert each `font-property: value` into a ILoadedFont object
 *
 * Original PR: https://git.corp.stripe.com/stripe-internal/stripe-js-v3/pull/451
 * */

import {
  CSS_TO_JS_FONT_PROPERTY_MAPPINGS,
  JS_TO_CSS_FONT_PROPERTY_MAPPINGS,
} from './constants';

// Get an array of all @font-face rules.
const findFontFaceRules = (css: string, url: string): Array<string> => {
  const fontFaceRegExp = /@font-face[ ]?{[^}]*}/g;
  const fontFaceRules = css.match(fontFaceRegExp);
  if (!fontFaceRules) {
    throw new Error(`No @font-face rules found in file from ${url}`);
  }
  return fontFaceRules;
};

// Return the contents of a @font-face definition.
const _extractFontFaceDefinition = (fontFaceRule: string): string => {
  const res = fontFaceRule.match(/@font-face[ ]?{([^}]*)}/);
  return res ? res[1] : '';
};

// Get an array of all CSS declarations.
const _splitDeclarations = (
  fontFaceRuleContent: string,
  url: string,
): Array<string> => {
  // Remove inline CSS comments and trailing whitespace.
  const contentWithoutComments = fontFaceRuleContent
    .replace(/\/\*.*\*\//g, '')
    .trim();
  // It is allowed to omit the last semicolon of the last rule
  // in a block. If that is the case we add it.
  const contentWithTrailingSemi =
    contentWithoutComments.length && /;$/.test(contentWithoutComments)
      ? contentWithoutComments
      : `${contentWithoutComments};`;
  const declarations = contentWithTrailingSemi
    // Split on ;. This regex allows us to ignore `;`s that appear within complete parentheses.
    .match(/((([^;(]*\([^()]*\)[^;)]*)|[^;]+)+)(?=;)/g);

  // Throw if the font-face-block is empty.
  if (!declarations) {
    throw new Error(
      `Found @font-face rule containing no valid font-properties in file from ${url}`,
    );
  }
  return declarations;
};

// Parse CSS-declaration string 'css-property: value' to a {property, value} object.
const _parseCssDeclaration = (
  declaration: string,
  url: string,
): {
  property: string;
  value: string;
} => {
  // Find the first ':'
  const i = declaration.indexOf(':');
  if (i === -1) {
    throw new Error(
      `Invalid css declaration in file from ${url}: "${declaration}"`,
    );
  }
  // The part before the ':' is the property
  const cssProperty = declaration.slice(0, i).trim();
  // Map the CSS property to our camelCased JavaScript equivalent.
  const property = CSS_TO_JS_FONT_PROPERTY_MAPPINGS[cssProperty];
  if (!property) {
    throw new Error(
      `Unsupported css property in file from ${url}: "${cssProperty}"`,
    );
  }
  // The part after the ':' is the value
  const value = declaration.slice(i + 1).trim();
  return {
    property,
    value,
  };
};

// Convert the array of `font-property: value`-declarations to a Font object.
const _parseDeclarations = (declarations: Array<string>, url: string) => {
  const font = declarations.reduce<Record<string, any>>(
    (
      result: Record<string, string>,
      declaration: string,
    ): Record<string, string> => {
      const {property, value} = _parseCssDeclaration(declaration, url);
      return {
        ...result,
        [property]: value,
      };
    },
    {},
  );

  ['family', 'src'].forEach((prop) => {
    if (!font[prop]) {
      throw new Error(
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly family: "font-family"; readonly src: "src"; readonly unicodeRange: "unicode-range"; readonly style: "font-style"; readonly variant: "font-variant"; readonly stretch: "font-stretch"; readonly weight: "font-weight"; readonly display: "font-display"; }'.
        `Missing css property in file from ${url}: "${JS_TO_CSS_FONT_PROPERTY_MAPPINGS[prop]}"`,
      );
    }
  });

  return font;
};

export const loadFont = async (url: string) => {
  // eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals
  const fontResponse = await window.fetch(url, {method: 'GET'});
  const cssText = await fontResponse.text();
  const fontFaceRules = findFontFaceRules(cssText, url);
  const fonts = fontFaceRules.map((rule) => {
    const fontFaceRuleContent = _extractFontFaceDefinition(rule);
    const declarations = _splitDeclarations(fontFaceRuleContent, url);
    const font = _parseDeclarations(declarations, url);
    return font;
  });
  return fonts;
};
