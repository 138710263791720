import Counter from 'src/internal/platformMetrics/aggregators/Counter';

export const safeParams = [
  // @sail/access
  'sail_access_useExperiment',
  'sail_access_useExperiments',
  'sail_access_useLazyExperiment',

  'sail_access_useDevEnabledFlag',
  'sail_access_useFlag',
  'sail_access_useFlags',
  'sail_access_useLazyFlag',

  // @sail/data
  'sail_data_useCookie',
  'sail_data_useJsonFetch',
  'sail_data_useTextFetch',
  'sail_data_useImperativeJsonFetch',
  'sail_data_useImperativeTextFetch',
  'sail_data_useLazyJsonFetch',
  'sail_data_useLazyTextFetch',

  'sail_data_useLazyQuery',
  'sail_data_useMutation',
  'sail_data_useQuery',

  'sail_data_useLocalStorage',
  'sail_data_useSessionStorage',

  // @sail/stripelet
  'sail_stripelet_Stripelet',
] as const;

type SafeParams = (typeof safeParams)[number];

export default new Counter<SafeParams>('frontend.platform-ui.usage-by-mount');
