export class ConnectElementObservableValues<V> {
  private observers: ((values: V) => void)[];

  constructor(public values: V) {
    this.observers = [];
  }

  public updateValues(newValues: V) {
    this.values = newValues;
    // eslint-disable-next-line no-restricted-syntax
    for (const observer of this.observers) {
      observer(this.values);
    }
  }

  public registerObserver(observer: (values: V) => void) {
    this.observers.push(observer);
  }

  public unregisterObserver(observer: () => void) {
    this.observers = this.observers.filter((o) => o !== observer);
  }
}
