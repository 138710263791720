import * as analytics from 'src/internal/analytics/analytics.client';

import type {
  AnalyticsEventName,
  AnalyticsParams,
  IAnalytics,
} from 'src/internal/analytics/types';
import type {ObservabilityConfig} from 'src/types';

export default class Analytics implements IAnalytics {
  #config: ObservabilityConfig;

  constructor(config: ObservabilityConfig) {
    this.#config = config;
  }

  action<T extends string>(
    eventName: AnalyticsEventName<T>,
    props?: AnalyticsParams<T>,
  ) {
    analytics.action(this.#config, eventName, props);
  }

  link<T extends string>(
    eventName: AnalyticsEventName<T>,
    props?: AnalyticsParams<T>,
  ) {
    analytics.link(this.#config, eventName, props);
  }

  modal<T extends string>(
    eventName: AnalyticsEventName<T>,
    props?: AnalyticsParams<T>,
  ) {
    analytics.modal(this.#config, eventName, props);
  }

  track<T extends string>(
    eventName: AnalyticsEventName<T>,
    props?: AnalyticsParams<T>,
  ) {
    analytics.track(this.#config, eventName, props);
  }

  viewed<T extends string>(
    eventName: AnalyticsEventName<T>,
    props?: AnalyticsParams<T>,
  ) {
    analytics.viewed(this.#config, eventName, props);
  }
}
