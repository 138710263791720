import type {Encoder} from 'src/internal/encoder/types';
import type {Transport} from 'src/internal/transport/types';

export type RequestId = string;

export type SubscriptionId = string;

export type SubscriptionName = string;

export interface Subscription {
  unsubscribe(): void;
}

export type SubscriptionCallback<T> = (result: T) => void;

export type SubscriptionProducer<P, R> = (
  payload: P,
  callback: SubscriptionCallback<R>,
) => Subscription;

export type MethodName = string;

export type MethodArgs<T = any[]> = T extends any[] ? T : T[];

export type MethodHandler<Args, Result> = (
  ...args: MethodArgs<Args>
) => Result | Promise<Result>;

export type RPCMethods = Record<
  MethodName,
  {
    args: MethodArgs;
    result: any;
  }
>;

export type RPCSubscriptions = Record<
  SubscriptionName,
  {
    payload: any;
    result: any;
  }
>;

export type ChannelConfig<
  Methods extends RPCMethods,
  Subscriptions extends RPCSubscriptions,
> = {
  name: string;
  transport: Transport;
  encoder?: Encoder;
  enableLogging?: boolean;
  methods?: {
    [M in keyof Methods]: MethodHandler<
      Methods[M]['args'],
      Methods[M]['result']
    >;
  };
  subscriptions?: {
    [S in keyof Subscriptions]: SubscriptionProducer<
      Subscriptions[S]['payload'],
      Subscriptions[S]['result']
    >;
  };
};

export enum MessageType {
  CALL = 'CALL',
  CALL_ERROR = 'CALL_ERROR',
  CALL_RESPONSE = 'CALL_RESPONSE',
  ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION',
  REMOVE_SUBSCRIPTION = 'REMOVE_SUBSCRIPTION',
  SUBSCRIPTION_RESULT = 'SUBSCRIPTION_RESULT',
}

export type CallMessage = {
  id: RequestId;
  type: MessageType.CALL;
  method: MethodName;
  args: MethodArgs;
};

export type CallResponseMessage = {
  type: MessageType.CALL_RESPONSE;
  id: RequestId;
  result: any;
};

export type CallErrorMessage = {
  type: MessageType.CALL_ERROR;
  id: RequestId;
  error: Error;
};

export type AddSubscriptionMessage = {
  type: MessageType.ADD_SUBSCRIPTION;
  id: SubscriptionId;
  name: string;
  payload: any;
};

export type RemoveSubscriptionMessage = {
  type: MessageType.REMOVE_SUBSCRIPTION;
  id: SubscriptionId;
};

export type SubscriptionResultMessage = {
  type: MessageType.SUBSCRIPTION_RESULT;
  id: SubscriptionId;
  result: any;
};

export type RPCMessage =
  | CallMessage
  | CallResponseMessage
  | CallErrorMessage
  | AddSubscriptionMessage
  | RemoveSubscriptionMessage
  | SubscriptionResultMessage;
