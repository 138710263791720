export const isStripeDomain = (windowUrl: URL): boolean => {
  const hostname = windowUrl.hostname.toLowerCase();
  return (
    hostname.endsWith('.furever.dev') ||
    hostname.endsWith('stripe-connect-furever.onrender.com') ||
    hostname.endsWith('stripe-connect-test.onrender.com') ||
    hostname.endsWith('stripe-connect-furever-ops-test.onrender.com') ||
    hostname.endsWith('issuing-embedded-components-app.vercel.app') ||
    hostname.endsWith('property-management-tau.vercel.app') ||
    hostname.endsWith('embedded-finance-app.vercel.app') ||
    hostname.endsWith('gym-management-mvhg.vercel.app') ||
    hostname.endsWith('coffee-shop-management-liard.vercel.app') ||
    hostname.endsWith('connecttest.onrender.com') ||
    hostname.endsWith('.stripedemos.com') ||
    hostname.endsWith('.stripe.com') ||
    hostname.endsWith('.stripe.me') ||
    hostname === 'localhost' ||
    hostname === 'stripe.com' ||
    hostname === '127.0.0.1' ||
    // App Platform demo site for Connect App Distribution
    hostname.endsWith('hamster-dance.onrender.com')
  );
};
