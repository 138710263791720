import type {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';
import {ComponentOverrideFlag} from '../../../utils/flags/flags';

export const chargeIdPaymentDetailsParameter = 'charge-id' as const;
export const visiblePaymentDetailsParameter = 'visible' as const;
export const inlinePaymentDetailsParameter = 'inline' as const;
export const paymentPaymentDetailsParameter = 'payment' as const;

const paymentDetailsParams = [
  chargeIdPaymentDetailsParameter,
  visiblePaymentDetailsParameter,
  inlinePaymentDetailsParameter,
  paymentPaymentDetailsParameter,
] as const;
type PaymentDetailsParams = (typeof paymentDetailsParams)[number];
export type PaymentDetailsHtmlParams = Partial<
  Record<PaymentDetailsParams, string>
>;

export const PaymentDetailsConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "paymentDetails" */ '../../../components/Payments/PaymentDetails'
    ),
  htmlAttributes: {
    [chargeIdPaymentDetailsParameter]: {
      isInternal: false,
    },
    [visiblePaymentDetailsParameter]: {
      isInternal: false,
    },
    [paymentPaymentDetailsParameter]: {
      isInternal: false,
    },

    // Internal - to enable these for platforms, we'll need API review
    [inlinePaymentDetailsParameter]: {
      isInternal: true,
    },
  },
  htmlEvents: ['close'],
  customHtmlElementMethods: {
    setPayment: {
      type: 'AttributeSettingMethod',
      attribute: paymentPaymentDetailsParameter,
      isInternal: false,
      description: 'Sets the payment id to display details for.',
    },
    setOnClose: {
      type: 'EventListenerMethod',
      event: 'close',
      isInternal: false,
      description:
        'Sets the event listener for when the payment details overlay is closed',
    },
  },
  enabledKey: 'payment_details',
  enabledOverrideFlag: ComponentOverrideFlag.payment_details,
  releaseState: 'ga',
  teamOwner: 'accounts_ui',
  optionalInlineOverlay: true,
  description:
    'Shows details for a given payment. Offers refund and dispute management.',
  docSitePath: '/connect/supported-embedded-components/payment-details',
  syntheticTestInfo: {
    testUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/sus-pay-detail-iframe',
    rcTestUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/paydetail-iframe-rc',
  },
};
