import {getStripeOrGraphQLErrorMetadata} from '@stripe-internal/connect-embedded-lib';

// iframes do not call onerror when they fail to load, so we need a timeout to reject the deferredFrame promise if loading fails
// This timeout is based on the p99 init to frame ready duration of Embedded Components: https://hubble.corp.stripe.com/viz/dashboards/37870--connect-embedded-components-performance?tab=component-agnostic+metrics
// 10s gives us >99% coverage of p99 loading times (as of 07/18/2024), so if the message channel has failed to initialize within that time, we can assume the data layer frame failed to load
export const MESSAGE_CHANNEL_TIMEOUT = 10000;
export const UI_LAYER_MESSAGE_CHANNEL_TIMEOUT = MESSAGE_CHANNEL_TIMEOUT + 2000;

// This timeout is based on the p99 first mount duration for all Embedded Components: https://hubble.corp.stripe.com/viz/dashboards/37870--connect-embedded-components-performance?tab=component-aware+metrics
// 20s gives us >99% coverage of p99 first mount times (as of 10/03/2024), so if the component has failed to render within that time, we can assume the UI layer failed to render
export const UI_LAYER_RENDER_TIMEOUT = 20000;

export type EmbeddedError = {
  type: EmbeddedErrorType; // The type of error
  message?: string; // Further description or detail for the error
};

export type EmbeddedErrorType =
  /**
   * Failure to connect to Stripe's API.
   */
  | 'api_connection_error'
  /**
   * Failure to perform the authentication flow within Connect Embedded Components
   */
  | 'authentication_error'
  /**
   * Account session create failed
   */
  | 'account_session_create_error'
  /**
   * Request failed with an 4xx status code, typically caused by platform configuration issues
   */
  | 'invalid_request_error'
  /**
   * Too many requests hit the API too quickly.
   */
  | 'rate_limit_error'
  /**
   * API errors covering any other type of problem (e.g., a temporary problem with Stripe's servers), and are extremely uncommon.
   */
  | 'api_error'
  /**
   * React was unable to render the component
   */
  | 'render_error';

export const mapAuthErrorToEmbeddedError = (error: unknown): EmbeddedError => {
  // If a properly formed error cause is passed in, use that as the EmbeddedError
  if (
    error instanceof Error &&
    error.cause &&
    typeof error.cause === 'object' &&
    'type' in error.cause &&
    'message' in error.cause
  ) {
    return {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      type: error.cause.type as EmbeddedErrorType,
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      message: error.cause.message as string | undefined,
    };
  }

  const e = getStripeOrGraphQLErrorMetadata(error);

  if (e.stripeErrorCode === 'Failed to fetch') {
    return {type: 'api_connection_error'};
  }

  if (e.stripeStatusCodeRange === '4xx') {
    return {type: 'invalid_request_error', message: e.stripeErrorMessage};
  }

  return {type: 'authentication_error', message: e.stripeErrorMessage};
};

export function isCustomEvent(event: Event): event is CustomEvent {
  return 'detail' in event;
}
