import {isTraversable} from 'src/internal/encoder/utils';

import type {Matcher} from 'src/internal/encoder/types';

export default function traverse(data: any, matchers: Matcher[] = []): any {
  return traverseInternal(data, matchers);
}

function traverseInternal(
  value: unknown,
  matchers: Matcher[] = [],
  visited = new Map<any, any>(),
): any {
  if (visited.has(value)) {
    return visited.get(value);
  }

  visited.set(value, undefined);

  const continueTraverse = (value: any) =>
    traverseInternal(value, matchers, visited);

  const [matched, result] = runMatchers(matchers, value, continueTraverse);

  if (matched) {
    visited.set(value, result);
    return result;
  }

  if (isTraversable(value)) {
    const result = Array.isArray(value)
      ? value.map(continueTraverse)
      : Object.keys(value).reduce(
          (obj, key) => ({
            ...obj,
            [key]: continueTraverse((value as any)[key]),
          }),
          {},
        );

    visited.set(value, result);

    return result;
  }

  visited.set(value, value);

  return value;
}

function runMatchers(
  matchers: Matcher[] = [],
  value: unknown,
  traverse: (value: any) => void,
): [boolean, any] {
  for (const [matches, setter] of matchers) {
    if (matches(value) && setter) {
      return [true, setter(value, traverse)];
    }
  }

  return [false, undefined];
}
