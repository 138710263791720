import type {ErrorScope} from 'src/internal/errors/types';
import type {ErrorEventReport, ObservabilityConfig} from 'src/types';

export default function buildErrorScope(
  config: ObservabilityConfig,
  error: Error,
  scope?: ErrorScope,
) {
  const project = scope?.project ?? config.project;
  const tags: ErrorEventReport = {
    project,
    service: config.service ?? '<unknown>',
    page: config.page ?? '<unknown>',
    release: config.release ?? '<unknown>',
    error_message: error.message,
    error_handled: 'true',
    error_issue_id: '<unknown>',
    error_issue_url: '<unknown>',
    ...config?.errors?.tags,
    ...scope?.tags,
  };
  const extras = {
    ...config.errors?.extras,
    ...scope?.extras,
  };

  return {
    project,
    tags,
    extras,
  };
}
