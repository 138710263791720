import type {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';
import {ComponentOverrideFlag} from '../../../utils/flags/flags';

export const CapitalOverviewConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "capitalOverviewExperience" */
      '../../../components/Capital/CapitalOverview'
    ),
  enabledKey: 'capital_overview',
  enabledOverrideFlag: ComponentOverrideFlag.capital_overview,
  releaseState: 'preview',
  teamOwner: 'capital_for_platforms',
  description:
    'Serves as a mini Capital dashboard that allows the user to access their current and past Capital loan information at all times.',
  deprecated: {
    // Note: there is no platform usage for this component, so there's no need to give an alternative
    deprecatedMessage: 'The capital overview component is deprecated.',
  },
};
