import {message} from '@sail/intl';
import type {ConnectElementConfig} from '../ConnectElementList';

const messages = {
  errorText: message({
    id: 'connectEmbed.reportingChart.errorBoundary.text',
    defaultMessage:
      'Your money is secure, but your information cannot be loaded here.',
    description: 'Message shown when an unhandled reporting error is caught',
  }),
};

export const reportName = 'report-name' as const;
export const intervalType = 'interval-type' as const;
export const intervalStart = 'intervalStart' as const;
export const intervalEnd = 'intervalEnd' as const;
const reportingChartHtmlParams = [reportName, intervalType] as const;
const reportingChartSupplementalParams = [intervalStart, intervalEnd] as const;

export type ReportingChartHtmlParams = Partial<
  Record<(typeof reportingChartHtmlParams)[number], string>
>;
export type ReportingChartSupplementalParams = Partial<
  Record<(typeof reportingChartSupplementalParams)[number], string>
>;

export const ReportingChartConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "reportingChart" */
      '../../../components/Reporting/ReportingChart'
    ),
  messageOverrides: messages,
  enabledKey: 'reporting_chart',
  releaseState: 'preview',
  htmlAttributes: {
    [reportName]: {isInternal: false},
    [intervalType]: {isInternal: false},
  },
  customHtmlElementMethods: {
    setReportName: {
      type: 'AttributeSettingMethod',
      attribute: reportName,
      isInternal: false,
      description: 'Must be either `gross_volume` or `net_volume`',
    },
    setIntervalType: {
      type: 'AttributeSettingMethod',
      attribute: intervalType,
      isInternal: false,
      description:
        'Must be either `day`, `week` or `month` if specified. Otherwise defaults to a sensible value based on `intervalStart` and `intervalEnd`',
    },
    setIntervalStart: {
      type: 'SupplementalObject',
      objectToSet: intervalStart,
      isInternal: false,
      description:
        'Starting date for data displayed in the chart. When not provided, defaults to available data start',
    },
    setIntervalEnd: {
      type: 'SupplementalObject',
      objectToSet: intervalEnd,
      isInternal: false,
      description:
        'Ending date for data displayed in the chart. When not provided, defaults to available data end',
    },
  },
  teamOwner: 'reporting_connect_embedded_components',
  description: 'Displays the reporting components.',
};
