import {MessageChannel, fromMessagePort} from '@sail/rpc';
import rpc from 'src/internal/rpc/rpc';

const {port1, port2} = new MessageChannel();
port1.start();
port2.start();

export const defaultRpcClient = rpc.initClient({
  transport: fromMessagePort(port1),
});

export const defaultRpcServer = rpc.initServer({
  transport: fromMessagePort(port2),
});
