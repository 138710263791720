/* eslint-disable no-console */
import {getEnvironment} from '@sail/utils';

export default function logErrorInDev(
  error: string | Error,
  level: 'error' | 'warning' | 'info' = 'error',
  ...extraArgs: any[]
): void {
  const environment = getEnvironment();

  if (environment === 'development') {
    switch (level) {
      case 'error':
        console.error(error, ...extraArgs);
        break;
      case 'warning':
        console.warn(error, ...extraArgs);
        break;
      case 'info':
        console.log(error, ...extraArgs);
        break;
      default:
        console.error(error, ...extraArgs);
        break;
    }
  }
}
