import {isRunningInIframe} from '../../ui-layer-frame/utils/isRunningInIframe';

// Obtains a parameter URL - if we are running on iframes, this comes from the has.
// If we are running on the platform frame, this comes from query params.
export const getSearchParams = (
  // eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals
  windowURL: string = window.location.href,
): URLSearchParams => {
  const url = new URL(windowURL);

  if (isRunningInIframe()) {
    const hash = url.hash.replace('#', '');
    const hashParams = new URLSearchParams(hash);
    return hashParams;
  } else {
    return url.searchParams;
  }
};
