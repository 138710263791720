import type {ErrorsConfig} from 'src/internal/errors/types';
import type {ObservabilityConfig} from 'src/types';

// The list of attributes that it will be used to memoize in the errors object
type ErrorPropsToMemoize =
  | 'tags'
  | 'extras'
  | 'captureUnhandledErrors'
  | 'whitelistUrls'
  | 'expectedJsErrors'
  | 'disabled';

// The list of attributes that it will not be used to memoize in the errors object
type ErrorPropsToIgnore = 'projects' | '_internal_injected_methods_only';

type ErrorPropsToBeConsidered = ErrorPropsToMemoize | ErrorPropsToIgnore;

// This type throws an error if a new error config prop has been added so it has to be considered for memoization (ErrorPropsToMemoize) or has to be ignored (ErrorPropsToIgnore)
type MemoizedError = Exclude<
  keyof ErrorsConfig,
  ErrorPropsToBeConsidered
> extends never
  ? Pick<ErrorsConfig, ErrorPropsToBeConsidered>
  : never;

export default function generateId(config: ObservabilityConfig) {
  // The id is calculated by using the parameters that re-renders the hubs
  // service, project, release, page, userId, and the ones that are included in the ErrorPropsToMemoize type
  const {service, project, release, page, userId, errors} = config;

  // This destructuration removes the readonly conditions at the type level for comparation
  const memoizableErrorsConfig: MemoizedError = {
    ...errors,
  };

  const hash = [
    service,
    project,
    release,
    page,
    userId,
    JSON.stringify(memoizableErrorsConfig?.tags ?? {}),
    JSON.stringify(memoizableErrorsConfig?.extras ?? {}),
    memoizableErrorsConfig?.captureUnhandledErrors,
    JSON.stringify(memoizableErrorsConfig?.whitelistUrls ?? []),
    JSON.stringify(memoizableErrorsConfig?.expectedJsErrors ?? []),
    memoizableErrorsConfig?.disabled,
  ].join('|');

  if (typeof window !== 'undefined' && window?.btoa) {
    return window?.btoa(hash);
  }

  return hash;
}
