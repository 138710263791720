// eslint-disable-next-line @typescript-eslint/ban-types
export default function once<T extends Function>(fn: T | null): T {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let result: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (this: unknown, ...args: any[]): any {
    if (fn) {
      result = fn.apply(this, args);
      // eslint-disable-next-line no-param-reassign
      fn = null;
    }

    return result;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any;
}
