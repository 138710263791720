import {matchers, traverse} from '@sail/rpc';

import type {Encoder} from '@sail/rpc';

const encoder: Encoder = {
  encode: (data) =>
    traverse(data, [
      matchers.error.encode,
      // We don't want to send functions over the wire (ex _internal_observability),
      // so we just return 'undefined'.
      [(data: any) => typeof data === 'function', () => undefined],
    ]),
  decode: (data) => traverse(data, [matchers.error.decode]),
};

export default encoder;
