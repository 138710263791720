import buildParams from 'src/internal/metrics/buildParams';
import record from 'src/internal/metrics/record';

import type {
  ImperativeMetrics,
  MetricName,
  ObservabilityConfig,
} from 'src/types';

const method = <TMethod extends keyof ImperativeMetrics>(
  type: TMethod,
): ImperativeMetrics[TMethod] => {
  return (
    config: ObservabilityConfig | null,
    metric: MetricName,
    ...params: any
  ): void => {
    if (!config || config.metrics?.disabled) {
      return;
    }

    const copy = [...params];
    const index = type === 'increment' ? 0 : 1;

    copy[index] = buildParams(config, copy[index]);

    record(config, metric as string, Object.assign({}, ...copy));
  };
};

export const increment: ImperativeMetrics['increment'] = method('increment');
export const gauge: ImperativeMetrics['gauge'] = method('gauge');
export const set: ImperativeMetrics['set'] = method('set');
