export const getLoadedFontFamilies = () => {
  try {
    // eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals
    if (document?.fonts == null) {
      return ['document.fonts is undefined'];
    }

    const set = new Set<string>();

    // eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals
    document?.fonts?.forEach((font) => {
      if (!font?.family) {
        set.add('error: font.family is undefined');
        return;
      }
      set.add(font.family);
    });

    return Array.from(set);
  } catch (error: any) {
    return [`error when walking fonts "${error?.message}"`];
  }
};

const measurementKeys = [
  'width',
  'actualBoundingBoxLeft',
  'actualBoundingBoxRight',
  'fontBoundingBoxAscent',
  'fontBoundingBoxDescent',
  'actualBoundingBoxAscent',
  'actualBoundingBoxDescent',
] as const;

// eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals
const context = document.createElement('canvas').getContext('2d');
export const isFontLoaded = (font: string) => {
  if (!context) {
    return false;
  }

  // Contains unadorned Latin characters, Simplified Chinese characters, Japanese characters, and full-width spaces
  const sampleString =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+{}|[]\\:";\'<>?,./`~\u3000\u4e00\u4e01\u4e02\u4e03\u4e04\u4e05\u4e06\u4e07\u4e08\u4e09\u4e0a\u4e0b\u4e0c\u4e0d\u4e0e\u3042\u3044\u3046\u3048\u304a\u304b\u304d\u304f\u3051\u3053';

  context.font = '14px serif';
  const serifMetrics = context.measureText(sampleString);
  context.font = `14px ${font}`;
  const fontMetrics = context.measureText(sampleString);
  return !measurementKeys.every(
    (key) => fontMetrics[key] === serifMetrics[key],
  );
};

export const getFirstLoadedFont = (stack: string | undefined) => {
  if (!stack) {
    return 'serif';
  }
  const fontFamilyMatcher = /(?:((?:[^,"';]|"[^"]*"|'[^']*')+)(?:,\s*|$))/g;
  const matches = Array.from(stack.matchAll(fontFamilyMatcher));
  const fonts = matches.map((match) => match[1]);

  return fonts?.find((font: string) => isFontLoaded(font)) || 'serif';
};
