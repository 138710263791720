import {
  ACK_MESSAGE,
  FIN_MESSAGE,
  FIN_SERVER_MESSAGE,
  SYN_ACK_MESSAGE,
  SYN_MESSAGE,
  SYN_SERVER_MESSAGE,
} from 'src/internal/rpc-handshake/constants';

import type {
  AckEvent,
  FinEvent,
  FinServerEvent,
  SynAckEvent,
  SynEvent,
  SynServerEvent,
} from 'src/internal/rpc-handshake/types';
import type {Transferable, Transport} from 'src/internal/transport/types';

const HANDSHAKE_MESSAGES = [
  SYN_MESSAGE,
  SYN_SERVER_MESSAGE,
  SYN_ACK_MESSAGE,
  ACK_MESSAGE,
  FIN_SERVER_MESSAGE,
  FIN_MESSAGE,
];

function isHandshakeEvent(namespace: string, event: any) {
  return (
    typeof event === 'object' &&
    typeof event.message === 'string' &&
    typeof event.namespace === 'string' &&
    event.namespace === namespace &&
    HANDSHAKE_MESSAGES.includes(event.message)
  );
}

export function createRpcHandshake(namespace: string) {
  return {
    syn: (
      transport: Transport,
      data: SynEvent['data'],
      transfer?: Transferable[],
    ) => {
      const event: SynEvent = {
        namespace,
        message: SYN_MESSAGE,
        data,
      };

      transport.postMessage(event, transfer);
    },
    synServer: (
      transport: Transport,
      data: SynServerEvent['data'],
      transfer?: Transferable[],
    ) => {
      const event: SynServerEvent = {
        namespace,
        message: SYN_SERVER_MESSAGE,
        data,
      };

      transport.postMessage(event, transfer);
    },
    synAck: (
      transport: Transport,
      data: SynAckEvent['data'],
      transfer?: Transferable[],
    ) => {
      const event: SynAckEvent = {
        namespace,
        message: SYN_ACK_MESSAGE,
        data,
      };

      transport.postMessage(event, transfer);
    },
    ack: (
      transport: Transport,
      data: AckEvent['data'],
      transfer?: Transferable[],
    ) => {
      const event: AckEvent = {
        namespace,
        message: ACK_MESSAGE,
        data,
      };

      transport.postMessage(event, transfer);
    },
    fin: (
      transport: Transport,
      data: FinEvent['data'],
      transfer?: Transferable[],
    ) => {
      const event: FinEvent = {
        namespace,
        message: FIN_MESSAGE,
        data,
      };

      transport.postMessage(event, transfer);
    },
    finServer: (
      transport: Transport,
      data: FinServerEvent['data'],
      transfer?: Transferable[],
    ) => {
      const event: FinServerEvent = {
        namespace,
        message: FIN_SERVER_MESSAGE,
        data,
      };

      transport.postMessage(event, transfer);
    },
    isSynEvent: (event: any): event is SynEvent => {
      return (
        isHandshakeEvent(namespace, event) && event.message === SYN_MESSAGE
      );
    },
    isSynServerEvent: (event: any): event is SynServerEvent => {
      return (
        isHandshakeEvent(namespace, event) &&
        event.message === SYN_SERVER_MESSAGE
      );
    },
    isSynAckEvent: (event: any): event is SynAckEvent => {
      return (
        isHandshakeEvent(namespace, event) && event.message === SYN_ACK_MESSAGE
      );
    },
    isAckEvent: (event: any): event is AckEvent => {
      return (
        isHandshakeEvent(namespace, event) && event.message === ACK_MESSAGE
      );
    },
    isFinEvent: (event: any): event is FinEvent => {
      return (
        isHandshakeEvent(namespace, event) && event.message === FIN_MESSAGE
      );
    },
    isFinServerEvent: (event: any): event is FinServerEvent => {
      return (
        isHandshakeEvent(namespace, event) &&
        event.message === FIN_SERVER_MESSAGE
      );
    },
  };
}
