export default {
  blacklistUrls: [
    /draft-js\/lib\/.*/,
    /draft-js\/node_modules\/.*/,
    /.*moz-extension.*/,
    /.*safari-extension.*/,
    /.*chrome-extension.*/,
    /\/\/www\.google-analytics\.com.*/,
    // These appear to be just from browser plugins
    /<anonymous>/,
    /EventEmitter.<anonymous>.*/,
    'file*',
    /\?\(blob:.*/,
    /\?\(undefined\)/,
  ],
  whitelistUrls: [],
  ignoreErrors: [
    // Lastpass chunders this error in IE 11.
    "ReferenceError: 'pass' is undefined",
    'Permission denied to access property "href" on cross-origin object',

    // js getting injected by the iOS version of Chrome GCRWeb
    '__gCrWeb',
    /Failed to execute 'open' on 'XMLHttpRequest': Refused to connect to.*/,
    /Failed to execute 'send' on 'XMLHttpRequest': Refused to connect to.*/,

    // React internals, don't seem to be actionable:
    'Should not already be working.',
    /Minified React error #327.*/, // Same error as above, wrapped

    /Loading chunk \d+ failed/,
    /Loading CSS chunk \d+ failed/,

    // A JS parsing bug in old Android Chrome (http://go/jira/DASH-9807)
    "Unexpected token 'else'",

    // These can appear out of localStorage overflow, or a JSON response too big for device's memory.
    // It may be beneficial to capture spikes in volume of such errors, but besides that it's not actionable.
    'Out of memory',
    'out of memory',

    // Caused by SeleniumIDE browser extension (http://go/jira/DASH-9809)
    "Identifier 'originalPrompt' has already been declared",

    // Seems related to Edge on iOS (https://stackoverflow.com/a/69576781)
    "Can't find variable: instantSearchSDKJSBridgeClearHighlight",

    // Has something to do with UC Browser: https://en.wikipedia.org/wiki/UC_Browser
    // We don't reference 'UCBrowserMessageCenter' anywhere in our JS code.
    "Can't find variable: UCBrowserMessageCenter",

    // Most of these happen on Firefox iOS 14 (released Nov 2018)
    // We don't have any references to 'webkit.messageHandlers' in our JS code.
    /undefined is not an object \(evaluating '(window\.)?webkit\.messageHandlers.*/,
    "Can't find variable: webkit",
    "undefined is not an object (evaluating 'document.getElementsByName*",
    "undefined is not an object (evaluating 'document.getElementsByTagName('video')[0].webkitExitFullScreen')",

    // All of these happen on Safari 12.0 and Mobile Safari 12.0 (released Sep 2018)
    // We don't have any references to 'ceCurrentVideo.currentTime' in our JS code.
    "undefined is not an object (evaluating 'ceCurrentVideo.currentTime')",

    // I couldn't find if this is caused by some specific extension or not.
    // We don't have any references to 'inputItem' in our JS code.
    "Identifier 'inputItem' has already been declared",

    // ResizeObserver errors seem safe to ignore:
    // https://stackoverflow.com/a/50387233
    'ResizeObserver loop limit exceeded', // chrome
    'ResizeObserver loop completed with undelivered notifications.', // firefox

    // This error comes from a weird recaptcha extension
    'Missing required parameters: sitekey',

    // These errors happen when people download the HTML for the Dashboard and then run it locally (why are they doing this?!)
    "SecurityError: Failed to execute 'replaceState' on 'History'",
    "'file:///C:/'",

    // This error is from running out of local storage space
    'QuotaExceededError',

    // Firefox specific error that is handled / not useful
    'InvalidStateError: An attempt was made to use an object that is not, or is no longer, usable',

    // ServiceWorker errors that are okay to ignore.
    'The operation is insecure.',
    'Failed to get ServiceWorkerRegistration objects: The document is in an invalid state.',

    // these happens sometimes when Mapbox is rendering
    'Failed to initialize WebGL.',
    'WebGLRenderingContext',

    // https://jira.corp.stripe.com/browse/RUN_DASHPLAT-2060
    'TypeError: cancelled',
    'TypeError: Abgebrochen',
    'TypeError: cancelado',
    'TypeError: 취소됨',
    'TypeError: annulé',
    'TypeError: annullato',
    'TypeError: anulat',
    'TypeError: geannuleerd',
    'TypeError: 網絡連線中斷。',
    'TypeError: avbruten',
    'TypeError: kumottu',
    'TypeError: отменено',
    'TypeError: zrušené',

    // 'Failed to fetch' unhandled rejections
    'TypeError: Failed to fetch',
    'TypeError: Load failed',

    // 'cannot parse response' unhandled rejections
    'TypeError: cannot parse response',
    'TypeError: impossible d’analyser la réponse',
    'TypeError: impossibile analizzare la risposta',
    'TypeError: 応答を解析できません',
    'TypeError: Parsen der Antwort nicht möglich.',
    'TypeError: не удается произвести анализ ответа',

    // 'NetworkError when attempting to fetch resource.' unhandled rejections
    'TypeError: NetworkError when attempting to fetch resource.',

    // Some unsupported browsers load properly, but throw flatMap errors.
    /.*\.flat(Map)? is not a function/,
    "Object doesn't support property or method 'flat",

    // Error code 499 is when the client disconnects from the server
    'Network error: Response not successful: Received status code 499',

    // Occurs in Safari when a TCP connection fails while the request was in progress
    'TypeError: The network connection was lost.',
    'TypeError: La conexión de red se perdió.',
    'TypeError: la connessione è stata persa.',
    'TypeError: La connexion réseau a été perdue.',
    'TypeError: Die Netzwerkverbindung wurde unterbrochen.',
    'TypeError: Połączenie sieciowe zostało przerwane.',

    // No internet seems inactionable.
    'TypeError: The internet connection appears to be offline.',
    'TypeError: The Internet connection appears to be offline.',

    // https://jira.corp.stripe.com/browse/RUN_DASHPLAT-2895
    'Function statements require a function name',

    // https://jira.corp.stripe.com/browse/RUN_DASHPLAT-2912
    'Possible side-effect in debug-evaluate',

    // Occurs in Firefox when the user is using one profile on multiple Firefox version
    /ServiceWorker script .*sw-stripe-2.js .* encountered an error during installation./,
    'TypeError: Script https://dashboard.stripe.com/sw-stripe-2.js load failed',

    // Remove after Stripelet error boundaries are updated in:
    // https://jira.corp.stripe.com/browse/WEBAPP-48
    // https://jira.corp.stripe.com/browse/WEBAPP-49
    "Cannot read properties of undefined (reading 'js')",

    // An ongoing request was intentionally canceled by the user.
    'AbortError: The user aborted a request.',

    // Caused by Capital One Shopping browser extension
    "Identifier 'listenerName' has already been declared",
  ],
};
