import {message} from '@sail/intl';
import type {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';

export const messages = {
  errorText: message({
    id: 'connectEmbed.disputes.errorBoundary.text',
    defaultMessage:
      'Your money is secure, but your disputes information cannot be loaded here.',
    description: 'Message shown when an unhandled disputes error is caught',
  }),
};

export const DisputesConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "disputesExperience" */ '../../../components/Disputes/Disputes'
    ),
  messageOverrides: messages,
  releaseState: 'internal',
  enabledKey: 'internal',
  teamOwner: 'accounts_ui',
  description: 'Displays the list of disputes, allows for dispute management.',
};
