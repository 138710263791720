import {ComponentOverrideFlag} from '../../../utils/flags/flags';
import type {ConnectElementConfig} from '../ConnectElementList';

export const debugParameter = 'debug' as const;
export const privacyPolicyUrlParameter = 'privacy-policy-url' as const;
export const howCapitalWorksUrlParameter = 'how-capital-works-url' as const;

export const CapitalFinancingApplicationConfig: ConnectElementConfig = {
  Component: () =>
    import('../../../components/Capital/CapitalFinancingApplication'),
  enabledKey: 'capital_financing_application',
  enabledOverrideFlag: ComponentOverrideFlag.capital_financing_application,
  htmlAttributes: {
    [debugParameter]: {
      isInternal: true,
    },
    [privacyPolicyUrlParameter]: {
      isInternal: false,
    },
    [howCapitalWorksUrlParameter]: {
      isInternal: false,
    },
  },
  htmlEvents: ['onapplicationsubmitted'],
  customHtmlElementMethods: {
    setOnApplicationSubmitted: {
      type: 'EventListenerMethod',
      event: 'onapplicationsubmitted',
      isInternal: false,
      description:
        'Sets the event listener that allows a user to pass in a callback function for when a financing application is submitted.',
    },
    setPrivacyPolicyUrl: {
      type: 'AttributeSettingMethod',
      attribute: privacyPolicyUrlParameter,
      isInternal: false,
      description:
        "Customizes the URL used for the Privacy Policy link in the application component. If not set, will default to Stripe's Privacy Policy.",
    },
    setHowCapitalWorksUrl: {
      type: 'AttributeSettingMethod',
      attribute: howCapitalWorksUrlParameter,
      isInternal: false,
      description:
        "Customizes the URL used for links to documentation about the Capital program in the application component. If not set, will default to Stripe Capital's documentation.",
    },
  },
  releaseState: 'preview',
  teamOwner: 'capital_for_platforms',
  description:
    'Capital offer application that is available in the CapitalFinancingPromotion component but can also be standalone.',
};
