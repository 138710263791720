import stableStringify from './stableStringify';

export default function memoize<TParameters extends unknown[], TResult>(
  fn: (...args: TParameters) => TResult,
  resolver: (args: TParameters) => string = stableStringify,
): (...args: TParameters) => TResult {
  const cache: {[key: string]: TResult} = Object.create(null);

  return (...args: TParameters): TResult => {
    const key = resolver(args);

    if (!(key in cache)) {
      cache[key] = fn(...args);
    }

    return cache[key];
  };
}
