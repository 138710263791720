/* eslint-disable @stripe-internal/embedded/no-restricted-globals */
import type {
  Axis,
  SizeAxis,
  Placement,
  PlacementAxis,
} from '@react-types/overlays';

export type PlacementInput = Extract<
  Placement,
  | 'top'
  | 'top left'
  | 'top right'
  | 'bottom'
  | 'bottom left'
  | 'bottom right'
  | 'left'
  | 'left top'
  | 'left bottom'
  | 'right'
  | 'right top'
  | 'right bottom'
>;

export type AttachmentPoints = {
  margin: string;
  bottom: number;
  top: number;
  left: number;
  right: number;
  centerX: number;
  centerY: number;
  width: number;
  height: number;
};

type PositionInfo = {
  top: number;
  left: number;
  width: number;
  height: number;
};
export type BoundaryPositionInfo = PositionInfo & {
  scroll: {
    top: number;
    left: number;
  };
};

const AXIS = {
  top: 'top',
  bottom: 'top',
  left: 'left',
  right: 'left',
} as const;
const FLIPPED_AXIS = {
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left',
} as const;
const CROSS_POSITION = {
  top: 'left',
  left: 'top',
} as const;
const AXIS_SIZE = {
  top: 'height',
  left: 'width',
} as const;

type Position = 'top' | 'left';

type PlacementInfo = {
  axis: Axis;
  crossAxis: PlacementAxis;
  position: Position;
  crossPosition: Position;
  size: SizeAxis;
  crossSize: SizeAxis;
};

type AccessoryFrameDimensions = {
  offsetWidth: number;
  offsetHeight: number;
  scrollTop: number;
  scrollLeft: number;
  scrollWidth: number;
  scrollHeight: number;
};
type SourceFrameDimensions = {
  offsetTop: number;
  offsetLeft: number;
};
export type SafeAreaDimensions = {
  top: number;
  left: number;
};
type CalculateAttachmentProps = {
  placement: PlacementInput;
  attachmentPoints: AttachmentPoints;
  sourceFrameDimensions: SourceFrameDimensions;
  accessoryFrameDimensions: AccessoryFrameDimensions;
  safeAreaDimensions?: SafeAreaDimensions;
  isFixedFrame: boolean;
  flip: boolean;
};

const PARSED_PLACEMENT_CACHE: Record<string, PlacementInfo> = {};

function parsePlacement(input: string): PlacementInfo {
  if (PARSED_PLACEMENT_CACHE[input]) {
    return PARSED_PLACEMENT_CACHE[input];
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const split = input.split(' ') as (keyof typeof AXIS)[];
  const [axis] = split;
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  let [, crossAxis] = split as PlacementAxis[];
  const position = AXIS[axis] || 'right';
  const crossPosition = CROSS_POSITION[position];
  if (!(crossAxis in AXIS)) {
    crossAxis = 'center';
  }
  const size = AXIS_SIZE[position];
  const crossSize = AXIS_SIZE[crossPosition];
  PARSED_PLACEMENT_CACHE[input] = {
    axis,
    crossAxis,
    position,
    crossPosition,
    size,
    crossSize,
  };
  return PARSED_PLACEMENT_CACHE[input];
}

/**
 * This function returns the available space for the popover/tooltip to be rendered in with respect to its boundary
 */
function getAvailableSpace(
  boundaryDimensions: BoundaryPositionInfo,
  targetDimensions: PositionInfo,
  placementInfo: PlacementInfo,
) {
  const {axis, position, size} = placementInfo;
  if (axis === position) {
    return Math.max(
      0,
      targetDimensions[position] -
        boundaryDimensions[position] -
        boundaryDimensions.scroll[position],
    );
  }

  return Math.max(
    0,
    boundaryDimensions[size] +
      boundaryDimensions.scroll[position] -
      targetDimensions[position] -
      targetDimensions[size],
  );
}

/**
 * This function returns the top and left positions of the popover/tooltip according to the placement that was specified
 */
type PositionStyles = {
  position: string;
  top: string;
  left: string;
};
const computePositionStyles = ({
  placementInput,
  attachmentPoints,
  sourceFrameDimensions,
  accessoryFrameDimensions,
}: {
  placementInput: PlacementInput;
  attachmentPoints: AttachmentPoints;
  sourceFrameDimensions: SourceFrameDimensions;
  accessoryFrameDimensions: AccessoryFrameDimensions;
}): PositionStyles => {
  const targetTop = attachmentPoints.top + sourceFrameDimensions.offsetTop;
  const targetBottom =
    attachmentPoints.bottom + sourceFrameDimensions.offsetTop;
  const targetLeft = attachmentPoints.left + sourceFrameDimensions.offsetLeft;
  const targetRight = attachmentPoints.right + sourceFrameDimensions.offsetLeft;

  const centerX =
    attachmentPoints.centerX +
    sourceFrameDimensions.offsetLeft -
    accessoryFrameDimensions.offsetWidth / 2;
  const centerY =
    attachmentPoints.centerY +
    sourceFrameDimensions.offsetTop -
    accessoryFrameDimensions.offsetHeight / 2;

  const placementMap = {
    'top left': {
      top: targetTop - accessoryFrameDimensions.offsetHeight,
      left: targetLeft,
    },
    'top right': {
      top: targetTop - accessoryFrameDimensions.offsetHeight,
      left: targetRight - accessoryFrameDimensions.offsetWidth,
    },
    top: {
      top: targetTop - accessoryFrameDimensions.offsetHeight,
      left: centerX,
    },
    'left top': {
      top: targetTop,
      left: targetLeft - accessoryFrameDimensions.offsetWidth,
    },
    'left bottom': {
      top: targetBottom - accessoryFrameDimensions.offsetHeight,
      left: targetLeft - accessoryFrameDimensions.offsetWidth,
    },
    left: {
      top: centerY,
      left: targetLeft - accessoryFrameDimensions.offsetWidth,
    },
    'bottom left': {
      top: targetBottom,
      left: targetLeft,
    },
    'bottom right': {
      top: targetBottom,
      left: targetRight - accessoryFrameDimensions.offsetWidth,
    },
    bottom: {
      top: targetBottom,
      left: centerX,
    },
    'right top': {
      top: targetTop,
      left: targetRight,
    },
    'right bottom': {
      top: targetBottom - accessoryFrameDimensions.offsetHeight,
      left: targetRight,
    },
    right: {
      top: centerY,
      left: targetRight,
    },
  };

  return {
    position: 'absolute',
    top: `${placementMap[placementInput].top}px`,
    left: `${placementMap[placementInput].left}px`,
  };
};

/**
 * This function returns the dimensions of the boundary that the popover/tooltip should be constrained to.
 * For example:
 * - If the popover/tooltip is rendered from the UI layer, the boundary dimensions would be the
 *   dimensions of the platform frame, i.e. document.body
 * - If the popover/tooltip is rendered from the accessory layer, the boundary dimensions would be the
 *   dimensions of the accessory frame of the source element, i.e. the button that triggered the popover/tooltip
 */
function getBoundaryDimensions(
  containerNode: HTMLElement,
): BoundaryPositionInfo {
  const visualViewport = typeof window !== 'undefined' && window.visualViewport;
  const {ownerDocument} = containerNode;

  return {
    width: visualViewport
      ? visualViewport.width
      : ownerDocument.documentElement.clientWidth,
    height: visualViewport
      ? visualViewport.height
      : ownerDocument.documentElement.clientHeight,
    top: 0,
    left: 0,
    scroll: {
      top: ownerDocument.documentElement.scrollTop || containerNode.scrollTop,
      left:
        ownerDocument.documentElement.scrollLeft || containerNode.scrollLeft,
    },
  };
}

/**
 * This function returns the delta that the popover/tooltip should be shifted by in order to be constrained to its boundary
 * For example, if the popover/tooltip is positioned out of the boundary to the left,
 * this will return the delta we should shift if right by.
 */
function getDelta(
  positionStyles: PositionStyles,
  placementInfo: PlacementInfo,
  accessoryFrameDimensions: AccessoryFrameDimensions,
  boundaryDimensions: BoundaryPositionInfo,
  padding: number,
) {
  const axis = placementInfo.crossPosition;
  const positionOffset = Number(
    positionStyles[placementInfo.crossPosition].replace('px', ''),
  );
  const accessoryFrameSize =
    placementInfo.crossSize === 'height'
      ? accessoryFrameDimensions.offsetHeight
      : accessoryFrameDimensions.offsetWidth;

  const containerScroll = boundaryDimensions.scroll[axis];
  const containerHeight = boundaryDimensions[AXIS_SIZE[axis]];
  const startEdgeOffset = positionOffset - padding - containerScroll;
  const endEdgeOffset =
    positionOffset + padding - containerScroll + accessoryFrameSize;

  if (startEdgeOffset < 0) {
    return -startEdgeOffset;
  } else if (endEdgeOffset > containerHeight) {
    return Math.max(containerHeight - endEdgeOffset, -startEdgeOffset);
  } else {
    return 0;
  }
}

export const calculatePosition = ({
  placement: placementInput,
  attachmentPoints,
  sourceFrameDimensions,
  accessoryFrameDimensions,
  isFixedFrame,
  flip,
  safeAreaDimensions,
}: CalculateAttachmentProps): Partial<CSSStyleDeclaration> => {
  const visualViewport = typeof window !== 'undefined' && window.visualViewport;

  let positionStyles = computePositionStyles({
    placementInput,
    attachmentPoints,
    sourceFrameDimensions,
    accessoryFrameDimensions,
  });

  // Get dimensions of the boundary that the popover/tooltip should be constrained to
  // If we are rendering the popover/tooltip from the UI layer, we need to use the document.body as the boundary dimensions instead of the UI layer's boundary
  // This is the boundary dimensions of the trigger of the popover/tooltip. For example, if the popoover/tooltip is rendered from the UI layer, this would be the dimensions of the UI layer
  const boundaryDimensions = isFixedFrame
    ? {
        scroll: {
          top: 0,
          left: 0,
        },
        top: 0,
        left: 0,
        width: window.visualViewport?.width ?? document.body.clientWidth,
        height: visualViewport
          ? visualViewport.height
          : document.body.clientHeight,
      }
    : getBoundaryDimensions(document.body);

  // Get the position of the target element that triggered the popover/tooltip
  const targetDimensions = {
    top: attachmentPoints.top + sourceFrameDimensions.offsetTop,
    left: attachmentPoints.left + sourceFrameDimensions.offsetLeft,
    width: attachmentPoints.width,
    height: attachmentPoints.height,
  };
  // Get the current position and dimensions of the popover/tooltip
  const layerDimensions = {
    top: accessoryFrameDimensions.scrollTop,
    left: accessoryFrameDimensions.scrollLeft,
    width: accessoryFrameDimensions.scrollWidth,
    height: accessoryFrameDimensions.scrollHeight,
  };

  let placementInfo = parsePlacement(placementInput);
  const {size, axis, crossAxis} = placementInfo;

  // Get the available space for the popover/tooltip to be rendered in with respect to its boundary
  const space = getAvailableSpace(
    boundaryDimensions,
    targetDimensions,
    placementInfo,
  );

  // Flipping logic using the axis, i.e. "top left" -> axis = top, "left top" -> axis = left
  // If the other side of the popover/tooltip has more space, flip it to that side, i.e. "top left" becomes "bottom left"
  if (layerDimensions[size] > space && flip) {
    const flippedPlacementInfo = parsePlacement(
      `${FLIPPED_AXIS[axis]} ${crossAxis}`,
    );
    const flippedPositionStyles = computePositionStyles({
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      placementInput: `${flippedPlacementInfo.axis}${
        flippedPlacementInfo.crossAxis === 'center'
          ? ''
          : ` ${flippedPlacementInfo.crossAxis}`
      }` as PlacementInput,
      attachmentPoints,
      sourceFrameDimensions,
      accessoryFrameDimensions,
    });
    const flippedSpace = getAvailableSpace(
      boundaryDimensions,
      targetDimensions,
      flippedPlacementInfo,
    );
    if (flippedSpace > space) {
      placementInfo = flippedPlacementInfo;
      positionStyles = flippedPositionStyles;
    }
  }

  // Shifting logic using the cross position, i.e. if the popover/tooltip is rendered too far left such that it is hidden from view, shift it to the right
  // We have to use the "root origin", i.e. either the window or the document.body as the boundary dimensions
  // For example, if a nested popover is opened from the UI layer, the boundary dimensions should always be the document.body here
  // If a nested popover is opened from the overlay, the boundary dimensions should always be the window viewport
  const delta = getDelta(
    positionStyles,
    placementInfo,
    accessoryFrameDimensions,
    boundaryDimensions,
    0,
  );
  positionStyles[placementInfo.crossPosition] = `${
    Number(positionStyles[placementInfo.crossPosition].replace('px', '')) +
    delta
  }px`;

  // If safe area dimensions exist and if computed positions are less than the safe area dimensions, set them to the safe area dimensions
  if (safeAreaDimensions) {
    positionStyles.top = `${Math.max(
      Number(positionStyles.top.replace('px', '')),
      safeAreaDimensions.top,
    )}px`;
    positionStyles.left = `${Math.max(
      Number(positionStyles.left.replace('px', '')),
      safeAreaDimensions.left,
    )}px`;
    // We currently don't cover right and bottom safe areas as that calculation is more complex and we haven't had issues reported yet
  }

  // If the target element that triggered the popover/tooltip is located in an accessory frame that has a fixed position,
  // i.e. an overlay, we need to set the position of the popover/tooltip to fixed as well so that
  // scrolling the page will not have affect the popover/tooltip's position
  if (isFixedFrame) positionStyles.position = 'fixed';

  // Calculate margin between attachment point and popover/tooltip
  const parsedMargin = Number(attachmentPoints.margin.replace('px', ''));
  const offsetMarginMap = {
    top: -parsedMargin,
    bottom: parsedMargin,
    left: -parsedMargin,
    right: parsedMargin,
  } as const;
  if (placementInfo.axis === 'top' || placementInfo.axis === 'bottom') {
    positionStyles.top = `${
      Number(positionStyles.top?.replace('px', '')) +
      offsetMarginMap[placementInfo.axis]
    }px`;
  } else {
    positionStyles.left = `${
      Number(positionStyles.left?.replace('px', '')) +
      offsetMarginMap[placementInfo.axis]
    }px`;
  }

  return positionStyles;
};
