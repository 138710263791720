import type {Transferable, Transport} from 'src/internal/transport/types';

export default function fromMessagePort(messagePort: MessagePort): Transport {
  return {
    postMessage: (...args: [any, Transferable[]]) =>
      messagePort.postMessage(...args),
    addEventListener: (...args) => messagePort.addEventListener(...args),
    removeEventListener: (...args) => messagePort.removeEventListener(...args),
    terminate() {
      messagePort.close();
    },
  };
}
