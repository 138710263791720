export const getDebugComponent = (platformUrl: string): string | null => {
  const parsedPlatformUrl = new URL(platformUrl);
  const specifiedComponent =
    parsedPlatformUrl.searchParams.get('debug_component');

  if (specifiedComponent) {
    return specifiedComponent;
  } else if (parsedPlatformUrl.pathname.endsWith('sample_platform.html')) {
    const components = parsedPlatformUrl.hash.split('/');
    return components[components.length - 1];
  } else if (
    parsedPlatformUrl.hostname === 'stripe-connect-test.onrender.com'
  ) {
    const components = parsedPlatformUrl.pathname.split('/');
    return `stripe-connect-${components[components.length - 1]
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')}`;
  } else {
    return null;
  }
};
