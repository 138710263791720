/* eslint-disable @stripe-internal/embedded/no-restricted-globals */
import {getCurrentScriptUrlContext} from '../../utils/getCurrentScriptUrlContext';
import {embeddedComponentParamName} from '../buildUILayerFrame';

let hasCachedAccessoryLayer = false;
export const preloadAccessoryLayerFrame = async (): Promise<void> => {
  if (hasCachedAccessoryLayer) {
    return;
  }
  hasCachedAccessoryLayer = true;
  const scriptUrlContext = getCurrentScriptUrlContext();
  const accessoryUrl = new URL(
    // eslint-disable-next-line camelcase
    `${scriptUrlContext.absoluteFolderPath}/accessory_layer_${__webpack_hash__}.html`,
  );

  const iframe = document.createElement('iframe');
  // Set URL search params so that the accessory layer won't throw an error
  const searchParams = new URLSearchParams();
  searchParams.set('layer_type', 'partial');
  searchParams.set(embeddedComponentParamName, 'stripe-connect-debug-utils');
  accessoryUrl.hash = searchParams.toString();

  // Hide the iframe
  iframe.style.display = 'none';

  iframe.src = accessoryUrl.toString();
  window.document.body.append(iframe);
  return new Promise((resolve, reject) => {
    iframe.onload = () => {
      iframe.remove();
      resolve();
    };
    iframe.onerror = (error) => {
      reject(error);
    };
  });
};
