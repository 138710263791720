import type {CustomFontOptions} from '@stripe-internal/connect-embedded-lib';
import {
  CONNECT_ELEMENTS_OVERLAYS_DEFAULT,
  CONNECT_ELEMENTS_OVERLAY_ZINDEX_DEFAULT,
  getAppearanceValuesWithDefaults,
} from '@stripe-internal/embedded-theming';
import type {
  UpdateOptions,
  MetaOptions,
  InitOptions,
} from './ConnectJSInterface/InitAndUpdateOptionsTypes';

/**
 * ConnectJS options with default values filled in
 */
export type IConnectJsOptionsWithDefaultValues = Required<UpdateOptions> & {
  metaOptions: MetaOptions;
  locale: string;
  fonts: CustomFontOptions;
};

/**
 * ConnectJS options that are specified by the platform
 */
export type IPlatformSpecifiedConnectJsOptions = Omit<
  InitOptions,
  'clientSecret' | 'publishableKey' | 'refreshClientSecret'
>;

export const defaultConnectJsOptions = {
  appearance: {
    overlays: CONNECT_ELEMENTS_OVERLAYS_DEFAULT,
    variables: {
      overlayZIndex: CONNECT_ELEMENTS_OVERLAY_ZINDEX_DEFAULT,
    },
  },
  metaOptions: {},
  locale: '',
  sdkOptions: {},
  fonts: [],
};

export class ConnectJsOptions {
  private observers: ((values: IPlatformSpecifiedConnectJsOptions) => void)[];

  private platformSpecifiedValues: IPlatformSpecifiedConnectJsOptions;

  private calculatedValues: IConnectJsOptionsWithDefaultValues;

  /**
   * Initialize with ConnectJS init options and overrides
   */
  constructor(
    initOptions: IPlatformSpecifiedConnectJsOptions,
    initOverrides?: Partial<InitOptions> | undefined,
  ) {
    this.observers = [];
    // We consider init overrides as part of the platform specified values
    this.platformSpecifiedValues = {...initOptions, ...initOverrides};
    this.calculatedValues = this.calculateInitValuesWithDefaults();
  }

  public getPlatformSpecifiedValues(): IPlatformSpecifiedConnectJsOptions {
    return this.platformSpecifiedValues;
  }

  public getValues(): IConnectJsOptionsWithDefaultValues {
    return this.calculatedValues;
  }

  private calculateInitValuesWithDefaults(): IConnectJsOptionsWithDefaultValues {
    const {appearance, metaOptions, locale, fonts} =
      this.platformSpecifiedValues;

    const calculatedAppearanceVariables = {
      appearance: getAppearanceValuesWithDefaults(
        appearance ?? defaultConnectJsOptions.appearance,
      ),
      metaOptions: metaOptions ?? {},
      locale: locale ?? '',
      sdkOptions: metaOptions?.sdkOptions ?? {},
      fonts: fonts ?? [],
    };

    return calculatedAppearanceVariables;
  }

  public updateValues(newValues: UpdateOptions) {
    // Simply update existing values with new values
    this.platformSpecifiedValues = {
      ...this.platformSpecifiedValues,
      ...newValues,
    };
    this.calculatedValues = this.calculateInitValuesWithDefaults();
    // eslint-disable-next-line no-restricted-syntax
    for (const observer of this.observers) {
      observer(this.platformSpecifiedValues);
    }
  }

  public registerObserver(observer: () => void) {
    this.observers.push(observer);
  }

  public unregisterObserver(observer: () => void) {
    this.observers = this.observers.filter((o) => o !== observer);
  }
}
