import {getEnvironment, noop} from '@sail/utils';

import type {ObservabilityConfig} from 'src/types';

export const DEFAULT_OBSERVABILITY_CONFIG: ObservabilityConfig = {
  project: 'sail_core',
  service: 'webapp-platform',
  errors: {
    projects: {
      sail_core:
        getEnvironment() === 'production'
          ? 'https://28219678049240a7b5ccd5c4fa8075fe@errors.stripe.com/992'
          : 'https://e78e80651cc645a1bab10afbcb4a7e28@qa-errors.stripe.com/853',
    },
  },
} as const;

export const QUIESCENSE_WINDOW = 1000 as const;

export const DEFAULT_SET_AND_UNSET_VALUE = {
  set: noop,
  unset: noop,
} as const;

export const DEFAULT_START_VALUE = noop;
