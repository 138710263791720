import noop from './noop';

type ResolveCallback<T> = (value: T | PromiseLike<T>) => void;
type RejectCallback = (reason?: Error) => void;
type State = 'pending' | 'resolved' | 'rejected';

export default class Deferred<T> {
  public readonly promise: Promise<T>;

  public readonly resolve: ResolveCallback<T>;

  public readonly reject: RejectCallback;

  private _state: State = 'pending';

  // Using getter so that the state cannot be changed from outside the class.
  public get state(): State {
    return this._state;
  }

  constructor() {
    let res: ResolveCallback<T> = noop;
    let rej: RejectCallback = noop;

    this.promise = new Promise((resolve, reject) => {
      res = (value: T | PromiseLike<T>) => {
        this._state = 'resolved';
        resolve(value);
      };
      rej = (reason?: Error) => {
        this._state = 'rejected';
        reject(reason);
      };
    });

    this.resolve = res;
    this.reject = rej;
  }
}
