import type {AccountSessionClaim} from '../data-layer-frame/types';
import type {IFrameMessenger} from '../data-layer-client/FrameMessenger';

export class RefreshClientSecret {
  public apiKeyExpiry: number | undefined = undefined;

  public apiKeyOverride = false;

  public refreshedClaimOngoingRequest: Promise<AccountSessionClaim> | undefined;

  constructor(
    public authenticate: IFrameMessenger['authenticate'],
    private refreshClientSecretCallback?: () => Promise<string | undefined>,
  ) {
    this.refreshClientSecretCallback = refreshClientSecretCallback;
    this.authenticate = authenticate;
  }

  // Checks if api key is <= 5 minutes before expiry
  private isExpired(): boolean {
    // If the expiry is uninitialized, this means auth has not run yet and the API key is not expired
    // Ignore refresh session logic if we use an api key override
    if (this.apiKeyExpiry === undefined || this.apiKeyOverride) {
      return false;
    }

    const FIVE_MINUTES_IN_SECONDS = 300;
    const CURRENT_TIME_IN_SECONDS = new Date().getTime() / 1000;
    const timeToExpiry = this.apiKeyExpiry - CURRENT_TIME_IN_SECONDS;
    return timeToExpiry <= FIVE_MINUTES_IN_SECONDS;
  }

  public async refresh(): Promise<AccountSessionClaim | undefined> {
    if (!this.refreshClientSecretCallback || !this.isExpired()) {
      return;
    }
    if (!this.refreshedClaimOngoingRequest) {
      this.refreshedClaimOngoingRequest =
        (async (): Promise<AccountSessionClaim> => {
          const clientSecret = await this.refreshClientSecretCallback!();
          if (!clientSecret) {
            throw new Error('refreshClientSecret returns undefined');
          }
          const claimResponse = await this.authenticate(clientSecret, true);
          return claimResponse;
        })();
    }
    return this.refreshedClaimOngoingRequest;
  }

  // Updates the api key expiry with new timestamp returned from claim response
  public updateExpiry(timestamp: number | undefined): void {
    if (!this.refreshClientSecretCallback) return;

    this.refreshedClaimOngoingRequest = undefined;
    this.apiKeyExpiry = timestamp;
  }
}
