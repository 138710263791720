/**
 * Returns a random v4 UUID of the form xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx,
 * where each x is replaced with a random hexadecimal digit from 0 to f, and y
 * is replaced with a random hexadecimal digit from 8 to b.
 *
 * Taken from https://gist.github.com/jed/982883
 */
export const uuid = (a = ''): string => {
  /* eslint-disable no-bitwise */
  return a
    ? (
        parseInt(a, 10) ^
        ((Math.random() * 16) >> (parseInt(a, 10) / 4))
      ).toString(16)
    : '00000000-0000-4000-8000-000000000000'.replace(/[08]/g, uuid);
  /* eslint-enable no-bitwise */
};
