import {Severity} from '@sentry/browser';
import {sanitizeExtras} from 'src/internal/errors/sentry/utils';

import type {
  SentryCaptureOptions,
  SentryHub,
} from 'src/internal/errors/sentry/types';

export function captureException(
  hub: SentryHub,
  error: any,
  options?: SentryCaptureOptions,
): string | undefined {
  const {tags, extras, level} = options ?? {};

  hub.withScope((scope) => {
    scope.setLevel(level ? Severity.fromString(level) : Severity.Error);
    tags && scope.setTags(tags);
    extras && scope.setExtras(sanitizeExtras(extras));

    hub.captureException(error);
  });

  return hub.lastEventId();
}
