import * as error from 'src/internal/encoder/matchers/error';

export {default as traverse} from 'src/internal/encoder/traverse';

export type {Encoder, Matcher} from 'src/internal/encoder/types';

export const matchers = {
  error: {
    encode: error.encode,
    decode: error.decode,
  },
} as const;
