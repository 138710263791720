import {assertUnreachable} from '@dashboard/disputes/utils';
import {isStripeDomain} from '@stripe-internal/connect-embedded-lib';
import type {IAnalytics} from '@sail/observability';
import type {ConnectElementImportKeys} from '../ConnectJSInterface/ConnectElementList';
import {CONNECT_ELEMENT_IMPORTS} from '../ConnectJSInterface/ConnectElementList';
import ConnectElementBase from '../ConnectElementBase';
import {getStronglyTypedKeys} from '../../utils/getStronglyTypedKeys';
import {getStronglyTypedEntries} from '../../utils/getStronglyTypedEntries';
import {commonHtmlEventMethods} from '../ConnectJSInterface/HtmlEventTypes';
import {
  validateAttributeValue,
  validateEventListenerValue,
  validateSupplementalFunctionValue,
  validateSupplementalObjectValue,
} from './validateSetterInput';

/**
 *
 * Give us a UPS-compatible React component and
 * some props, we'll turn it into a custom element
 * and make sure UPS context is available.
 */
export type ConnectElement = typeof ConnectElementBase;

export const wrapComponentAsCustomElement = (
  connectElementName: ConnectElementImportKeys,
  analytics: IAnalytics,
): ConnectElement => {
  const {htmlAttributes, customHtmlElementMethods} =
    CONNECT_ELEMENT_IMPORTS[connectElementName];

  const customAndCommonHtmlElementMethods = {
    ...commonHtmlEventMethods,
    ...customHtmlElementMethods,
  };

  const specificClass = class ConnectElement extends ConnectElementBase {
    static get observedAttributes(): string[] {
      if (!htmlAttributes) {
        return [];
      }

      return getStronglyTypedKeys(htmlAttributes);
    }

    constructor() {
      super(connectElementName);
    }
  };

  // Normally we don't perform checks like this - however this code always runs in the platform frame
  // eslint-disable-next-line @stripe-internal/embedded/no-restricted-globals
  const isInternalDomain = isStripeDomain(new URL(window.location.href));

  if (customAndCommonHtmlElementMethods) {
    getStronglyTypedEntries(customAndCommonHtmlElementMethods).forEach(
      ([key, value]) => {
        // Skip internal methods if we're not on an internal domain
        if (value.isInternal && !isInternalDomain) {
          return;
        }

        switch (value.type) {
          case 'AttributeSettingMethod':
            // We ignore TS here because we're dynamically creating a method - we avoid using `any` to retain type safety on the `this` object
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            specificClass.prototype[key] = function (
              newValue: string | boolean | undefined,
            ): void {
              // For now, we don't act on these validations
              // TODO(jorgea): enable this validation once analytics have been active for some time
              validateAttributeValue(key, newValue, analytics);

              if (newValue !== undefined && newValue !== null) {
                this.setAttribute(value.attribute, newValue.toString());
              } else {
                // RemoveAttribute is a no-op if the attribute is not present
                this.removeAttribute(value.attribute);
              }
            };
            break;
          case 'EventListenerMethod':
            // We ignore TS here because we're dynamically creating a method - we avoid using `any` to retain type safety on the `this` object
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore

            specificClass.prototype[key] = function (
              newValue: (param: any) => void,
            ): void {
              // For now, we don't act on these validations
              // TODO(jorgea): enable this validation once analytics have been active for some time
              validateEventListenerValue(key, newValue, analytics);

              const oldListener = this.eventListeners[value.event];
              if (oldListener) {
                this.removeEventListener(
                  value.event,
                  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                  oldListener as (param: Event) => void,
                );

                delete this.eventListeners[value.event];
              }
              if (newValue !== undefined && newValue !== null) {
                const newListener = (event: CustomEvent) => {
                  newValue(event.detail || {});
                };

                this.eventListeners[value.event] = newListener;
                this.addEventListener(
                  value.event,
                  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                  newListener as (param: Event) => void,
                );
              }
            };
            break;
          case 'SupplementalFunction':
            // We ignore TS here because we're dynamically creating a method - we avoid using `any` to retain type safety on the `this` object
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            specificClass.prototype[key] = function (newValue: any): void {
              // For now, we don't act on these validations
              // TODO(jorgea): enable this validation once analytics have been active for some time
              validateSupplementalFunctionValue(key, newValue, analytics);

              this.connectElementSupplementalFunction.updateValues({
                ...this.connectElementSupplementalFunction.values,
                [value.objectToSet]: newValue,
              });
            };
            break;
          case 'SupplementalObject':
            // We ignore TS here because we're dynamically creating a method - we avoid using `any` to retain type safety on the `this` object
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            specificClass.prototype[key] = function (newValue: any): void {
              // For now, we don't act on these validations
              // TODO(jorgea): enable this validation once analytics have been active for some time
              validateSupplementalObjectValue(key, newValue, analytics);

              this.connectElementSupplementalObjects.updateValues({
                ...this.connectElementSupplementalObjects.values,
                [value.objectToSet]: newValue,
              });
            };
            break;
          default:
            assertUnreachable(value);
        }

        // We ignore TS here because we're dynamically creating a method - we avoid using `any` to retain type safety on the `this` object
        // We add the same method with "internal only" as a workaround to the loading sequence of connect embedded components in the npm package SDK
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        specificClass.prototype[`${key}InternalOnly`] =
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          specificClass.prototype[key as keyof typeof specificClass.prototype];
      },
    );
  }

  return specificClass;
};
