import type {Transport} from 'src/internal/transport/types';

export default function listen<TData>(
  transport: Transport,
  callback: (event: MessageEvent<TData>) => void,
): {start: () => void; stop: () => void} {
  let isListening = true;
  transport.addEventListener('message', callback);

  return {
    start: () => {
      if (isListening) {
        return;
      }

      transport.addEventListener('message', callback);
    },
    stop: () => {
      transport.removeEventListener('message', callback);
      isListening = false;
    },
  };
}
