/**
 * This error occurs when performing a request and running into issues with authentication
 * the error is not recognized as an API error - instead we expect authentication to flag an issue. In some situations,
 * this is expected, like when a platform feeds us an expired/wrong account session client secret
 */
export class DataLayerAuthError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'DataLayerAuthError';
  }
}

/**
 * This error occurs when performing a request and running into a component that is disabled
 * In that case, we fail the request. This is an expected error/behavior
 */
export class DataLayerDisabledComponentError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'DataLayerDisabledComponentError';
  }
}
