import type {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';

const SIMPLIFIED_UI_CONFIG_HTML_ATTRIBUTE = 'simplified' as const;
const FROM_INTERNAL_SITE_HTML_ATTRIBUTE = 'from-internal-site' as const;

export const DebugUIConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "debugUIConfig" */ '../../../components/Debug/UIConfig/UIConfig'
    ),
  enabledKey: 'internal',
  releaseState: 'internal',
  ignoreThemingValues: true,
  teamOwner: 'accounts_ui',
  htmlAttributes: {
    // Internal - to enable these for platforms, we'll need API review
    [SIMPLIFIED_UI_CONFIG_HTML_ATTRIBUTE]: {
      isInternal: true,
    },
    [FROM_INTERNAL_SITE_HTML_ATTRIBUTE]: {
      isInternal: true,
    },
  },
  description:
    'Allows for configuring theming in Connect embedded components. Used in the Stripe doc site for the theming demo.',
};
