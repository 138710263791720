import {PLATFORM_METRICS_PARAMETERS} from 'src/internal/platformMetrics/constants';

import type {ErrorEventReport} from 'src/internal/common/types';

const ERROR_EVENT_PARAMETERS: (keyof ErrorEventReport)[] = [
  'service',
  'release',
  'project',
  'error_message',
  'error_handled',
  'error_issue_id',
  'error_issue_url',
];

// Keep in sync with keys created by `createObservabilityLink.tsx`. We cannot
// base a type on that object because this would imply a circular reference
// between data and observability.
const SAIL_DATA_PARAMETERS = [
  // Ownership information:
  'service',
  'project',
  'component',

  // Error information:
  'errorCode',
  'errorMessage',
  'errorPath',
  'errorExpected',
  'errorMuted',
  'errorSource',

  // Operation information:
  'operationName',
  'operationPrefetch',
  'operationDeduplicate',

  // Network information:
  'networkDuration',
  'networkStatus',
  'networkActionId',
  'networkRequestId',
  'networkTraceId',

  // I _think_ these are deadwood?...
  'query_name',
  'field_accesses',
];

const SAIL_INTL_PARAMETERS = ['locale', 'messageKey', 'translationResult'];

export const DEFAULT_PARAMETERS_ALLOW_LIST = [
  ...SAIL_DATA_PARAMETERS,
  ...SAIL_INTL_PARAMETERS,
  ...ERROR_EVENT_PARAMETERS,
  ...PLATFORM_METRICS_PARAMETERS,
];
