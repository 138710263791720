import type {Matcher} from 'src/internal/encoder/types';

export const ENCODE_TYPE_ERROR = '__sdk_rpc_error_encoder';
export const ENCODE_TYPE_ERROR_VALUE = 'error';

export const encode: Matcher = [
  (value) => value instanceof Error,
  (value, traverse) => {
    const properties = new Set([
      'name',
      'message',
      'stack',
      'cause',
      ...Object.keys(value),
    ]);

    const encoded = Object.create(null);

    encoded[ENCODE_TYPE_ERROR] = ENCODE_TYPE_ERROR_VALUE;

    for (const property of properties) {
      encoded[property] = traverse(value[property]);
    }

    return encoded;
  },
];

export const decode: Matcher = [
  (value) => isSerializedError(value),
  (value, decode) =>
    Object.assign(
      new Error(),
      Object.keys(value).reduce((obj, key) => {
        if (
          key === ENCODE_TYPE_ERROR &&
          value[key] === ENCODE_TYPE_ERROR_VALUE
        ) {
          return obj;
        }

        obj[key] = decode((value as any)[key]);

        return obj;
      }, {} as Record<string, any>),
    ),
];

function isSerializedError(obj: any) {
  return (
    obj &&
    typeof obj === 'object' &&
    typeof obj.name === 'string' &&
    typeof obj.message === 'string' &&
    obj[ENCODE_TYPE_ERROR] === ENCODE_TYPE_ERROR_VALUE
  );
}
