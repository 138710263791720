// Referenced code from https://github.com/adobe/react-spectrum/blob/main/packages/%40react-aria/interactions/src/useInteractOutside.ts#L54
// This file is forked from react-aria to account for an event occuring within a ShadowDOM.
// We modify
// Because event propagation in shadowDOM bubbles up to the shadow root, we have to
// check event.composedPath and target the element that the event actually originated from.
// If the container does contain that innermost element, then we do not close the Popover.

import type {RefObject, SyntheticEvent} from 'react';
import {useEffect, useRef} from 'react';
import {ownerDocument} from '../../utils/domHelpers';

interface InteractOutsideProps {
  ref: RefObject<Element>;
  onInteractOutside?: (e: SyntheticEvent) => void;
  onInteractOutsideStart?: (e: SyntheticEvent) => void;
  /** Whether the interact outside events should be disabled. */
  isDisabled?: boolean;
}

/**
 * Example, used in components like Dialogs and Popovers so they can close
 * when a user clicks outside them.
 */
export function useInteractOutside(props: InteractOutsideProps) {
  const {ref, onInteractOutside, isDisabled, onInteractOutsideStart} = props;
  const stateRef = useRef({
    isPointerDown: false,
    ignoreEmulatedMouseEvents: false,
    onInteractOutside,
    onInteractOutsideStart,
  });
  const state = stateRef.current;
  state.onInteractOutside = onInteractOutside;
  state.onInteractOutsideStart = onInteractOutsideStart;

  useEffect(() => {
    if (isDisabled) {
      return;
    }

    const onPointerDown = (e: Event) => {
      if (
        isValidEvent(e as unknown as SyntheticEvent, ref) &&
        state.onInteractOutside
      ) {
        if (state.onInteractOutsideStart) {
          state.onInteractOutsideStart(e as unknown as SyntheticEvent);
        }
        state.isPointerDown = true;
      }
    };

    // Use pointer events if available. Otherwise, fall back to mouse and touch events.
    if (typeof PointerEvent !== 'undefined') {
      const onPointerUp = (e: Event) => {
        if (
          state.isPointerDown &&
          state.onInteractOutside &&
          isValidEvent(e as unknown as SyntheticEvent, ref)
        ) {
          state.isPointerDown = false;
          state.onInteractOutside(e as unknown as SyntheticEvent);
        }
      };

      // changing these to capture phase fixed combobox
      ownerDocument(ref.current).addEventListener(
        'pointerdown',
        onPointerDown,
        true,
      );
      ownerDocument(ref.current).addEventListener(
        'pointerup',
        onPointerUp,
        true,
      );

      return () => {
        ownerDocument(ref.current).removeEventListener(
          'pointerdown',
          onPointerDown,
          true,
        );
        ownerDocument(ref.current).removeEventListener(
          'pointerup',
          onPointerUp,
          true,
        );
      };
    } else {
      const onMouseUp = (e: Event) => {
        if (state.ignoreEmulatedMouseEvents) {
          state.ignoreEmulatedMouseEvents = false;
        } else if (
          state.isPointerDown &&
          state.onInteractOutside &&
          isValidEvent(e as unknown as SyntheticEvent, ref)
        ) {
          state.isPointerDown = false;
          state.onInteractOutside(e as unknown as SyntheticEvent);
        }
      };

      const onTouchEnd = (e: Event) => {
        state.ignoreEmulatedMouseEvents = true;
        if (
          state.onInteractOutside &&
          state.isPointerDown &&
          isValidEvent(e as unknown as SyntheticEvent, ref)
        ) {
          state.isPointerDown = false;
          state.onInteractOutside(e as unknown as SyntheticEvent);
        }
      };

      ownerDocument(ref.current).addEventListener(
        'mousedown',
        onPointerDown,
        true,
      );
      ownerDocument(ref.current).addEventListener('mouseup', onMouseUp, true);
      ownerDocument(ref.current).addEventListener(
        'touchstart',
        onPointerDown,
        true,
      );
      ownerDocument(ref.current).addEventListener('touchend', onTouchEnd, true);

      return () => {
        ownerDocument(ref.current).removeEventListener(
          'mousedown',
          onPointerDown,
          true,
        );
        ownerDocument(ref.current).removeEventListener(
          'mouseup',
          onMouseUp,
          true,
        );
        ownerDocument(ref.current).removeEventListener(
          'touchstart',
          onPointerDown,
          true,
        );
        ownerDocument(ref.current).removeEventListener(
          'touchend',
          onTouchEnd,
          true,
        );
      };
    }
  }, [ref, state, isDisabled]);
}

function isValidEvent(event: SyntheticEvent, ref: RefObject<Element>) {
  if (event instanceof MouseEvent && event.button > 0) {
    return false;
  }

  // if the event target is no longer in the document
  if (event.target) {
    const ownerDocument = (event.target as Node).ownerDocument;
    if (
      !ownerDocument ||
      !ownerDocument.documentElement.contains(event.target as Node)
    ) {
      return false;
    }
  }

  return ref.current && !ref.current.contains(event.target as Node);
}
